import { PayloadAction, createSlice } from '@reduxjs/toolkit';
type DrawingUrl = { url: string, label: string, id: number, data?: string }
const initialState:
    {
        Drawings: Blob[],
        urls: DrawingUrl[]

    } = {
    Drawings: [],
    urls: []
}

const Units = createSlice({
    name: 'drawings',
    initialState,
    reducers: {
        addDrawing: (state, action: PayloadAction<Blob>) => {
            state.Drawings.push(action.payload)
        },
        addDrawings: (state, action: PayloadAction<Blob[]>) => {
            state.Drawings = action.payload
        },
        addUrls: (state, action: PayloadAction<DrawingUrl[]>) => {
            state.urls = action.payload
        },
    },
});

export const { addDrawings, addDrawing, addUrls } = Units.actions;

export default Units.reducer;
