import { Configuration } from '@azure/msal-browser';
import { ssoProperties, ssoPropertiesCIAM } from "../Constants/Consts";

export const msalConfig: Configuration = {
  auth: {
    clientId: ssoProperties.clientId,
    authority: ssoProperties.authority,
    knownAuthorities: ssoProperties.knownAuthorities,
    redirectUri: ssoProperties.redirectUri
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    // cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ssoProperties.scopes
};

export const msalConfigCIAM: Configuration = {
  auth: {
    clientId: ssoPropertiesCIAM.clientId,
    authority: ssoPropertiesCIAM.authority,
    knownAuthorities: ssoPropertiesCIAM.knownAuthorities,
    redirectUri: ssoPropertiesCIAM.redirectUri
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    // cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
