import { useNavigate, useParams } from "react-router-dom";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store.ts";
import DetailGrid from "../../components/CoreComponents/Details/DetailsGrid.tsx";
import { AdHocUnitItem } from "../../types/Unit/AdHocUnitSchema.ts";
import SectionHeader from "../../components/CoreComponents/Details/SectionHeader.tsx";


export default function AdHocUnitDetails() {
    const { ProjectNumber, AdhocUuid } = useParams();
    const navigate = useNavigate();

    const filteredAdHocUnit: AdHocUnitItem = useSelector((state: RootState) => {
        return state.AdHocUnit.ProjectAdHocUnitItem[ProjectNumber].find((item) => item.adhocUuid === AdhocUuid);
    });
    
    const discipline = useSelector((state: RootState) => {
        return state.Discipline.allDisciplines.find(d => d.id === filteredAdHocUnit.disciplineId)
    })

    const generalDetails = useMemo(() => {
        const details = [
            { label: "Project Number", value: filteredAdHocUnit.projectNumber.toString() },
            { label: "Discipline", value: discipline.disciplineName },
            { label: "Sequence Number", value: filteredAdHocUnit.sequenceNumber.toString() },
            { label: "Unit Number", value: filteredAdHocUnit.unitNumber.toString() },
            { label: "Model Number", value: filteredAdHocUnit.modelNumber },
            { label: "Model Full", value: filteredAdHocUnit.modelFull },
            {
                label: "Requested On",
                value: filteredAdHocUnit.requestedOn ? new Date(filteredAdHocUnit.requestedOn).toISOString() : ''
            },
            { label: "Requested By", value: filteredAdHocUnit.requestedBy },
            { label: "Requestor Email", value: filteredAdHocUnit.requestedEmail },
            {
                label: "Completed On",
                value: filteredAdHocUnit.completedOn ? new Date(filteredAdHocUnit.completedOn).toISOString() : ''
            },
            { label: "Completed Message", value: filteredAdHocUnit.completedMessage },
        ];
        return details.filter(detail => detail.value != null);
    }, [filteredAdHocUnit]);
    
    return (
        <div className={'max-w-screen-md'}>
            <div className="felx items-center mt-8 mb-8">
                <SectionHeader title="Adhoc Details Page" />
            </div>

            <DetailGrid details={generalDetails} />

            <button className='relative bg-gray-300 mt-3 left-0' onClick={() => navigate(-1)}>Back</button>
        </div>
    );
}
