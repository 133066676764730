import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Discipline } from '../types/types';

const initialState:
    {
        allDisciplines: Discipline[],
        projectDisciplines: Discipline[],
        SelectedDiscipline: Discipline,

    } = {
    allDisciplines: [],
    projectDisciplines: [],
    SelectedDiscipline: {
        "id": 0,
        "disciplineName": "",
        "disciplineCode": "E",
        "drillDownProcess": "",
    },
}

const Units = createSlice({
    name: 'disciplines',
    initialState,
    reducers: {
        addSelectedDiscipline: (state, action: PayloadAction<Discipline>) => {
            state.SelectedDiscipline = action.payload
        },
        addDisciplines: (state, action: PayloadAction<Discipline[]>) => {
            state.allDisciplines = action.payload
        },
        addProjectDisciplines: (state, action: PayloadAction<Discipline[]>) => {
            state.projectDisciplines = action.payload
        },
    },
});

export const { addSelectedDiscipline, addDisciplines, addProjectDisciplines } = Units.actions;

export default Units.reducer;
