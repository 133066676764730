import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { URLs } from '../../../../Constants/Consts'
import { fetchApi } from '../../../../Helpers/Functions'
import { editComment } from '../../../../store/Comments'
import Loader from '../../Loader'
import { Comment } from '../../../../types/types'

export default function EditComment({ commentToEdit, cancelEdit }: { commentToEdit: Comment, cancelEdit: () => void }) {
    const dispatch = useDispatch()
    const [comment, setComment] = useState(commentToEdit.comment1)
    const [loading, setLoading] = useState(false)
    const addCommentHandler = async (e) => {
        e.preventDefault()
        const commentBody = {
            ...commentToEdit,
            "comment1": comment,
        }
        try {
            setLoading(true)
            await fetchApi(URLs.putCommentsUrl(commentToEdit.commentUuid), {
                method: "PATCH",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ "comment": comment })
            }).then(res => res.json()).then(() => {
                dispatch(editComment(commentBody))
            })
        } catch (error) {
            if (!navigator.onLine) {
                dispatch(editComment({ ...commentBody, notSync: true }))
            }
        } finally {
            setComment("")
            setLoading(false)
        }
    }

    return (
        loading ? <Loader /> :
            <div>
                <form className='flex flex-col gap-4 items-start'>
                    <label htmlFor='editedCommet' className=''>Edit Comment:</label>
                    <textarea id='editedCommet' value={comment} onChange={e => setComment(e.target.value)} className='border w-80 h-32 p-2' />
                    <div className="flex gap-2">
                        <button type='button' className='p-1 px-4 bg-gray-300 text-black hover:bg-gray-400' onClick={cancelEdit}>Cancel</button>
                        <button type='submit' className='p-1 px-4 bg-[#fdb419] text-white hover:bg-[#aaa6a5]' onClick={addCommentHandler}>Save</button>
                    </div>
                </form>
            </div>
    )
}