import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { photosKeys, URLs as URLs } from "../../Constants/Consts";
import { fetchApi } from "../../Helpers/Functions";
import { updateDisplinesImagesFlag, updateDisplinesImagesTriggered } from "../../store/Utilities";
import { RootState } from "../../store/store";

export default function useDownloadPunchListDisciplineImages(disciplineId: number) {
    const [isPhotosLoading, setIsPhotosLoading] = useState(false)
    const dispatch = useDispatch()
    const { allDisciplines } = useSelector((state: RootState) => state.Discipline);
    const selectedDiscipline = allDisciplines.find(d => d.id === disciplineId)
    const { ProjectPunchLists } = useSelector((state: RootState) => state.PunchList)
    const arrKeys = Object.keys(photosKeys)
    const { disciplinePunchlistsImageDownloaded, disciplinePunchlistsImageTriggered } = useSelector((state: RootState) => state.Utilities)

    const filesWorker = useMemo(() => new Worker(new URL('../SyncManager/webWorker.ts', import.meta.url)), [])
    useEffect(() => {
        (async () => {
            if (!disciplinePunchlistsImageDownloaded[selectedDiscipline.disciplineCode] && disciplinePunchlistsImageTriggered[selectedDiscipline.disciplineCode]) {
                setIsPhotosLoading(_ => true)
                const punchlistsWithPhotos = ProjectPunchLists.filter(pl => String(pl.assignedDisciplineId) == String(disciplineId))
                const photosUrls = punchlistsWithPhotos
                    .reduce<{ grouped: Array<string>, couter: string[] }[]>((acc, pl) => {
                        let couter = []
                        let grouped = []
                        if (pl[arrKeys[0]]) {
                            couter.push(photosKeys[arrKeys[0]])
                            grouped.push(pl[arrKeys[0]])
                        }
                        if (pl[arrKeys[1]]) {
                            couter.push(photosKeys[arrKeys[1]])
                            grouped.push(pl[arrKeys[1]])
                        }
                        if (pl[arrKeys[2]]) {
                            couter.push(photosKeys[arrKeys[2]])
                            grouped.push(pl[arrKeys[2]])
                        }
                        if (pl[arrKeys[3]]) {
                            couter.push(photosKeys[arrKeys[3]])
                            grouped.push(pl[arrKeys[3]])
                        }
                        acc.push({ grouped, couter })
                        return acc
                    }, [])
                // fetch discipline photos 
                const photosToFetch = photosUrls.reduce((acc, f) => {
                    if (f.couter.length) acc.push(f.grouped)
                    return acc.flat()
                }, [])
                console.log("punchlistsWithPhotos", punchlistsWithPhotos);
                console.log(photosUrls, "photosToFetch", photosToFetch);
                let counter = 0
                await Promise.allSettled(photosToFetch.map(p => {
                    const pUrl = p.replace('punch_list_images/', '');
                    return fetchApi(URLs.getFilePunchListImage(encodeURIComponent(pUrl)))
                        .then(async response => {
                            if (Worker) filesWorker.postMessage({ action: 'storeFile', fileName: `${pUrl.split('/')?.[3]}`, file: await response.arrayBuffer() });
                            counter++
                            if (counter === photosToFetch.length) {
                                console.log("counter", counter);
                                setIsPhotosLoading(_ => false)
                            }
                        })
                        .catch(reason => ({
                            status: 'rejected',
                            reason
                        }));
                }))
                dispatch(updateDisplinesImagesFlag({ disciplineCode: selectedDiscipline.disciplineCode, value: true }))
                dispatch(updateDisplinesImagesTriggered({ disciplineCode: selectedDiscipline.disciplineCode, value: false }))
            }
        })()
    }, [
        disciplinePunchlistsImageDownloaded[selectedDiscipline.disciplineCode],
        disciplinePunchlistsImageTriggered[selectedDiscipline.disciplineCode]
    ])

    return { isPhotosLoading }
}
