import Select from 'react-select';

type Option = {
    text: string;
    value: string | number | { value: string; id: string | number };
};

interface DropdownProps {
    options: Option[];
    selected;
    onChangeEvent;
    isMultiSelect: boolean;
    disabled?: boolean;
}

export default function Dropdown({ options, selected, onChangeEvent, disabled = false, isMultiSelect = false }: DropdownProps) {
    const selectedOption = isMultiSelect
        ? options?.filter((option) => selected.includes(option.value))
        : options?.find((option) => option.value === selected) || null;

    return (
        <div>
            <Select
                isDisabled={disabled}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary25: '#fdb419', // Inside select color
                        primary: '#fdb419', // Inside select color
                        neutral0: 'var(--select-background)', // Background color
                        neutral80: 'var(--select-text-color)', // Text color
                        neutral20: 'gray', // Border color
                    },
                })}
                isMulti={isMultiSelect}
                value={selectedOption}
                onChange={onChangeEvent}
                options={options}
                classNames={{
                    control: (state) => state.isDisabled ? '!bg-gray-200 !cursor-not-allowed' : '',
                    singleValue: (state) =>
                        state.isDisabled ? '!text-neutral-500' : '',
                    multiValue: (state) =>
                        state.isDisabled ? '!text-neutral-500' : '',
                    placeholder: (state) =>
                        state.isDisabled ? '!text-neutral-500' : '',
                }}
                getOptionLabel={(option) => option.text}
            />
        </div>
    );
}