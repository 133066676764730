import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { PiSealWarning } from "react-icons/pi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { z } from "zod";
import SectionHeader from "../../components/CoreComponents/Details/SectionHeader.tsx";
import Loader from "../../components/CoreComponents/Loader.tsx";
import FormDDL from "../../components/CoreComponents/ReactFormComponents/FormDDL.tsx";
import FormInput from "../../components/CoreComponents/ReactFormComponents/FormInput.tsx";
import { getDrilldownType, URLs } from "../../Constants/Consts.ts";
import { fetchApi, getDrilldownStepsNumber, splitOnCapitals } from "../../Helpers/Functions.ts";
import { RootState } from "../../store/store.ts";
import { ModelApiItem } from "../../types/Model/ModelSchema.ts";
import { getProjectText } from "../../types/types.ts";
import { detailsSchema } from "../../types/Unit/AdHocUnitSchema.ts";

// disciplineId 2,3,4,5 ==> drilldown_type_id = 1
// disciplineId 1,6,7,8 ==> drilldown_type_id = 4

export default function CreateAdHocUnit() {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        watch
    } = useForm({
        resolver: zodResolver(detailsSchema)
    });

    const { SelectedUser } = useSelector((state: RootState) => state.SelectedUser)
    const { DrilldownMappingsAPIResponse } = useSelector((state: RootState) => state.SequenceObject);
    const { projects } = useSelector((state: RootState) => state.Project)
    const [loading, setLoading] = useState(false);
    // based on regestered user !!
    const drilldownType = getDrilldownType(SelectedUser.disciplineId)
    const DrilldownMappingMeaning = DrilldownMappingsAPIResponse.find(d => d.drilldownTypeId === drilldownType)
    const [fetchedModels, setFetchedModels] = useState<ModelApiItem[]>([])
    useEffect(() => {
        (async () => {
            setLoading(true);
            const response = await fetchApi(URLs.getModels())
            if (response.status === 200) {
                const data = await response.json() as ModelApiItem[]
                // this is for show only, sending data willbe with model number only 
                setFetchedModels(data)
                setLoading(false);
            } else
                setLoading(false);
        })()
    }, [])

    useEffect(() => {
        setValue("ProjectNumber", SelectedUser.projectNumber)
    }, [SelectedUser]);

    const modelNumber = watch("ap01")
    useEffect(() => {
        const selectedDD = fetchedModels.find(m => m.modelNumber === modelNumber)?.drilldownTypeId
        const DDobject = DrilldownMappingsAPIResponse.find(dd => dd.drilldownTypeId === selectedDD)
        const DDsteps = getDrilldownStepsNumber(DDobject)
        const DDvalues = []
        for (let i = 1; i <= DDsteps; i++) {
            const step = i < 10 ? `0${i}` : `${i}`
            DDvalues.push({ ddKey: `dd${step}`, ddValue: "", ddName: DDobject[`dd${step}`] })
        }
        replace(DDvalues)
    }, [modelNumber])
    const { fields, replace } = useFieldArray({
        name: "DDLevels",
        control: control,
    });
    const expectedValuesFromAPI = {
        projectNumber: 0,
        ap01: "",
        dd01: "",
        dd02: "",
        dd03: "",
        dd04: "",
        dd05: ""
    }
    const onSubmit = async (data: z.infer<typeof detailsSchema>) => {
        setLoading(true);
        const levelsData = data.DDLevels.reduce((acc, { ddKey, ddValue }) => {
            acc[ddKey] = ddValue;
            return acc;
        }, {});
        delete data.DDLevels
        const dataToSend = { ...expectedValuesFromAPI, ...data, ...levelsData }
        await fetchApi(URLs.postAdhocChecklistTarget(), {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataToSend)
        }).then(() => navigate(-1))
        setLoading(false);
    }

    return (
        loading ? <Loader loaders={{ isModelsLoading: loading }} /> :
            <div className='max-w-screen-md'>
                <div className='mt-8 mb-8'>
                    <SectionHeader title='Add Adhoc Checklist Item' />
                </div>
                <div className='flex items-center mb-5 '>
                    <PiSealWarning className='mr-2 text-uniteOrange' />
                    <h2>Checklist could take up to 5 minutes to show in UNITE.</h2>
                </div>
                <div>
                    <form className='relative' onSubmit={handleSubmit(onSubmit)}>
                        <FormDDL
                            required={true}
                            options={
                                [...projects]
                                    .sort((a, b) => a.projectName.localeCompare(b.projectName))
                                    .map(i => ({ text: getProjectText(i), value: i.projectNumber }))
                            }
                            control={control}
                            errors={errors}
                            defaultValue={SelectedUser.projectNumber}
                            inputKey={"ProjectNumber"}
                            label={"Project Selection"}
                            name={"ProjectNumber"}
                        />
                        <FormDDL
                            required={true}
                            options={
                                fetchedModels
                                    .map(({ modelNumber, modelDescription }) => ({ text: `${modelNumber} - ${modelDescription}`, value: modelNumber }))
                                    .sort((a, b) => a.text.localeCompare(b.text))
                                    .map(i => ({ text: i.text, value: i.value }))
                            }
                            control={control}
                            errors={errors}
                            defaultValue={undefined}
                            inputKey={"ap01"}
                            label={splitOnCapitals(DrilldownMappingMeaning?.ap01)}
                            name={"ap01"}
                        />
                        {fields.map((ss, i) => {
                            return <>
                                <FormInput
                                    key={ss.id}
                                    required={true}
                                    errors={errors}
                                    defaultValue=""
                                    inputKey={`DDLevels.${i}.ddKey`}
                                    registeredProps={register(`DDLevels.${i}.ddValue`)}
                                    // @ts-ignore TODO
                                    label={splitOnCapitals(ss.ddName)}
                                    placeholder={i === 0 ? `Ex: control area="CA-121", segment="1010"` : ''}
                                />
                                {/* TODO: styling */}
                                {errors[`DDLevels`]?.[i]?.["ddValue"]?.message && <p className="text-red-500 text-xs text-right">{errors[`DDLevels`][i]["ddValue"]?.message}</p>}
                            </>
                        })}
                        <div className='flex items-center mt-16'>
                            <button
                                className='absolute left-0 mt-3 text-gray-900 bg-gray-500 dark:text-gray-900 dark:bg-gray-500'
                                onClick={() => navigate(-1)}>Cancel
                            </button>
                            <button type='submit' disabled={false}
                                className={twMerge("absolute right-0 text-white ", true ?
                                    "bg-orange-1000 dark:text-gray-800 dark:bg-orange-1000" :
                                    "text-gray-900 bg-gray-500 dark:text-gray-900 dark:bg-gray-500")}>
                                Submit
                            </button>
                        </div>
                        <div className='flex items-center justify-center mt-10 p-12 '>
                            <PiSealWarning className='mr-2 text-uniteOrange' />
                            <h2>In order to add a unit checklist item, you must be connected to the internet.
                            </h2>
                        </div>
                    </form>
                </div>
            </div>
    );
}
