import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ChangeLog } from '../types/types';

const initialState:
    {
        ChangeLogs: ChangeLog[]

    } = {
    ChangeLogs: []
}

const Units = createSlice({
    name: 'changelogs',
    initialState,
    reducers: {
        addChangeLogs: (state, action: PayloadAction<ChangeLog[]>) => {
            state.ChangeLogs = action.payload
        },
        updateChangeLogs: (state, action: PayloadAction<{ uuidType: string, uuid: string, data: ChangeLog[] }>) => {
            const itemCurrentChanglogs = state.ChangeLogs.filter(c => {
                if (action.payload.uuidType === "Punchlist") {
                    return c.punchlistUuid === action.payload.uuid
                }
                if (action.payload.uuidType === "Checklist") {
                    return c.unitChecklistUuid === action.payload.uuid
                }
            })

            for (let i = 0; i < itemCurrentChanglogs.length; i++) {
                const element = itemCurrentChanglogs[i];
                const index = state.ChangeLogs.findIndex(c => c.changelogUuid === element.changelogUuid);
                if (index >= 0) state.ChangeLogs.splice(index, 1);
            }

            const newc = [...state.ChangeLogs, ...action.payload.data]
            state.ChangeLogs = newc;
        },
    },
});

export const { addChangeLogs, updateChangeLogs } = Units.actions;

export default Units.reducer;