import Static from 'ol/source/ImageStatic'
import { useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { fextent, layerContext } from '../../components/Providers/LayersProvider'
import { RootState } from '../../store/store'
import PunchlistViewer from './PunchlistViewer'
import { FaMapMarkerAlt } from "react-icons/fa";
import "./MapView.css"

export default function MapView() {
  const { layoutId, punchlistId } = useParams()
  const { mapObject, staticImageLayer } = useContext(layerContext)
  const mapRef = useRef<HTMLDivElement>()
  const { urls } = useSelector((state: RootState) => state.Drawings)
  const [options, setOptions] = useState([])

  useEffect(() => {
    (async () => {
      const ff = await Promise.all(urls.map(async ({ label, id, data }) => {
        const dd = await fetch(data)
          .then(response => response.blob())
        const blobUrl = window.URL.createObjectURL(dd)
        return ({ url: blobUrl, id, label })
      }))
      setOptions(ff)
    })()
  }, [urls])

  const selectedUrl = options.find(u => u.id === Number(layoutId))

  useEffect(() => {
    if (mapObject) mapObject?.setTarget(mapRef.current)
    if (staticImageLayer && selectedUrl) staticImageLayer.setSource(new Static({
      url: selectedUrl.url,
      projection: "EPSG:4326",
      imageExtent: fextent,
    }),)
  }, [mapObject, staticImageLayer, selectedUrl])

  return (
    <div>
      <div className="w-[85vw] h-[85svh] relative" ref={mapRef}>
        <PunchlistViewer punchlistId={punchlistId} layoutId={layoutId} />
        <div className="flex flex-wrap gap-2 mb-4">
          <div className="inline-flex items-center mx-2">
            <FaMapMarkerAlt className="mr-1 text-orange-1000" />Pending
          </div>
          <div className="inline-flex items-center mx-2">
            <FaMapMarkerAlt className="mr-1 text-blue-400" />Ready For Re-Inspection
          </div>
          <div className="inline-flex items-center mx-2">
            <FaMapMarkerAlt className="mr-1 text-green-500" />Completed
          </div>
          <div className="inline-flex items-center mx-2">
            <FaMapMarkerAlt className="mr-1 text-orange-700" />Duplicate
          </div>
          <div className="inline-flex items-center mx-2">
            <FaMapMarkerAlt className="mr-1 text-gray-800" />On-Hold
          </div>
          <div className="inline-flex items-center mx-2">
            <FaMapMarkerAlt className="mr-1 text-red-800" />Cancelled
          </div>
          <div className="inline-flex items-center mx-2">
            <FaMapMarkerAlt className="mr-1 text-yellow-300" />Materials Received
          </div>
          <div className="inline-flex items-center mx-2">
            <FaMapMarkerAlt className="mr-1 text-amber-950" />Multiple Punch List Items
          </div>
        </div>
      </div>
    </div>
  )
}