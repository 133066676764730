import { RouteProps } from "react-router-dom";
import Layout from "../Layouts/Layout";
import Home from "../Pages/Home/Home";
import AllUsers from "../Pages/AllUsers/AllUsers";
import User from "../Pages/AllUsers/User";
import PunchList from "../Pages/PunchList/PunchList";
import PunchListDetails from "../Pages/PunchList/Form/Details/Details";
import PunchListForm from "../Pages/PunchList/Form/Form";
import SiteMap from "../Pages/PunchList/Form/SiteMap/SiteMap";
import Options from "../Pages/PunchList/Options/Options";
import View from "../Pages/PunchList/Options/View/View";
import PunchListsDetailsPage from "../Pages/PunchList/DetailsPage/DetailsPage";
import Table from "../Pages/PunchList/Options/View/Table/Table";
import MyAssignment from "../components/User/MyAssignment";
import ListComment from "../components/CoreComponents/Comments/Comment/Comment";
import ChangeLog from "../components/CoreComponents/Changelog/ChangeLog/ChangeLog.tsx";
import DrawingLayouts from "../Pages/DrawingLayouts/DrawingLayouts";
import MapView from "../Pages/DrawingLayouts/MapView";
import AllAdHocUnits from "../Pages/Unit/AllAdHocUnits";
import CreateAdHocUnit from "../Pages/Unit/CreateAdHocUnit";
import ChangeUserRole from "../Pages/ChangeUserRole";
import AllAdHocUnitsByProject from "../Pages/Unit/AllAdHocUnitsByProject";
import AdHocUnitDetails from "../Pages/Unit/AdHocUnitDetails";
import PunchListEditImagesPage from "../Pages/PunchList/DetailsPage/PunchListEditImagesPage.tsx";
import PendingSyncItems from "../Pages/PendingSyncItems/PendingSyncItems.tsx";
import TasksDD from "../Pages/Tasks/TasksDD.tsx";
import CheckListDD from "../Pages/Tasks/CheckList/CheckList.tsx";
import DetailsDD from "../Pages/Tasks/CheckList/Details/Details.tsx";

export const routes: {
    [key: string]: {
        Layout: React.FC<{ children?: React.ReactNode }>;
        Props: RouteProps;
        Component: React.FC;
        addToMenu?: boolean;
    };
} = {
    Home: {
        Layout: Layout,
        Props: {
            path: "/",
        },
        Component: Home,
        addToMenu: true,
    },
    AllUsers: {
        Layout: Layout,
        Props: {
            path: "/AllUsers",
        },
        Component: AllUsers,
        addToMenu: true,
    },
    UserEdit: {
        Layout: Layout,
        Props: {
            path: "/AllUsers/Edit/:uuid",
        },
        Component: User,
    },
    UserCreate: {
        Layout: Layout,
        Props: {
            path: "/AllUsers/Create",
        },
        Component: User,
    },
    AdHocUnits: {
        Layout: Layout,
        Props: {
            path: "/AdHocUnits",
        },
        Component: AllAdHocUnits,
        addToMenu: true,
    },
    AdHocUnitsByProject: {
        Layout: Layout,
        Props: {
            path: "/AdHocUnits/:ProjectNumber",
        },
        Component: AllAdHocUnitsByProject,
    },
    AdHocUnitDetails: {
        Layout: Layout,
        Props: {
            path: "/AdHocUnits/:ProjectNumber/:AdhocUuid",
        },
        Component: AdHocUnitDetails,
    },
    CreateAdHocUnit: {
        Layout: Layout,
        Props: {
            path: "/AdHocUnits/Create",
        },
        Component: CreateAdHocUnit
    },
    PunchListForm: {
        Layout: Layout,
        Props: {
            path: "/PunchList/Form/:type/:id",
        },
        Component: PunchListForm,
    },
    PunchListDetails: {
        Layout: Layout,
        Props: {
            path: "/PunchList/Details/:punchListId",
        },
        Component: PunchListDetails,
    },
    SiteMap: {
        Layout: Layout,
        Props: {
            path: "/PunchList/SiteMap/:type/:id",
        },
        Component: SiteMap,
    },
    PunchList: {
        Layout: Layout,
        Props: {
            path: "/PunchList",
        },
        Component: PunchList,
        addToMenu: true,
    },
    PunchListView: {
        Layout: Layout,
        Props: {
            path: "/PunchList/:viewType/view",
        },
        Component: View,
    },
    PunchListDisciplineOption: {
        Layout: Layout,
        Props: {
            path: "/PunchListDiscipline/:disciplineId",
        },
        Component: Options,
    },
    PunchListsDisciplineView: {
        Layout: Layout,
        Props: {
            path: "/PunchListDiscipline/:disciplineId/view",
        },
        Component: View,
    },

    PunchListsDisciplineViewTable: {
        Layout: Layout,
        Props: {
            path: "/PunchListDiscipline/:disciplineId/view/:listStatus",
        },
        Component: Table,
    },
    PunchListsViewTable: {
        Layout: Layout,
        Props: {
            path: "/PunchList/:viewType/view/:listStatus",
        },
        Component: Table,
    },

    PunchListsDetailsPage: {
        Layout: Layout,
        Props: {
            path: "/PunchList/DetailsPage/:punchListId",
        },
        Component: PunchListsDetailsPage,
    },
    PunchListsDetailsEditImagesPage: {
        Layout: Layout,
        Props: {
            path: "/PunchList/DetailsPage/:punchListId/Image",
        },
        Component: PunchListEditImagesPage,
    },
    MyAssignment: {
        Layout: Layout,
        Props: {
            path: "/MyAssignment",
        },
        Component: MyAssignment,
    },

    Comment: {
        Layout: Layout,
        Props: {
            path: "/Comment/:commentUuid",
        },
        Component: ListComment,
    },

    ChangeLog: {
        Layout: Layout,
        Props: {
            path: "/ChangeLog/:changelogUuid",
        },
        Component: ChangeLog,
    },

    DrawingLayouts: {
        Layout: Layout,
        Props: {
            path: "/DrawingLayouts",
        },
        Component: DrawingLayouts,
    },
    DrawingLayoutsMap: {
        Layout: Layout,
        Props: {
            path: "/DrawingLayouts/MapView/:layoutId/:punchlistId?",
        },
        Component: MapView,
    },
    ChangeUserRoleRoute: {
        Layout: Layout,
        Props: {
            path: "/change-user-role",
        },
        Component: ChangeUserRole,
    },
    PendingSyncItems: {
        Layout: Layout,
        Props: {
            path: "/PendingSyncItems",
        },
        Component: PendingSyncItems,
    },
    TasksDD: {
        Layout: Layout,
        Props: {
            path: "/TasksDD",
        },
        Component: TasksDD,
    },
    CheckListDD: {
        Layout: Layout,
        Props: {
            path: "/CheckListDD/:path",
        },
        Component: CheckListDD,
    },
    CheckListDetailsDD: {
        Layout: Layout,
        Props: {
            path: "/CheckListDetailsDD/:path",
        },
        Component: DetailsDD,
    },
};
