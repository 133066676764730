import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UserAssignment } from '../types/types';
import { AccountInfo } from '@azure/msal-common';

const initialState:
    {
        SelectedUser: UserAssignment,
        account: AccountInfo
    } = {
    SelectedUser: {
        id: 0,
        fullName: "",
        email: "",
        role: "",
        projectNumber: 0,
        disciplineId: 0,
        sequence: "",
        checklistType: null,
        caCc: null,
        location: null,
        adhocAbility: "",
        uuid: "",
        discipline: "",
        project: "",
        kionEmail: "",
        loginId: ""
    },
    account: null
}

const Units = createSlice({
    name: 'selecteduser',
    initialState,
    reducers: {
        addSelectedUser: (state, action: PayloadAction<UserAssignment>) => {
            state.SelectedUser = action.payload;
        },
        addSelectedAccount: (state, action: PayloadAction<AccountInfo>) => {
            state.account = action.payload;
        },
        updateSelectedUserRole: (state, action: PayloadAction<string>) => {
            state.SelectedUser.role = action.payload;
        },
    }
});

export const { addSelectedUser, updateSelectedUserRole, addSelectedAccount } = Units.actions;

export default Units.reducer;