import { useMsal } from "@azure/msal-react";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { memo, useEffect, useState } from "react";
import { IoCloudOfflineOutline } from "react-icons/io5";
import { TbCloudUpload } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { CookiesKeys, LoginPlatforms, ssoProperties, ssoPropertiesCIAM } from "../../Constants/Consts";
import { updateUnsyncItemsCount, updatesyncTriggered } from "../../store/Utilities";
import { RootState } from "../../store/store";
import useCheckLists from "./CheckListSync";
import useComments from "./CommentsSync";
import usePunchlists from "./PunchlistSync";

export enum SyncItemsTypes {
    CheckListItem = "CheckListItem",
    PunchlistItem = "PunchlistItem",
    Comment = "Comment",
    CreateAdHocUnitItem = "CreateAdHocUnitItem",
    PunchlistPhotoItem = "PunchlistPhotoItem",
}

function SyncManager() {
    const { instance, accounts } = useMsal();

    const checkTokenExpirationAndRenew = async () => {
        const currentToken = Cookies.get(CookiesKeys.jwt);
        await instance.initialize();
        if (accounts.length > 0 && currentToken) {
            const decodedToken = jwtDecode(currentToken);
            const currentTime = Math.floor(Date.now() / 1000);
            const timeUntilExpiry = decodedToken.exp - currentTime;
            if (timeUntilExpiry <= 300) { // 5 minutes threshold
                // Token is about to expire, renew it
                if (Cookies.get(CookiesKeys.loginPlatform) === LoginPlatforms.azure) {
                    const accessTokenRequest = {
                        scopes: ssoProperties.scopes,
                        account: accounts[0],
                    };
                    const newTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
                    Cookies.set(CookiesKeys.jwt, newTokenResponse.accessToken);
                }
                else if (Cookies.get(CookiesKeys.loginPlatform) === LoginPlatforms.ciam) {
                    const accessTokenRequest = {
                        scopes: ssoPropertiesCIAM.scopes,
                        account: accounts[0],
                    };
                    const newTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
                    Cookies.set(CookiesKeys.jwt, newTokenResponse.idToken);
                }
            }
        }
    };

    const [controller, setController] = useState(navigator.serviceWorker.controller);
    const [onLine, setOnLine] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const { getChecklistItemsToSync, updateSyncItem: updateChecklistSyncItem } = useCheckLists();
    const checklists = getChecklistItemsToSync();
    const { getPunchlistsToSync, updateSyncItem: updateSyncPunchlist } = usePunchlists();
    const punchlistsPromises = getPunchlistsToSync();
    const { getCommentsToSync, updateSyncItem: updateComment } = useComments();
    const comments = getCommentsToSync();
    const toSyncItemsCount = checklists.length + punchlistsPromises.length + comments.length;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateUnsyncItemsCount(toSyncItemsCount));
    }, [dispatch, toSyncItemsCount]);

    const updateBackendInBackground = async () => {
        if (!('serviceWorker' in navigator)) {
            console.error("serviceWorker is not in navigator");
            return;
        }
        const checklists = getChecklistItemsToSync();
        const punchlistsPromises = getPunchlistsToSync();
        const punchlists = [];
        for (let k = 0; k < punchlistsPromises.length; k++) {
            const pl = await punchlistsPromises[k];
            punchlists.push(pl);
        }
        const comments = getCommentsToSync();
        const data = [...checklists, ...punchlists, ...comments];
        for (let i = 0; i < data.length; i++) {
            const syncRequest = data[i];
            controller.postMessage({ isOnline: true, syncRequest });
        }
    };

    useEffect(() => {
        const handleOnline = async () => {
            setOnLine(true);
            await checkTokenExpirationAndRenew();
            updateBackendInBackground();
        };
        const handleOffline = () => setOnLine(false);

        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);
        return () => {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);
        };
    }, [punchlistsPromises, comments, checklists, toSyncItemsCount]);

    const { syncTriggered } = useSelector((state: RootState) => state.Utilities);

    useEffect(() => {
        if (syncTriggered) {
            (async () => {
                await checkTokenExpirationAndRenew();
                await updateBackendInBackground();
                dispatch(updatesyncTriggered(false));
            })();
        }
    }, [syncTriggered, dispatch]);

    useEffect(() => {
        const messageListener = (e) => {
            setIsLoading(!!e.data.loading);
            switch (e.data?.data?.type) {
                case SyncItemsTypes.CheckListItem:
                    updateChecklistSyncItem(e.data?.data?.itemId ?? "");
                    break;
                case SyncItemsTypes.PunchlistItem:
                    updateSyncPunchlist(e.data?.data?.itemId ?? "");
                    break;
                case SyncItemsTypes.Comment:
                    updateComment(e.data?.data?.itemId ?? "");
                    break;
                default:
                    break;
            }
        };

        const controllerListener = () => {
            console.log("controller changed");
            setController(navigator.serviceWorker.controller);
        };

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener("controllerchange", controllerListener);
            navigator.serviceWorker.addEventListener('message', messageListener);
        }

        return () => {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.removeEventListener("controllerchange", controllerListener);
                navigator.serviceWorker.removeEventListener('message', messageListener);
            }
        };
    }, [updateChecklistSyncItem, updateSyncPunchlist, updateComment]);

    const contentLength = toSyncItemsCount.toString().replace(/,/g, '').length;
    const baseWidth = 20;
    const widthPerCharacter = 10;
    const totalWidth = baseWidth + (contentLength - 1) * widthPerCharacter;
    const additionalRightSpacing = 12;

    return (
        <div className="relative">
            {onLine ? (
                <i onClick={() => dispatch(updatesyncTriggered(true))}>
                    <TbCloudUpload className={`${isLoading ? "animate-ping" : ""} cursor-pointer scale-125`} />
                </i>
            ) : (
                <IoCloudOfflineOutline className="scale-125" />
            )}
            <span
                className="absolute top-1 left-3 h-6 text-white bg-orange-1000 rounded-full flex justify-center items-center scale-75"
                style={{ width: `${totalWidth}px`, right: `${additionalRightSpacing}px` }}
            >
                {toSyncItemsCount}
            </span>
        </div>
    );
}


export default memo(SyncManager)