import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
    persistStore,
    persistReducer,
    PersistState,
    // FLUSH,
    // REHYDRATE,
    // PAUSE,
    // PERSIST,
    // PURGE,
    // REGISTER,
} from 'redux-persist'
import storage from 'redux-persist-indexeddb-storage';
import PunchList from './PunchList';
import SequenceObject from './SequenceObject';
import Discipline from "./Discipline";
import Project from "./Projects";
import UserAssignment from "./User";
import SelectedUser from "./SelectedUser";
import Comments from "./Comments";
import ChangeLogs from "./ChangeLogs";
import changelogsTypes from "./changelogsTypes";
import Utilities from "./Utilities";
import Issues from "./Issues";
import Status from "./Status";
import Categories from "./Categories";
import Drawings from "./Drawings";
import AdHocUnit from "./AdHocUnit";
import Model from "./Model";
import RelatedChecklistDetails from './RelatedChecklistDetails';
import Photos from './Photos';
import createMigrate from 'redux-persist/es/createMigrate';
import DD1Options from './DD1Options';
import DD2Options from './DD2Options';
import RootCauses from './RootCauses';
import Technology from './Technology'
import LinkDisciplineIssue from './LinkDisciplineIssue';
import LinkDisciplineTecnCategory from './LinkDisciplineTecnCategory';


const newVersion = 1;
const persistConfig = {
    key: 'root',
    storage: storage('UniteDB'),  // 'UniteDB' is the name of your IndexedDB database,
    blacklist: ['Utilities'/* , 'SelectedUser' */],
    version: newVersion,
    migrate: createMigrate({
        [newVersion]: (state: RootState & { _persist: PersistState }) => {
            // Migration logic here
            console.log("migration state", state, state._persist);
            return {
                ...state, AdHocUnit: {
                    ...state.AdHocUnit,
                    ProjectAdHocItem: {}
                }
            }
        }
    }, { debug: true }),
}

const rootReducer = combineReducers({
    SequenceObject: SequenceObject,
    AdHocUnit: AdHocUnit,
    Model: Model,
    PunchList: PunchList,
    Discipline: Discipline,
    Project: Project,
    UserAssignment: UserAssignment,
    Comments: Comments,
    ChangeLogs: ChangeLogs,
    changelogsTypes: changelogsTypes,
    Utilities: Utilities,
    Issues: Issues,
    Status: Status,
    Categories: Categories,
    Drawings: Drawings,
    SelectedUser: SelectedUser,
    RelatedChecklistDetails: RelatedChecklistDetails,
    Photos: Photos,
    DD1Options,
    DD2Options,
    RootCauses,
    Technology,
    LinkDisciplineIssue,
    LinkDisciplineTecnCategory
})

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer) as typeof rootReducer;

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
        }),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>
