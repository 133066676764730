import { PublicClientApplication } from "@azure/msal-browser";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import * as z from 'zod';
import { CookiesKeys, LoginPlatforms, ssoProperties, ssoPropertiesCIAM } from '../Constants/Consts';
import { msalConfig } from "../config/MsalConfig";
import { ChecklistTaskApiResponse, DDCheckList } from "../types/DataDD/DataDD";


export const splitOnCapitals = (name: string = "") => name.split(/(?=[A-Z])/g)?.join(" ")
export const commaSeparatedNumbers = (nums: String = "") => nums.split(",")?.map(n => Number(n))


// to intersept when needed
export const fetchApi = async (url: string, options?: RequestInit) => {
  const currentToken = Cookies.get(CookiesKeys.jwt)
  const decodedToken = jwtDecode(currentToken);
  const currentTime = Math.floor(Date.now() / 1000);
  const timeUntilExpiry = decodedToken.exp - currentTime;
  if (timeUntilExpiry <= 300) { // 5 minutes threshold
    // Token is about to expire, renew it
    const msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize()
    const accounts = msalInstance.getAllAccounts()
    if (Cookies.get(CookiesKeys.loginPlatform) === LoginPlatforms.azure) {
      const accessTokenRequest = {
        scopes: ssoProperties.scopes,
        account: accounts[0],
      };
      const newTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);
      Cookies.set(CookiesKeys.jwt, newTokenResponse.accessToken);
    }
    else if (Cookies.get(CookiesKeys.loginPlatform) === LoginPlatforms.ciam) {
      const accessTokenRequest = {
        scopes: ssoPropertiesCIAM.scopes,
        account: accounts[0],
      };
      const newTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);
      Cookies.set(CookiesKeys.jwt, newTokenResponse.idToken);
    }
    else {
      //TODO redirect to landing page
      alert("you are not logged on")
    }
  }
  return fetch(url, {
    ...options,
    headers: {
      ...options?.headers,
      'Authorization': 'Bearer ' + Cookies.get(CookiesKeys.jwt),
    }
  })
};

export const zodKeys = <T extends z.ZodTypeAny>(schema: T): string[] => {
  if (schema === null || schema === undefined) return [];
  if (schema instanceof z.ZodNullable || schema instanceof z.ZodOptional) return zodKeys(schema.unwrap());
  if (schema instanceof z.ZodArray) return zodKeys(schema.element);
  if (schema instanceof z.ZodObject) {
    const entries = Object.entries(schema.shape);
    return entries.flatMap(([key, value]) => {
      const nested = value instanceof z.ZodType ? zodKeys(value).map(subKey => `${key}.${subKey}`) : [];
      return nested.length ? nested : key;
    });
  }
  return [];
};

const uuid = (a?): string => a ? (a ^ Math.random() * 16 >> a / 4).toString(16) : ([1e7, -1e3, -4e3, -8e3, -1e11].join('')).replace(/[018]/g, uuid);

export const getRandomUuid = () => crypto?.randomUUID?.() ?? uuid()

export const blobToBase64 = (blob): Promise<string> => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
  });
};


export function deleteAllCookies() {
  document.cookie.split(';').forEach(cookie => {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  });
}


export function getByPath(path: string, obj: any) {
  const pathArray = path.split('.');
  let current = obj;
  for (const key of pathArray) {
    if (current[key] === undefined) {
      return current;
    }
    current = current[key];
  }
  return current;
}

export function getCompletePath(ChecklistItem: DDCheckList | ChecklistTaskApiResponse) {
  switch (ChecklistItem.drilldownTypeId) {
    case 1:
      return `${ChecklistItem.dd01}.${ChecklistItem.dd02}.${ChecklistItem.ctUuid}`
    case 2:
      return `${ChecklistItem.dd01}.${ChecklistItem.dd02}.${ChecklistItem.dd03}.${ChecklistItem.ctUuid}`
    default:
      return `${ChecklistItem.dd01}.${ChecklistItem.dd02}.${ChecklistItem.ctUuid}`
  }
}