import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { twMerge } from "tailwind-merge";
import SectionHeader from '../../../../components/CoreComponents/Details/SectionHeader.tsx';
import ViewTable from '../../../../components/CoreComponents/ViewTable';
import { NumberToStatus } from "../../../../Constants/Consts.ts";
import { RootState } from '../../../../store/store';
import { ChecklistTaskApiResponse } from '../../../../types/DataDD/DataDD.ts';
import { useTableData } from "../../../PunchList/Options/View/Table/usePunchListsTableData.tsx";

export default function PunchLists({ item }: { item: ChecklistTaskApiResponse }) {
    const { ProjectPunchLists } = useSelector((state: RootState) => state.PunchList)
    const { rows } = useTableData(ProjectPunchLists.filter(pl => pl.checklistUuid === item.ctUuid))
    const navigate = useNavigate()
    const columns = useMemo(() => [
        {
            Header: "Status",
            accessor: 'original.status',
            Cell: ({ value }) => NumberToStatus[value]
        },
        {
            Header: "Punch List Tag",
            accessor: 'original.mpctag',
        },
        {
            Header: "Date Identified",
            accessor: 'original.dateIdentified',
            Cell: ({ value }) => value != null ? new Date(String(value)).toLocaleString() : ''
        },
        // {
        //     Header: "Date Completed",
        //     accessor: 'original.dateCompleted',
        //     Cell: ({ value }) => value != null ? new Date(String(value)).toLocaleString() : ''
        // },
    ], [])

    return (
        <>
            <div className="flex gap-3 items-center mb-2">
                <div className={twMerge(`flex gap-3 items-center`)}>
                    <SectionHeader title="related punch list" />
                </div>
                <span className={'bg-uniteOrange w-10 h-8 text-lg text-white text-center font-semibold'} style={{ borderRadius: '20px' }}>{rows.length}</span>
            </div>

            {rows && rows.length > 0 ? (
                <div>
                    <ViewTable
                        columns={columns}
                        data={rows}
                        onRowClick={row => navigate(`/PunchList/DetailsPage/${row.original.punchListId}`)}
                    />
                </div>
            ) : (
                <span className='text-sm text-gray-500 ml-6'>No Related Punch List</span>
            )}

        </>
    )
}
