import { PunchList, PunchlistApiType, punchlistDefault } from "../types/PunchList/PunchListSchema";
import { createAdHocUnitDefault, AdHocUnitDetailsType, CreateAdHocUnitItem } from "../types/Unit/AdHocUnitSchema.ts";

export const mapAdHocUnitToAPI = (unit: AdHocUnitDetailsType): CreateAdHocUnitItem => {
    const data = { ...createAdHocUnitDefault }
    for (const key in unit) {
        if (Object.prototype.hasOwnProperty.call(unit, key)) {
            if (unit[key] && key in data) {
                data[key] = unit[key];
            }
            data[adhocUnitToApiMappingObject[key]] = unit[key];
        }
    }
    return data;
}

const adhocUnitToApiMappingObject = {
    ProjectNumber: "projectNumber",
    Discipline: "disciplineId",
    UnitNumber: "unitNumber",
    SequenceNumber: "sequenceNumber",
    ModelNumber: "modelNumber",
    ModelFull: "modelFull",
}

export const mapPunchListToAPI = (punchList: PunchList): PunchlistApiType => {
    const data = { ...punchlistDefault }
    for (const key in punchList) {
        if (Object.prototype.hasOwnProperty.call(punchList, key)) {
            if (punchList[key] && key in data) {
                data[key] = punchList[key]
            }
            data[punchListToApiMappingObject[key]] = punchList[key]
            if (key === "Location") {
                data[punchListToApiMappingObject[key]] = `${punchList[key]?.[0] ?? ""},${punchList[key]?.[1] ?? ""}`
            } else if (key === "DrawingId") {
                try {
                    const parsed = JSON.parse(punchList[key])
                    data[punchListToApiMappingObject[key]] = parsed.id
                } catch (error) {
                    data[punchListToApiMappingObject[key]] = 0
                }
            } else if (key === "DoesPunchListRequireReinspection") {
                data[punchListToApiMappingObject[key]] = punchList[key] ? 1 : 0
            } else if (key === "DrawingHash") {
                data[punchListToApiMappingObject[key]] = String(punchList[key])
            } else if (key === "ErmRelevant") {
                data[punchListToApiMappingObject[key]] = punchList[key] ? 1 : 0
            } else if (key === "DueDate" && punchList[key] === "") {
                data[punchListToApiMappingObject[key]] = null
            } else if (key === "OriginalDiscipline" || key === "AssignedDiscipline" || key === "DrawingHash") {
                data[punchListToApiMappingObject[key]] = String(punchList[key])
            // } else if (key.toLowerCase() === "creation" || key.toLowerCase() === "notsync") {
            //     data[key] = punchList[key]
            } else if (key.toLowerCase().includes('stored')) {
                delete data[key]
            }
        }
    }
    return data
}
const punchListToApiMappingObject = {
    Project: "projectNumber",
    projectNumber: "projectNumber",
    OriginalDiscipline: "disciplineId",
    AssignedDiscipline: "assignedDisciplineId",
    Category: "materialId",
    DrawingHash: "drawingNumber",
    Issue: "issueId",
    Priority: "priority",
    DueDate: "dueDate",
    DoesPunchListRequireReinspection: "rfrRequired",
    DrawingId: "drawingId",
    DefectPic1: "defectPic1",
    DefectPic2: "defectPic2",
    correctedPic1: "storedCorrectedPic1",
    correctedPic2: "storedCorrectedPic2",
    defectPic1: "storedDefectPic1",
    defectPic2: "storedDefectPic2",
    Location: "locationPin",
    SequenceControlArea: "adhocSequenceCa",
    UnitDevice: "adhocUnitDevice",
    IssueDescription: "issueDesc",
    ChecklistUuid: "checklistUuid",
    CtUuid: "ctUuid",
    itemId: "punchListId",
    ErmRelevant: "ermRelevant",
    ErmType: "ermType",
    punchListId: "punchListId",
}
