import { FaPenToSquare } from "react-icons/fa6";
import { MdSaveAs } from "react-icons/md";

function EditSaveBtn({ disable, onClickHandler, buttonText }) {
    return (
        <div className="ml-2" onClick={() => onClickHandler(d => !d)}>
            {disable
                ? <>
                    <div className="flex items-center">
                        <FaPenToSquare style={{ color: '#fdb419', transform: 'scale(1.00)', cursor: 'pointer' }} />
                        <span className="ml-3 cursor-pointer text-sm">{buttonText}</span>
                    </div>
                </>
                : <div className="flex flex-wrap gap-3 items-center">
                    <MdSaveAs style={{ color: '#fdb419', transform: 'scale(1.50)', cursor: 'pointer' }} />
                    <p className={'text-uniteOrange font-bold text-sm'}>Save Changes</p>
                </div>
            }
        </div>
    );
}

export default EditSaveBtn;
