import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { PunchListViewOptions } from '../../../../Constants/Consts'
import { RootState } from '../../../../store/store'
import { PunchlistApiType } from "../../../../types/PunchList/PunchListSchema.ts";
import StatusTextIcon from '../../../../components/CoreComponents/StatusTextIcons.tsx'
import { twMerge } from "tailwind-merge";
import SectionHeader from '../../../../components/CoreComponents/Details/SectionHeader.tsx';

export default function View() {
    const { disciplineId } = useParams()
    let { viewType } = useParams();
    viewType = viewType || (disciplineId ? PunchListViewOptions.DISCIPLINE.name : viewType);

    const navigate = useNavigate()
    const { allDisciplines: disciplines } = useSelector((state: RootState) => state.Discipline)
    const selectedDiscipline = disciplines.find(d => String(d.id) == disciplineId)
    const { ProjectPunchLists } = useSelector((state: RootState) => state.PunchList)
    const { SelectedUser } = useSelector(
        (state: RootState) => state.SelectedUser
    );

    const filteredPunchLists: PunchlistApiType[] = [];
    let headerTitle: string;

    if (viewType === PunchListViewOptions.DISCIPLINE.name && disciplineId) {
        filteredPunchLists.push(...ProjectPunchLists.filter(pl => Number(pl.assignedDisciplineId) === Number(disciplineId)))
        headerTitle = selectedDiscipline.disciplineName;
    } else if (viewType === PunchListViewOptions.ALL_PUNCH_LISTS.name) {
        filteredPunchLists.push(...ProjectPunchLists)
        headerTitle = PunchListViewOptions.ALL_PUNCH_LISTS.label;
    } else if (viewType === PunchListViewOptions.MY_PUNCH_LISTS.name) {
        filteredPunchLists.push(...ProjectPunchLists.filter(pl => pl.identifiedBy === SelectedUser.email || pl.identifiedBy === SelectedUser.kionEmail))
        headerTitle = PunchListViewOptions.MY_PUNCH_LISTS.label;
    } else if (viewType === PunchListViewOptions.ERM_RELEVANT.name) {
        filteredPunchLists.push(...ProjectPunchLists.filter(pl => pl.ermRelevant));
        headerTitle = PunchListViewOptions.ERM_RELEVANT.label;
    }

    const groupedPunchlistsByStatus = filteredPunchLists?.reduce((g, p) => {
        const key = p.status
        if (!g[key]) g[key] = []
        g[key].push(p)
        return g
    }, {})

    const customOrder = [11, 6, 4, 9, 8, 10, 7];
    const sortedStatusKeys = Object.keys(groupedPunchlistsByStatus ?? {}).sort((a, b) => {
        let indexA = customOrder.indexOf(Number(a));
        let indexB = customOrder.indexOf(Number(b));
        return indexA - indexB;
    });

    return (
        <div className='max-w-4xl'>
            <div className={twMerge(`flex gap-3 items-center mt-4 mb-4`)}>
                <SectionHeader title={headerTitle} />
            </div>
            <hr className="border-t mt-2" />
            <ul>
                {sortedStatusKeys.map((k, i, arr) => {
                    return (
                        <li
                            className={`flex items-center py-4 cursor-pointer text-sm hover:bg-gray-600 hover:cursor-pointer border-b ${i !== arr.length - 1 ? 'border-gray-300' : 'border-0'}`}
                            onClick={() => viewType === PunchListViewOptions.DISCIPLINE.name ?
                                navigate(`/PunchListDiscipline/${disciplineId}/view/${k}`) :
                                navigate(`/PunchList/${viewType}/view/${k}`)
                            }
                            key={k}
                        >
                            <StatusTextIcon statusId={Number(k)} />
                            <span className="ml-2">- Total: {groupedPunchlistsByStatus[k].length}</span>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}
