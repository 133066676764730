import { useMutation } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { pathDelmiter, Status, URLs } from '../../Constants/Consts'
import { setSelectedDD01, setSelectedInspectionTask, updateCheckListItem, setSelectedPath, setSelectedDD02 } from '../../store/SequenceObject'
import { RootState } from '../../store/store'
import { fetchApi, getByPath } from '../Functions'
import { DDCheckList, DDCheckListObject } from '../../types/DataDD/DataDD'

export default function useSCheckList({ path }: { path: string }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { ChecklistsObject } = useSelector((state: RootState) => state.SequenceObject)
    const checklistGroupObject = getByPath(path, ChecklistsObject) as DDCheckListObject

    const updateItemStatus = (item: DDCheckList) => {
        return fetchApi(URLs.updateDD(item.ctUuid), {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ statusId: item.statusId })
        })
    }
    const { mutate, isPending: isMutating, variables, mutateAsync } = useMutation({
        mutationFn: updateItemStatus,
        onSuccess: async (_, variables) => {
            dispatch(updateCheckListItem({
                completePath: `${path}.${variables.ctUuid}`,
                checklistItem: variables
            }))
        },
        onError: (_, variables) => {
            if (!navigator.onLine) {
                dispatch(updateCheckListItem({ completePath: `${path}.${variables.ctUuid}`, checklistItem: { ...variables, notSync: true } }))
            }
        },
        networkMode: "offlineFirst"
    })

    const markPassed = (item: DDCheckList) => {
        const selectedItem = checklistGroupObject?.[item.ctUuid]
        if (selectedItem) return mutateAsync({ ...selectedItem, statusId: Status.Passed })
    }

    const markFailed = (item: DDCheckList) => {
        const selectedItem = checklistGroupObject?.[item.ctUuid]
        if (selectedItem) return mutateAsync({ ...selectedItem, statusId: Status.Failed })
    }

    const markNotApplicable = (item: DDCheckList) => {
        const selectedItem = checklistGroupObject?.[item.ctUuid]
        if (selectedItem) mutate({ ...selectedItem, statusId: Status.NA })
    }

    const reset = (item: DDCheckList) => {
        const selectedItem = checklistGroupObject?.[item.ctUuid]
        if (selectedItem) return mutateAsync({ ...selectedItem, statusId: Status.Open })
    }

    const markPending = (item: DDCheckList) => {
        const selectedItem = checklistGroupObject?.[item.ctUuid]
        if (selectedItem) return mutateAsync({ ...selectedItem, statusId: Status.Pending })
    }

    const navigateToPunchList = (item: DDCheckList) => {
        const pathArray = path.split(pathDelmiter)
        dispatch(setSelectedPath(path))
        // todo: delete these place holders
        console.log("pathArray", pathArray);
        
        dispatch(setSelectedDD01(pathArray[1]))
        dispatch(setSelectedDD02(pathArray[3]))
        // **
        dispatch(setSelectedInspectionTask(item.taskDescription))
        navigate(`/PunchList/Form/CheckList/${item.ctUuid}`)
    }

    return {
        checkListObject: checklistGroupObject, markPassed, markFailed, navigateToPunchList, markNotApplicable, reset, markPending,
        showLoader: { isMutating, variables }
    }
}
