import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from '../../../../store/store'
import DetailsHeader from "../../Details/SectionHeader";

const viewKeys = {
    data: "Data",
    changedBy: "Name",
    changedEmail: "Email",
    changedOn: "Date/Time",
    type: "Changelog Type",
    // changelogType: "Changelog Type",
}

export default function ChangeLog() {
    const { changelogUuid } = useParams()
    const { ChangeLogs } = useSelector((state: RootState) => state.ChangeLogs)
    const { ChangeLogsTypes } = useSelector((state: RootState) => state.changelogsTypes)

    const selectedLog = ChangeLogs.find(cl => cl.changelogUuid === changelogUuid)
    const logView = { ...selectedLog, type: ChangeLogsTypes.find(clt => clt.id === selectedLog.changelogType)?.typeName }
    return (
        <>
            <div className="mt-8">
                <div className="mt-4 mb-8">
                    <DetailsHeader title="changelog" />
                </div>
                {Object.keys(logView ?? {}).map(k => {
                    if (!Object.keys(viewKeys).includes(k)) return
                    if (k === "changedOn") {
                        return (<div key={k}>
                            <span className='text-lg font-bold'>
                                {viewKeys[k]}</span>
                            {" : "}
                            {new Date(String(logView[k])).toLocaleString()}
                        </div>)
                    }
                    return (<div key={k}>
                        <span className='text-lg font-bold'>
                            {viewKeys[k]}</span>
                        {" : "}
                        {logView[k]}
                    </div>)
                })}
            </div>
        </>
    )
}
