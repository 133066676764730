import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TLinkDisciplineIssue } from '../types/types';

const initialState:
    {
        LinkDisciplineIssue: TLinkDisciplineIssue[]

    } = {
    LinkDisciplineIssue: []
}

const LinkDisciplineIssue = createSlice({
    name: 'rootCauses',
    initialState,
    reducers: {
        addLinkDisciplineIssue: (state, action: PayloadAction<TLinkDisciplineIssue[]>) => {
            state.LinkDisciplineIssue = action.payload
        },
    },
});

export const { addLinkDisciplineIssue } = LinkDisciplineIssue.actions;

export default LinkDisciplineIssue.reducer;
