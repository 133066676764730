import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TTechnology } from '../types/types';

const initialState:
    {
        Technology: TTechnology[]

    } = {
    Technology: []
}

const Technology = createSlice({
    name: 'technology',
    initialState,
    reducers: {
        addTechnology: (state, action: PayloadAction<TTechnology[]>) => {
            state.Technology = action.payload
        },
    },
});

export const { addTechnology } = Technology.actions;

export default Technology.reducer;
