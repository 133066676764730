import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import React from "react";

type modal = {
    open: boolean,
    Chilren: React.FC,
    onConfirm: (data?) => void
    onClose?: (data?) => void
    onReject?: (data?) => void
    width?: string,
}

export type DownloadedModules = {
    projects: boolean,
    drawings: boolean,
    sequences: boolean,
    changeLogsTypes: boolean,
    changeLogs: boolean,
    disciplines: boolean,
    allUsers: boolean,
    comments: boolean,
    issues: boolean,
    status: boolean,
    categories: boolean,
    punchList: boolean,
    adHocUnits: boolean,
    models: boolean,
    punchListImages: boolean,
    readonlyelatedChecklistDetails: boolean,
    drilldownMappings: boolean
}

const initialState:
    {
        modal: modal,
        hasDownloaded: DownloadedModules,
        downloadTriggered: DownloadedModules,
        syncTriggered: boolean,
        disciplinePunchlistsImageDownloaded: { [downloaded: string]: boolean },
        disciplinePunchlistsImageTriggered: { [downloaded: string]: boolean },
        unsyncItemsCount: number,
        backClickHandler?: () => void,
        taskDisplayedPath: string
    } = {
    modal: {
        open: false,
        Chilren: () => "",
        onConfirm: (_?) => { },
        onClose: (_?) => { },
        width: null,
    },
    hasDownloaded: {
        drilldownMappings: false,
        projects: false,
        drawings: false,
        sequences: false,
        changeLogsTypes: false,
        changeLogs: false,
        disciplines: false,
        allUsers: false,
        comments: false,
        issues: false,
        status: false,
        categories: false,
        punchList: false,
        adHocUnits: false,
        models: false,
        readonlyelatedChecklistDetails: false,
        punchListImages: false
    },
    downloadTriggered: {
        projects: false,
        drawings: false,
        drilldownMappings: false,
        sequences: false,
        changeLogsTypes: false,
        changeLogs: false,
        disciplines: false,
        allUsers: false,
        comments: false,
        issues: false,
        status: false,
        categories: false,
        punchList: false,
        adHocUnits: false,
        models: false,
        readonlyelatedChecklistDetails: false,
        punchListImages: false
    },
    syncTriggered: false,
    disciplinePunchlistsImageDownloaded: {},
    disciplinePunchlistsImageTriggered: {},
    unsyncItemsCount: 0,
    backClickHandler: null,
    taskDisplayedPath: ""
}

const Units = createSlice({
    name: 'utilities',
    initialState,
    reducers: {
        setModalOpen: (state, action: PayloadAction<boolean>) => {
            state.modal.open = action.payload
        },
        setModalMessage: (state, action: PayloadAction<React.FC>) => {
            state.modal.Chilren = action.payload
        },
        setModal: (state, action: PayloadAction<modal>) => {
            state.modal = action.payload
        },
        updateHasDownloadedValue: (state, action: PayloadAction<{ key: keyof DownloadedModules, value: boolean }>) => {
            state.hasDownloaded[action.payload.key] = action.payload.value
        },
        updateDownloadTriggered: (state, action: PayloadAction<{ key: keyof DownloadedModules, value: boolean }>) => {
            state.downloadTriggered[action.payload.key] = action.payload.value
        },
        updatesyncTriggered: (state, action: PayloadAction<boolean>) => {
            state.syncTriggered = action.payload
        },
        addDisplinesImagesFlag: (state, action: PayloadAction<string>) => {
            state.disciplinePunchlistsImageDownloaded[action.payload] = false
            state.disciplinePunchlistsImageTriggered[action.payload] = false
        },
        updateDisplinesImagesFlag: (state, action: PayloadAction<{ disciplineCode: string, value: boolean }>) => {
            state.disciplinePunchlistsImageDownloaded[action.payload.disciplineCode] = action.payload.value
        },
        updateDisplinesImagesTriggered: (state, action: PayloadAction<{ disciplineCode: string, value: boolean }>) => {
            state.disciplinePunchlistsImageTriggered[action.payload.disciplineCode] = action.payload.value
        },
        updateUnsyncItemsCount: (state, action: PayloadAction<number>) => {
            state.unsyncItemsCount = action.payload
        },
        setBackClickHandler: (state, action: PayloadAction<() => void>) => {
            state.backClickHandler = action.payload
        },
        setTaskDisplayedPath: (state, action: PayloadAction<string>) => {
            state.taskDisplayedPath = action.payload
        }
    },
});

export const { setModalOpen, setModalMessage, setModal, addDisplinesImagesFlag, updateDisplinesImagesFlag,
    updateDisplinesImagesTriggered, updateUnsyncItemsCount,
    updateHasDownloadedValue, updateDownloadTriggered, updatesyncTriggered, setBackClickHandler, setTaskDisplayedPath
} = Units.actions;

export default Units.reducer;
