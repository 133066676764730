import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TLinkDisciplineTecnCategory } from '../types/types';

const initialState:
    {
        LinkDisciplineTecnoCategory: TLinkDisciplineTecnCategory[]

    } = {
    LinkDisciplineTecnoCategory: []
}

const LinkDisciplineTecnoCategory = createSlice({
    name: 'rootCauses',
    initialState,
    reducers: {
        addLinkDisciplineTecnoCategory: (state, action: PayloadAction<TLinkDisciplineTecnCategory[]>) => {
            state.LinkDisciplineTecnoCategory = action.payload
        },
    },
});

export const { addLinkDisciplineTecnoCategory } = LinkDisciplineTecnoCategory.actions;

export default LinkDisciplineTecnoCategory.reducer;