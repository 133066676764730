import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getDrilldownType, URLs } from "../../Constants/Consts"
import { fetchApi } from "../../Helpers/Functions"
import { addChecklists, addMPCStatus } from "../../store/SequenceObject"
import { RootState } from "../../store/store"
import { updateDownloadTriggered, updateHasDownloadedValue } from "../../store/Utilities"
import { ChecklistTaskApiResponse, DDObject } from "../../types/DataDD/DataDD"
import { MPCStatus, UserAssignment } from "../../types/types"

export default function storeTasks() {
    const dispatch = useDispatch()
    const { SelectedUser } = useSelector((state: RootState) => state.SelectedUser)
    const { downloadTriggered: { sequences: triggered } } = useSelector(
        (state: RootState) => state.Utilities
    );
    const [isLoading, setisLoading] = useState(false)
    const controllerRef = useRef<AbortController>()
    const calledOnceRef = useRef(false)
    useEffect(() => {
        (async () => {
            if (navigator.onLine && !!SelectedUser.jwt && triggered) {
                dispatch(updateDownloadTriggered({ key: "sequences", value: false }))
                calledOnceRef.current = true
                if (controllerRef.current) {
                    controllerRef.current?.abort?.()
                }
                controllerRef.current = new AbortController()
                setisLoading(true)

                const { UserSequences, mpcStatus } = await fetchUserTasks(SelectedUser, controllerRef.current.signal)
                dispatch(addMPCStatus(mpcStatus))
                dispatch(addChecklists(UserSequences ?? {}))
                dispatch(updateHasDownloadedValue({ key: "sequences", value: true }))
                setisLoading(false)

                // chain changelogs download
                // dispatch(updateDownloadTriggered({ key: "changeLogs", value: true }))

                // chain adHocUnits download
                dispatch(updateDownloadTriggered({ key: "adHocUnits", value: true }))
            }
        })()
    }, [SelectedUser.jwt, SelectedUser.sequence, triggered])
    const isCheckListsLoading = isLoading
    return { isLoading: isCheckListsLoading }
}

async function storeMPCStatus(projectNumber: number, sequences: string): Promise<MPCStatus[]> {
    const sequencesArray = sequences.split(',')
    return await fetchApi(URLs.getMPCStatusUrl(projectNumber, sequencesArray)).then(res => res.json()).then(data => {
        return data
    })
}

export const fetchUserTasks = async (SelectedUser: UserAssignment, signal?: AbortSignal) => {
    // check if discipline id = 1, 7 or 6 -> return CACC objects instead
    if (SelectedUser.disciplineId === 1 || SelectedUser.disciplineId === 6) {
        // return fetchUserCACCs(SelectedUser, signal)
    }
    const userAllowedSequences = SelectedUser?.sequence.split(',')

    // get all getChecklistsUrl
    // aggregate them to construct the drop down object
    const userSequencesPromises = await Promise.allSettled(
        userAllowedSequences.map(c =>
            fetchApi(URLs.getDD(SelectedUser.projectNumber, SelectedUser.disciplineId, c), { signal })
                .then(res => res.json())))
    const userSequences = []
    for (const element of userSequencesPromises) {
        if (element.status === 'fulfilled') {
            const f = await element.value
            userSequences.push(f)
        }
    }
    const UserTasks = aggregateData(SelectedUser.disciplineId, userSequences.flat())
    console.log("UserTasks: ", UserTasks);
    // MECHANICAL PRE-COMMISSIONING
    let mpcStatus = []
    if (SelectedUser.disciplineId == 5) {
        mpcStatus = await storeMPCStatus(SelectedUser.projectNumber, SelectedUser.sequence)
    }
    return { UserSequences: UserTasks, mpcStatus }
}

const aggregateData = (userDisciplineId: number, userData: ChecklistTaskApiResponse[] = [], obj: DDObject = {}) => {
    /**
     * 1 -> dd01 -> dd02 -> taskDescription
     * 2 -> dd01 -> dd02 -> dd03 -> taskDescription
     */
    const drilldownType = getDrilldownType(userDisciplineId)
    if (drilldownType === 1) {
        for (const element of userData) {
            if (!obj[element.dd01]) obj[element.dd01] = {}
            if (!obj[element.dd01][element.dd02])
                obj[element.dd01][element.dd02] = {}
            if (!obj[element.dd01][element.dd02][element.ctUuid])
                obj[element.dd01][element.dd02][element.ctUuid] = element
        }
    }
    if (drilldownType === 2) {
        for (const element of userData) {
            if (!obj[element.dd01]) obj[element.dd01] = {}
            if (!obj[element.dd01][element.dd02])
                obj[element.dd01][element.dd02] = {}
            if (!obj[element.dd01][element.dd02][element.dd03])
                obj[element.dd01][element.dd02][element.dd03] = {}
            if (!obj[element.dd01][element.dd02][element.dd03][element.ctUuid])
                obj[element.dd01][element.dd02][element.dd03][element.ctUuid] = element
        }
    }
    return obj
}