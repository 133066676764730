import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import Cookies from "js-cookie";
import { useEffect, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { PiSealWarning } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import * as z from 'zod';
import { CookiesKeys, URLs } from '../../Constants/Consts';
import { fetchApi, splitOnCapitals } from '../../Helpers/Functions';
import SectionHeader from "../../components/CoreComponents/Details/SectionHeader.tsx";
import Loader from '../../components/CoreComponents/Loader';
import FormDDL from '../../components/CoreComponents/ReactFormComponents/FormDDL';
import FormInput from '../../components/CoreComponents/ReactFormComponents/FormInput';
import { addSelectedUser } from "../../store/SelectedUser";
import { updateDownloadTriggered } from '../../store/Utilities.ts';
import { RootState } from "../../store/store.ts";
import { AssignmentApiData, Project, UserAssignment, getProjectText } from '../../types/types';
import roleCheck from "../../utils/roleCheck.ts";
import { ROLES, RoleMapping } from "../../utils/roles.ts";

export default function UserPage() {
    const usersData = useSelector((state: RootState) => state.UserAssignment.Users) as any
    const schema = z.object({
        FullName: z.string().nonempty({ message: "Please enter the full name." }),
        KionEmail: z.string().email({ message: "Please enter a valid email." }),
        LoginId: z.string().email({ message: "Please enter the Login ID." }),
        ProjectSelection: z.number().gt(0, "A project must be selected."),
        DisciplineSelection: z.number().gt(0, "A discipline must be selected."),
        // SequenceSelection: z.string().optional(),
        // CACCSelection: z.string().optional(),
        DD1: z.string()
    }).refine(() => {
        return uuid != null || !usersData.some(user => user.kionEmail?.toLowerCase() === watchEmail?.toLowerCase());
    }, {
        message: "A user with this email already exists.",
        path: ["KionEmail"]
    }).refine(data => {
        return data.DD1.split(',').length < 11;
    }, {
        message: "Please select up to 10 or less sequences or controls to proceed.",
        path: ["SequenceSelection"]
    });
    type AssignmentFormType = z.infer<typeof schema>;

    const { uuid } = useParams();
    const navigate = useNavigate();
    const [saveFailedErrorMessage, setSaveFailedErrorMessage] = useState<string>();
    const [assignmentData, setAssignmentData] = useState<AssignmentApiData | null>(null);
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        reset,
        setValue,
    } = useForm<AssignmentFormType>({
        resolver: zodResolver(schema),
    });

    const SelectedUser = useSelector((state: RootState) => state.SelectedUser.SelectedUser);
    const { uuid: userUuid } = SelectedUser
    const userRole = ROLES[RoleMapping[Cookies.get(CookiesKeys.roles)]] || 'Unknown Role';
    const isAdminUser = roleCheck(userRole, [ROLES.UNITE_AdminUser, ROLES.UNITE_SuperAdmin, ROLES.UNITE_IT]);
    const watchEmail = useWatch({ control, name: "KionEmail" });
    const [isFetchingUserData, setIsFetchingUserData] = useState(false)
    const disciplineSelectionWatchValue = useRef(0)
    const projectSelectionWatchValue = useRef(0)
    const fetchAssignmentData = async () => {
        setIsFetchingUserData(true)
        const data = await fetchApi(URLs.getAssignmentUrl(uuid)).then(res => res.json()) as AssignmentApiData;
        projectSelectionWatchValue.current = data.projectNumber
        disciplineSelectionWatchValue.current = data.disciplineId
        setAssignmentData(data);
        resetAssignmentData(data);
        setIsFetchingUserData(false)
    };

    const createAssignmentData = () => {
        const data = {
            fullName: "",
            kionEmail: "",
            loginId: "",
            projectNumber: 0,
            disciplineId: 0,
            sequence: ""
        } as AssignmentApiData;
        setAssignmentData(data);
        resetAssignmentData(data);
    };

    const resetAssignmentData = (data: AssignmentApiData) => {
        reset({
            FullName: data.fullName,
            KionEmail: data.kionEmail,
            LoginId: data.loginId,
            ProjectSelection: data.projectNumber,
            DisciplineSelection: data.disciplineId,
            DD1: String(data.sequence ?? data.caCc ?? ''),
        });
    }

    useEffect(() => {
        if (uuid != null) {
            fetchAssignmentData();
        } else {
            createAssignmentData();
        }
    }, [uuid]);

    const { data: projects } = useQuery<Array<Project>, Error>({
        queryKey: ["projects"],
        queryFn: async () => {
            const response = await fetchApi(URLs.getProjectsUrl());
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const projectsData = await response.json();
            return projectsData.filter(project => project.isArchived === 0);
        },
        gcTime: 0,
    });

    const { allDisciplines: disciplines } = useSelector((state: RootState) => state.Discipline);
    const projectSelectionWatch = useWatch({ control, name: "ProjectSelection" })
    const disciplineSelectionWatch = useWatch({ control, name: "DisciplineSelection" })
    const projectNumver = Number(projectSelectionWatch ?? assignmentData?.projectNumber)
    const disciplineId = Number(disciplineSelectionWatch ?? assignmentData?.disciplineId)
    const [showSequenceOptionsEmptyError, setShowSequenceOptionsEmptyError] = useState(false)

    const { data: DD1, refetch: refetchDD1 } = useQuery({
        queryKey: ["DD1"],
        queryFn: () => {
            return fetchApi(URLs.getDropDownListing(projectNumver, disciplineId)).then(res => res.json())
        },
        enabled: (!!projectSelectionWatch && !!disciplineSelectionWatch) || (!!assignmentData?.projectNumber && !!assignmentData?.disciplineId),
        gcTime: 0,
    })

    //1 = Controls Engineering, 6 = Electrical Pre-Commissioning, 7 = 
    const showCACC = disciplineSelectionWatch === 1 || disciplineSelectionWatch === 6 || disciplineSelectionWatch === 7
    useEffect(() => {
        if (disciplineSelectionWatch && projectSelectionWatch &&
            (projectSelectionWatchValue.current !== projectSelectionWatch ||
                disciplineSelectionWatchValue.current !== disciplineSelectionWatch)) {
            refetchDD1().then(() => {
                setValue('DD1', '');
                setShowSequenceOptionsEmptyError(true);
            })
            disciplineSelectionWatchValue.current = disciplineSelectionWatch
            projectSelectionWatchValue.current = projectSelectionWatch
        }
    }, [disciplineSelectionWatch, projectSelectionWatch])

    const { mutateAsync, isPending: isMutating } = useMutation<any, Error, AssignmentApiData>({
        mutationKey: ["EditAssignment"],
        mutationFn: async (updatedData: AssignmentApiData) => {
            const url = uuid != null ? URLs.putAssignmentsUrl(updatedData.uuid) : URLs.postAssignmentsUrl();
            const method = uuid != null ? 'PUT' : 'POST';
            setLocalLoader(true)
            await fetchApi(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedData),
            }).then(response => {
                if (!response.ok) {
                    response.json().then(errorResponse => {
                        setSaveFailedErrorMessage(errorResponse.detail);
                        setLocalLoader(false)
                    })
                }
            }).catch(err => {
                setSaveFailedErrorMessage(err);
                setLocalLoader(false)
            })
        },
    });

    const dispatch = useDispatch();
    const [localLoader, setLocalLoader] = useState(false);
    const onSubmit = (formData: AssignmentFormType) => {
        if (!assignmentData.disciplineId && uuid) return
        const updatedData = {
            ...assignmentData,
            fullName: formData.FullName,
            kionEmail: formData.KionEmail,
            loginId: formData.LoginId,
            projectNumber: formData.ProjectSelection,
            disciplineId: formData.DisciplineSelection,
            sequence: formData.DD1,
            caCc: formData.DD1,
        };
        mutateAsync(updatedData).then(() => {
            setLocalLoader(true);
            const userRole = ROLES[RoleMapping[Cookies.get(CookiesKeys.roles)]] || 'Unknown Role';
            fetchApi(URLs.getAssignmentUsingLoginIdUrl(Cookies.get(CookiesKeys.username)))
                .then(res => res.json()).then((data: UserAssignment[]) => {
                    const user = data[0];
                    const userProject = projects.find(p => p.projectNumber === user.projectNumber)?.projectName;
                    const userDiscipline = disciplines.find(d => d.id === user.disciplineId)?.disciplineName;
                    const newUserData = {
                        ...user,
                        role: userRole,
                        projectNumber: user?.projectNumber,
                        disciplineId: user?.disciplineId,
                        sequence: user?.sequence,
                        project: userProject,
                        discipline: userDiscipline,
                        jwt: Cookies.get(CookiesKeys.jwt)
                    }
                    dispatch(addSelectedUser(newUserData));
                    if (!uuid) createAssignmentData()
                    setLocalLoader(false)
                    dispatch(updateDownloadTriggered({ key: "drawings", value: true }))
                })
        })
    };

    return (
        <>
            {localLoader || isMutating || isFetchingUserData ? <Loader loaders={{ isUpdatingUserDataLoading: localLoader, isFetchingUserData: isFetchingUserData }} /> :
                <>
                    <div className='max-w-screen-md mt-4'>
                        <div className="mt-8 mb-8">
                            <SectionHeader title="User Details" />
                        </div>

                        <>
                            <form className='relative' onSubmit={handleSubmit(onSubmit)}>
                                <FormInput
                                    required={true}
                                    errors={errors}
                                    defaultValue=""
                                    inputKey="FullName"
                                    registeredProps={register('FullName')}
                                    label={splitOnCapitals("Last Name, First Name")}
                                    placeholder={uuid === null ? "Last Name, First Name" : ""}
                                    disabled={!isAdminUser || uuid != null}
                                />
                                <FormInput
                                    required={true}
                                    errors={errors}
                                    defaultValue=""
                                    inputKey="KionEmail"
                                    registeredProps={register('KionEmail')}
                                    label="KION Email:"
                                    placeholder={uuid === null ? "first.last@kiongroup.com" : ""}
                                    disabled={!isAdminUser || uuid != null}
                                />
                                <FormInput
                                    required={true}
                                    errors={errors}
                                    defaultValue=""
                                    inputKey="LoginId"
                                    registeredProps={register('LoginId')}
                                    label="Login ID:"
                                    placeholder={uuid === null ? "A00XXXXX@kiongroup.com" : ""}
                                    disabled={!isAdminUser || uuid != null}
                                />
                                <FormDDL
                                    required={true}
                                    options={projects?.sort((a, b) => {
                                        if (a.projectName < b.projectName) {
                                            return -1;
                                        } else if (a.projectName > b.projectName) {
                                            return 1;
                                        }
                                        return 0;
                                    }).map(p => ({
                                        text: getProjectText(p),
                                        value: p.projectNumber,
                                    }))}
                                    control={control}
                                    errors={errors}
                                    defaultValue=""
                                    inputKey="ProjectSelection"
                                    label="Project Selection"
                                    name="ProjectSelection"
                                    disabled={false}
                                />
                                <FormDDL
                                    required={true}
                                    options={disciplines?.map(d => ({
                                        text: d.disciplineName as string,
                                        value: d.id as number,
                                    })).sort((a, b) => {
                                        if (a.text < b.text) {
                                            return -1;
                                        } else if (a.text > b.text) {
                                            return 1;
                                        }
                                        return 0;
                                    })}
                                    control={control}
                                    errors={errors}
                                    defaultValue=""
                                    inputKey="DisciplineSelection"
                                    label="Discipline Selection"
                                    name="DisciplineSelection"
                                    disabled={false}
                                />
                                <FormDDL
                                    isMultiSelect={true}
                                    required={false}
                                    options={(DD1?.length ? DD1 : [])?.map(s => ({
                                        text: String(s),
                                        value: s
                                    }))}
                                    control={control}
                                    errors={errors}
                                    defaultValue={''}
                                    inputKey={"DD1"}
                                    label={showCACC ? "CACC Selection" : "Sequence Selection"}
                                    name={"DD1"}
                                    onChangeEvent={(selectedArray) => {
                                        setValue('DD1', selectedArray.join(','));
                                    }}
                                    showErrorIfOptionsEmpty={showSequenceOptionsEmptyError}
                                    errorTextIfOptionsIsEmpty={'The project and discipline selected have no CACCs available'}
                                    disabled={!isAdminUser && userUuid.toString() !== uuid} />

                                <div>
                                    <p hidden={saveFailedErrorMessage == null} style={{ color: "red" }}>
                                        {saveFailedErrorMessage}<br />
                                        Please ask support or try again later.
                                    </p>

                                </div>
                                <div className='flex items-center mt-16'>
                                    <button type="button" onClick={() => navigate(-1)}
                                        className='absolute left-0 mt-3 text-gray-900 bg-gray-500 dark:text-gray-900 dark:bg-gray-500'>Cancel
                                    </button>
                                    <button type="submit"
                                        className='absolute right-0 text-white bg-orange-1000 dark:text-gray-800 dark:bg-orange-1000'>Submit
                                    </button>
                                </div>
                            </form>

                            <div className='flex items-center justify-center mt-10 p-12 '>
                                <PiSealWarning className='mr-2 text-uniteOrange' />
                                <h2>{uuid != null ?
                                    'In order to edit the assignment, you must be connected to the internet.' :
                                    'In order to add a user you must be connected to the internet.'}
                                </h2>
                            </div>
                        </>
                    </div>
                </>
            }
        </>
    );
}
