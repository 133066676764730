import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Issue } from '../types/types';

const initialState:
    {
        Issues: Issue[]

    } = {
    Issues: []
}

const Units = createSlice({
    name: 'issues',
    initialState,
    reducers: {
        addIssues: (state, action: PayloadAction<Issue[]>) => {
            state.Issues = action.payload
        },
    },
});

export const { addIssues } = Units.actions;

export default Units.reducer;
