import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { findSyncSequences } from '../components/SyncManager/CheckListSync';
import { getByPath } from '../Helpers/Functions';
import { DDCheckList, DDObject, DrilldownMappingAPIResponse } from '../types/DataDD/DataDD';
import { MPCStatus } from '../types/types';

const initialState:
  {
    ChecklistsObject: DDObject,
    selectedUnitNumber: string,
    selectedSequenceNumber: string,
    selectedPath: string,
    selectedInspectionTask: string | null,
    MPCStatus?: Array<MPCStatus>,
    DrilldownMappingsAPIResponse: Array<DrilldownMappingAPIResponse>
  } = {
  DrilldownMappingsAPIResponse: [],
  ChecklistsObject: {},
  selectedSequenceNumber: '',
  selectedUnitNumber: '',
  selectedPath: '',
  selectedInspectionTask: null,
  MPCStatus: []
}

function overridePayloadStatus(object, items: Array<DDCheckList>) {
  if (items.length === 0) return
  for (let prop in object) {
    if (typeof object[prop] === 'object' && object[prop] !== null) {
      overridePayloadStatus(object[prop], items)
    } else if (prop === 'ctUuid' && object[prop] !== null) {
      const toSyncItem = items.find(c => c.ctUuid === object[prop])
      if (toSyncItem) {
        object.statusId = toSyncItem.statusId
        object["notSync"] = toSyncItem.notSync
      }
    }
  }
}

const SequencesSlice = createSlice({
  name: 'sequences',
  initialState,
  reducers: {
    addChecklists: (state, action: PayloadAction<DDObject>) => {
      const notSyncChecklists = findSyncSequences(state.ChecklistsObject)
      // get the new items out to be added later
      const updated = notSyncChecklists.filter(f => !f.creation)
      // override the notSync items with current ones 
      overridePayloadStatus(action.payload, updated)
      // add the new notSync items to the current ones
      notSyncChecklists.forEach(notSyncElement => {
        if (notSyncElement.creation)
          action.payload[notSyncElement.dd01][notSyncElement.dd02][notSyncElement.ctUuid] = notSyncElement;
      });
      // add the new payload
      state.ChecklistsObject = action.payload
    },
    changeCheckListItemStatus: (state, action: PayloadAction<{
      SequenceId: string, UnitId: string, checklistUuid: string, status: number, notSync?: boolean
    }>) => {
      state.ChecklistsObject[action.payload.SequenceId][action.payload.UnitId][action.payload.checklistUuid.toString()].status = action.payload.status;
      state.ChecklistsObject[action.payload.SequenceId][action.payload.UnitId][action.payload.checklistUuid.toString()].notSync = action.payload.notSync;
    },
    updateCheckListItem: (state, action: PayloadAction<{ completePath: string, checklistItem: any }>) => {
      const pathArray = action.payload.completePath.split('.');
      let current = state.ChecklistsObject;
      for (let i = 0; i < pathArray.length - 1; i++) {
        const key = pathArray[i];
        if (current[key] === undefined) {
          return;
        }
        current[key] = { ...current[key] };
        current = current[key];
      }
      const lastKey = pathArray[pathArray.length - 1];
      current[lastKey] = { ...action.payload.checklistItem };
    },
    setSelectedSequenceNumber: (state, action: PayloadAction<string>) => {
      state.selectedSequenceNumber = action.payload ?? ""
    },
    setSelectedUnitNumber: (state, action: PayloadAction<string>) => {
      state.selectedUnitNumber = action.payload ?? ""
    },
    setSelectedPath: (state, action: PayloadAction<string>) => {
      state.selectedPath = action.payload ?? ""
    },
    setSelectedInspectionTask: (state, action: PayloadAction<string>) => {
      state.selectedInspectionTask = action.payload;
    },
    addMPCStatus: (state, action: PayloadAction<Array<MPCStatus>>) => {
      state.MPCStatus = action.payload
    },
    updateMPCStatus: (state, action: PayloadAction<{ SequenceId: string | number, UnitId: string | number, MPCStatus: Array<MPCStatus> }>) => {
      for (const mpcStatusItem of action.payload.MPCStatus) {
        state.ChecklistsObject[action.payload.SequenceId][action.payload.UnitId][mpcStatusItem.meChecklistUuid].mpcStatus = mpcStatusItem.mpcStatus;
      }
    },
    setDrilldownMappingsAPIResponse: (state, action: PayloadAction<Array<DrilldownMappingAPIResponse>>) => {
      state.DrilldownMappingsAPIResponse = action.payload
    },
    addNewTask: (
      state,
      action: PayloadAction<{ path: string, newTask: DDCheckList }>
    ) => {
      const pathArray = action.payload.path.split('.');
      let current = state.ChecklistsObject;
      for (let i = 0; i < pathArray.length - 1; i++) {
        const key = pathArray[i];
        if (!current[key]) {
          current[key] = {};
        }
        current[key] = { ...current[key] };
        current = current[key];
      }
      const lastKey = pathArray[pathArray.length - 1];
      current[lastKey] = { ...action.payload.newTask };
    },
    updateTaskSyncStatus: (
      state,
      action: PayloadAction<{
        path: string,
        notSync: boolean;
      }>
    ) => {
      const checklistItem = getByPath(action.payload.path, state.ChecklistsObject)
      if (checklistItem) {
        checklistItem.notSync = action.payload.notSync;
      } else {
        console.error('Checklist item not found for sync update.');
      }
    }
  },
});

export const {
  addChecklists,
  changeCheckListItemStatus,
  updateCheckListItem,
  setSelectedSequenceNumber,
  setSelectedUnitNumber,
  setSelectedInspectionTask,
  addMPCStatus,
  updateMPCStatus,
  addNewTask,
  setSelectedPath,
  setDrilldownMappingsAPIResponse
} = SequencesSlice.actions;
export default SequencesSlice.reducer;