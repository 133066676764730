export default function ProvidersTreeBuilder(Providers) {
    const InitialComponent = ({ children }) => <>{children}</>
    return Providers.reduce((AccumulatedComponents, Provider) => {
        return ({ children }) => {
            return (<AccumulatedComponents>
                <Provider>{children}</Provider>
            </AccumulatedComponents>)
        }
    }, InitialComponent)
}
