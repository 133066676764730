import { Link } from "react-router-dom";

export default function Breadcrumb({ trail, showIndices = [] }) {
  const isShownOnMobile = (index) => 
    showIndices.length === 0 || showIndices.includes(index);

  return (
    <nav
      className="flex px-3 py-2 text-gray-700 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-900 dark:border-gray-700 sm:px-5 sm:py-3"
      aria-label="Breadcrumb"
    >
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        {trail.map((item, index) => (
          <li key={index} className={`${isShownOnMobile(index) ? 'inline-flex' : 'hidden md:inline-flex'} items-center`}>
            {item.link ? (
              <Link
                to={item.link}
                className={`items-center text-xs font-medium text-gray-600 dark:text-gray-300 sm:text-sm ${isShownOnMobile(index) ? '' : 'hidden md:inline-flex'}`}
              >
                {item.label}
              </Link>
            ) : (
              <span className={`items-center text-xs font-medium text-gray-800 dark:text-gray-100 sm:text-sm ${isShownOnMobile(index) ? '' : 'hidden md:inline-flex'}`}>
                {item.label}
              </span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}