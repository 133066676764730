import { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { URLs } from "../../../Constants/Consts";
import userOnlineCheck from "../../../Helpers/CustomHooks/userOnlineCheck";
import { fetchApi } from "../../../Helpers/Functions";
import { updateChangeLogs } from "../../../store/ChangeLogs";
import { RootState } from "../../../store/store";
import { ChecklistTaskApiResponse } from "../../../types/DataDD/DataDD";
import { PunchlistApiType } from "../../../types/PunchList/PunchListSchema";
import { isChecklist, isPunchList } from "../Comments/Comments";
import SectionHeader from "../Details/SectionHeader";
import ViewTable from "../ViewTable";

export default function Changelogs({ item }: { item: ChecklistTaskApiResponse | PunchlistApiType }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const online = userOnlineCheck();
  const run = useRef(false);
  const { ChangeLogs } = useSelector((state: RootState) => state.ChangeLogs);

  useEffect(() => {
    const fetchChangeLogs = async () => {
      if (!run.current && online) {
        let endpoint;
        let uuidType;
        let uuid;

        if (isChecklist(item)) {
          endpoint = URLs.getUpdatedChangeLogsUrl(item.projectNumber, item.disciplineId, item.ctUuid);
          uuidType = "Checklist";
          uuid = item.ctUuid;
        } else if (isPunchList(item)) {
          endpoint = URLs.getUpdatedChangeLogsUrl(item.projectNumber, item.creatorDisciplineId, null, item.punchListUuid);
          uuidType = "Punchlist";
          uuid = item.punchListUuid;
        }

        if (endpoint) {
          try {
            const logs = await fetchApi(endpoint).then(res => res.json());
            dispatch(updateChangeLogs({ uuidType, uuid, data: logs }));
          } catch (err) {
            console.error("Error updating changelogs:", err);
          }
        }
        run.current = true;
      }
    };

    fetchChangeLogs();
  }, [dispatch, online, item, ChangeLogs]);

  const filteredChangelogs = useMemo(() => {
    return ChangeLogs.filter((c) => {
      if (isChecklist(item)) {
        return c.ctUuid === item.ctUuid;
      }
      if (isPunchList(item)) {
        return c.punchlistUuid === item.punchListUuid;
      }
      return false;
    });
  }, [ChangeLogs, item]);

  const columns = useMemo(() => [
    {
      Header: "Data",
      accessor: "description",
    },
    {
      Header: "Changed By",
      accessor: "changedBy",
    },
    {
      Header: "Changed On",
      accessor: "changedOn",
      Cell: ({ value }) => {
        const utcDate = new Date(value + 'Z');
        return utcDate.toLocaleString();
      },
    },
  ], []);

  const sortedChangelogs = useMemo(() => {
    const ff = filteredChangelogs.sort((a, b) => new Date(b.changedOn).getTime() - new Date(a.changedOn).getTime());
    return ff
  }, [filteredChangelogs]);

  return (
    <>
      <div className="flex gap-3 items-center mb-2">
        <div className={twMerge("flex gap-3 items-center")}>
          <SectionHeader title="Changelog" />
        </div>
      </div>
      <div>
        {
          filteredChangelogs.length > 0 ? (
            <ViewTable
              columns={columns}
              data={sortedChangelogs}
              onRowClick={(row) => navigate(`/ChangeLog/${row.changelogUuid}`)}
            />
          ) : (
            <span className='text-sm text-gray-500 ml-6'>No Changelogs</span>
          )
        }
      </div>
    </>
  );
}
