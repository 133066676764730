import React from 'react';

type DetailGridProps = {
    details: { label: string; value: string }[];
};

const DetailGrid: React.FC<DetailGridProps> = ({ details }) => {
    return (
        <div className="grid grid-cols-[auto_minmax(0,1fr)] gap-x-12 gap-y-2">
            {details.map(detail => (
                <React.Fragment key={detail.label}>
                    <h2 className="text-sm font-semibold whitespace-nowrap capitalize">{detail.label}</h2>
                    <p className="text-sm break-words">{detail.value}</p>
                </React.Fragment>
            ))}
        </div>
    );
};

export default DetailGrid;