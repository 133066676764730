import { useEffect, useState } from 'react';
import useCheckLists from "../../components/SyncManager/CheckListSync";
import useComments from "../../components/SyncManager/CommentsSync";
import usePunchlists from "../../components/SyncManager/PunchlistSync";
import PendingItem from './PendingItem';
import { twMerge } from 'tailwind-merge';
import SectionHeader from '../../components/CoreComponents/Details/SectionHeader';

export default function PendingSyncItems() {
    const { getChecklistItemsToSync } = useCheckLists();
    const checklists = getChecklistItemsToSync();
    const { getCommentsToSync } = useComments();
    const comments = getCommentsToSync();
    const { getPunchlistsToSync } = usePunchlists();

    const [pls, setPls] = useState([]);

    useEffect(() => {
        (async () => {
            const punchlistsPromises = getPunchlistsToSync();
            const punchlists = [];
            for (let k = 0; k < punchlistsPromises.length; k++) {
                const pl = await punchlistsPromises[k];
                punchlists.push(pl);
            }
            setPls(punchlists);
        })();
    }, []);

    const totalPendingItems = checklists.length + comments.length + pls.length;

    return (
        <>
            {totalPendingItems === 0 ? (
                <div className="flex gap-3 items-center mb-2">
                    <div className={twMerge("flex gap-3 items-center")}>
                    <SectionHeader title="No Pending Sync Items" />
                    </div>
                </div>
            ) : (
                <>
                    <div className="flex gap-3 items-center mb-2">
                        <div className={twMerge("flex gap-3 items-center")}>
                        <SectionHeader title="Pending Sync Items" />
                        </div>
                    </div>
                    <div className="m-2">
                        {checklists.map((c, i) => <PendingItem key={`checklist-${i}`} objectData={c} />)}
                        {comments.map((c, i) => <PendingItem key={`comment-${i}`} objectData={c} />)}
                        {pls.map((c, i) => <PendingItem key={`punchlist-${i}`} objectData={c} />)}
                    </div>
                </>
            )}
        </>
    );
}