import {useState} from "react";
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {RootState} from '../../../../../store/store';
import {useTableData} from './usePunchListsTableData';
import {FaSearch} from "react-icons/fa";
import {MdOutlineClear} from "react-icons/md";
import {twMerge} from 'tailwind-merge';
import {PunchListViewOptions} from "../../../../../Constants/Consts.ts";
import {PunchlistApiType} from "../../../../../types/PunchList/PunchListSchema.ts";

export default function tab() {
    const {disciplineId, listStatus} = useParams()
    let {viewType} = useParams();
    viewType = viewType || (disciplineId ? PunchListViewOptions.DISCIPLINE.name : viewType);

    const navigate = useNavigate()
    const {ProjectPunchLists} = useSelector((state: RootState) => state.PunchList)
    const {SelectedUser} = useSelector(
        (state: RootState) => state.SelectedUser
    );

    const punchlistsByViewType: PunchlistApiType[] = [];

    if (viewType === PunchListViewOptions.DISCIPLINE.name && disciplineId) {
        punchlistsByViewType.push(...ProjectPunchLists.filter(pl => Number(pl.assignedDisciplineId) === Number(disciplineId)))
    } else if (viewType === PunchListViewOptions.ALL_PUNCH_LISTS.name) {
        punchlistsByViewType.push(...ProjectPunchLists)
    } else if (viewType === PunchListViewOptions.MY_PUNCH_LISTS.name) {
        punchlistsByViewType.push(...ProjectPunchLists.filter(pl => pl.identifiedBy === SelectedUser.email || pl.identifiedBy === SelectedUser.kionEmail))
    } else if (viewType === PunchListViewOptions.ERM_RELEVANT.name) {
        punchlistsByViewType.push(...ProjectPunchLists.filter(pl => pl.ermRelevant))
    }

    const punchlists = punchlistsByViewType.filter(p => String(p.status) == listStatus)
        .sort((a, b) => Date.parse(b.dateIdentified) - Date.parse(a.dateIdentified))

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        search, setSearch
    } = useTableData(punchlists)

    const [searchInspectionTask, setSearchInspectionTask] = useState('');

    function resetSearchInspectionTask() {
        setSearchInspectionTask("");
    }

    return (
        <div className="overflow-x-auto">
            <div className="flex border rounded-lg my-2">
                <div className="border-r rounded-r-lg">
                    <span
                        className="flex px-5 items-center text-sm font-medium bg-gray-50 py-3 dark:bg-gray-900 dark:text-gray-200">
                        <FaSearch/>
                        <span className="ml-2">Search</span>
                    </span>
                </div>
                <div className="flex justify-end relative w-full">
                    <input
                        value={search ?? ""}
                        className='border px-2 h-full w-full'
                        id="search"
                        onChange={(e) => setSearch(e.target.value)}
                        type="text"
                        placeholder="Search any field below"
                    />
                    <div className="border-l bg-gray-50">
                        <button type="button" className="py-3" title="Clear" onClick={resetSearchInspectionTask}
                                hidden={searchInspectionTask === ""}>
                            <MdOutlineClear/>
                        </button>
                    </div>
                </div>
            </div>

            <table {...getTableProps()} className="text-sm text-left">
                <thead className="border-b font-medium dark:border-neutral-500">
                {headerGroups.map(headerGroup => {
                    const {key, ...restProps} = headerGroup.getHeaderGroupProps()
                    return (
                        <tr key={key} {...restProps}>
                            {headerGroup.headers.map(column => {
                                const {key, ...restProps} = column.getHeaderProps(column.getSortByToggleProps())
                                return (
                                    <th
                                        className="px-6 py-4 whitespace-nowrap overflow-hidden truncate"
                                        key={key} {...restProps}>
                                        {column.render("Header")}
                                        <span>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? ' 🔽'
                                                        : ' 🔼'
                                                    : ''}
                                            </span>
                                    </th>
                                )
                            })}
                        </tr>
                    )
                })}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    const {key, ...restProps} = row.getRowProps()
                    return (
                        <tr
                            key={key}
                            onClick={() => {
                                navigate(`/PunchList/DetailsPage/${row.original.punchListId}`)
                            }}
                            style={{width: "85vw"}}
                            className={twMerge("cursor-pointer hover:bg-slate-300", i % 2 == 0 ? "border-b bg-white dark:border-neutral-500 dark:bg-neutral-600"
                                : "border-b bg-neutral-100 dark:border-neutral-500 dark:bg-neutral-700")}
                            {...restProps}>
                            {row.cells.map(cell => {
                                const {key, ...restProps} = cell.getCellProps()
                                return (
                                    <td
                                        className={"px-6 py-4"}
                                        key={key} {...restProps}
                                    >
                                        <div className="flex">
                                            {cell.render("Cell")}
                                        </div>
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    )
}
