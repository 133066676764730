import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Category } from '../types/types';

const initialState:
    {
        Categories: Category[]

    } = {
    Categories: []
}

const Units = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        addCategories: (state, action: PayloadAction<Category[]>) => {
            state.Categories = action.payload
        },
    },
});

export const { addCategories } = Units.actions;

export default Units.reducer;
