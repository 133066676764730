import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PunchListActionButtons from '../../../../src/components/ActionButtons/PunchListActionButtons';
import Comments from '../../../components/CoreComponents/Comments/Comments.tsx';
import DetailGrid from '../../../components/CoreComponents/Details/DetailsGrid.tsx';
import SectionHeader from "../../../components/CoreComponents/Details/SectionHeader.tsx";
import { RootState } from '../../../store/store';
import PunchListDetails from "./PunchListDetails.tsx";
import PunchListImages from "./PunchListImages.tsx";
import Changelogs from '../../../components/CoreComponents/Changelog/Changelogs.tsx';
import { setModal } from "../../../store/Utilities.ts";
import { DDCheckList } from '../../../types/DataDD/DataDD.ts';
import { getDrilldownType } from '../../../Constants/Consts.ts';
import { splitOnCapitals } from '../../../Helpers/Functions.ts';

export default function DetailsPage() {
    const { punchListId } = useParams();
    const filteredPunchList = useSelector((state: RootState) => { return state.PunchList.ProjectPunchLists.find((item) => item.punchListId === punchListId) });
    const relatedChecklistDetails = useSelector((state: RootState) => state.RelatedChecklistDetails.relatedChecklistDetails);
    const relatedLoadedChecklistDetails = useSelector((state: RootState) => state.SequenceObject.ChecklistsObject);

    const { SelectedUser } = useSelector((state: RootState) => state.SelectedUser)
    const { DrilldownMappingsAPIResponse } = useSelector((state: RootState) => state.SequenceObject);
    const drilldownType = getDrilldownType(SelectedUser.disciplineId)
    const DrilldownMappingObject = DrilldownMappingsAPIResponse.find(d => d.drilldownTypeId === drilldownType)

    function returnLoadedChecklistItem(object, ctUuid) {
        for (let prop in object) {
            if (object.hasOwnProperty(prop)) {
                if (typeof object[prop] === 'object' && object[prop] !== null) {
                    const obj = returnLoadedChecklistItem(object[prop], ctUuid);
                    if (obj) return obj;
                } else if (prop === 'ctUuid' && object[prop] !== null && object[prop]?.toUpperCase?.() === ctUuid?.toUpperCase?.()) {
                    return object
                }
            }
        }
    }


    const getChecklistDetails = useCallback((checklistUuid) => {
        const dd01Naming = DrilldownMappingObject?.dd01
        const dd02Naming = DrilldownMappingObject?.dd02
        const ap01Naming = DrilldownMappingObject?.ap01
        if (checklistUuid) {
            const item = returnLoadedChecklistItem(relatedLoadedChecklistDetails, checklistUuid) as DDCheckList;
            if (item) {
                return {
                    [dd01Naming]: item.dd01,
                    [dd02Naming]: item.dd02,
                    [ap01Naming]: item.ap01 || 'N/A',
                    inspectionTask: item.taskDescription || 'N/A',
                };
            }

            const detail = relatedChecklistDetails.find(d => d.ctUuid === checklistUuid);

            if (detail) {
                return {
                    [dd01Naming]: detail.dd01,
                    [dd02Naming]: detail.dd02,
                    [ap01Naming]: detail.ap01 || 'N/A',
                    inspectionTask: detail.taskDescription || 'N/A',
                };
            }

        } else if (filteredPunchList?.adhocSequenceCa) {
            return {
                [dd01Naming]: filteredPunchList?.adhocSequenceCa,
                [dd02Naming]: filteredPunchList?.adhocUnitDevice,
                [ap01Naming]: 'N/A',
                inspectionTask: 'N/A',
            };
        }

        return {
            [dd01Naming]: 'N/A',
            [dd02Naming]: 'N/A',
            [ap01Naming]: 'N/A',
            inspectionTask: 'N/A',
        };
    }, [relatedLoadedChecklistDetails, relatedChecklistDetails, filteredPunchList?.adhocSequenceCa, filteredPunchList?.adhocUnitDevice]);

    const checklistUuid = filteredPunchList?.ctUuid;
    const checklistDetailsObject = getChecklistDetails(checklistUuid);
    const checklistDetails = useMemo(() => Object.keys(checklistDetailsObject)?.map(k => {
        return {
            label: splitOnCapitals(k),
            value: checklistDetailsObject[k]
        }
    }), [checklistDetailsObject]);
    // const checklistDetails = useMemo(() => [
    //     { label: "Model", value: checklistDetailsObject.modelFull?.toString() },
    //     { label: "Inspection Task", value: checklistDetailsObject.inspectionTask?.toString() }
    // ], [checklistDetailsObject]);

    const generalDetails = useMemo(() => {
        const details = [
            { label: "Checklist UUID", value: filteredPunchList?.ctUuid },
            { label: "Punch List UUID", value: filteredPunchList?.punchListId },
            { label: "Created By Email", value: filteredPunchList?.identifiedBy },
        ];
        return details.filter(detail => detail.value != null);
    }, [filteredPunchList]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const addEditPhotoModal = () => {
        if (detailsEditEnabled) {
            navigate(`/PunchList/DetailsPage/${filteredPunchList?.punchListId}/Image`);
        }
        dispatch(setModal({
            Chilren: () =>
                <div>
                    Proceeding will discard any changes that haven't been saved. Do you wish to continue?<br />
                </div>,
            onConfirm: () => {
                navigate(`/PunchList/DetailsPage/${filteredPunchList?.punchListId}/Image`)
            },
            open: true
        }));
    }

    const [detailsEditEnabled, setDetailsEditEnabled] = useState(false);

    return (
        <>
            <PunchListActionButtons item={filteredPunchList} addEditPhotoModal={addEditPhotoModal} />

            <div className="max-w-4xl">

                <PunchListDetails
                    item={filteredPunchList}
                    detailsEditEnabled={detailsEditEnabled}
                    setDetailsEditEnabled={setDetailsEditEnabled}
                />

                <hr className="border-t mt-8 mb-8" />

                <PunchListImages item={filteredPunchList} addEditPhotoModal={addEditPhotoModal} />

                <hr className="border-t mt-8 mb-8" />

                <div className="flex flex-col mb-4">
                    <SectionHeader title="Checklist Details" />
                </div>

                <DetailGrid details={checklistDetails} />

                <hr className="border-t mt-8 mb-8" />

                <div className="flex flex-col mt-16 mb-4">
                    <Comments item={filteredPunchList} />
                </div>

                <div className="flex flex-col mt-16 mb-4">
                    <Changelogs item={filteredPunchList} />
                </div>

                <div className="flex flex-col mt-16 mb-4">
                    <SectionHeader title="General Details" />
                </div>

                <DetailGrid details={generalDetails} />

            </div>
        </>
    );
}

