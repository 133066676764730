import { useMemo } from 'react';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';

const useTechnologyOptions = (AssignedDiscipline: number) => {
    const { Technology: TechnologiesOptions } = useSelector((state: RootState) => state.Technology);
    const { LinkDisciplineTecnoCategory } = useSelector((state: RootState) => state.LinkDisciplineTecnCategory);

    return useMemo(() => {
        const relevantLinks = LinkDisciplineTecnoCategory.filter(link => link.disciplineId === AssignedDiscipline);

        const technologyOrderMap = new Map<number, number>();
        
        relevantLinks.forEach(link => {
            if (!technologyOrderMap.has(link.technologyId)) technologyOrderMap.set(link.technologyId, link.listOrder)
        });

        const filteredTechnologies = TechnologiesOptions.filter(technology =>
            technologyOrderMap.has(technology.technologyId)
        );

        const sortedTechnologies = filteredTechnologies.sort((a, b) => {
            const orderA = technologyOrderMap.get(a.technologyId) || 0;
            const orderB = technologyOrderMap.get(b.technologyId) || 0;
            return orderA - orderB;
        });

        const options = sortedTechnologies.map(technology => ({
            text: technology.technologyDescription,
            value: technology.technologyId,
        }));
        return options;
    }, [AssignedDiscipline, LinkDisciplineTecnoCategory, TechnologiesOptions]);
};

export default useTechnologyOptions;