import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
    PunchList,
    PunchListDetailsType,
    PunchListSiteMapType,
    PunchlistApiType,
    detailsSchema,
    siteMapSchema
} from '../types/PunchList/PunchListSchema';
import { zodKeys } from '../Helpers/Functions';

interface PunchListState {
    ProjectPunchLists: PunchlistApiType[];
    PunchListObject: PunchList;
    punchListDetails: Record<string, PunchListDetailsType>;
}

const initialState: PunchListState = {
    PunchListObject: {
        OriginalDiscipline: 0,
        AssignedDiscipline: 0,
        ProjectNumber: 0,
        Category: 0,
        Issue: 0,
        DrawingHash: '',
        SequenceControlArea: '',
        UnitDevice: '',
        IssueDescription: '',
        DefectPic1: '',
        DefectPic2: '',
        DueDate: '',
        Priority: 0,
        DoesPunchListRequireReinspection: true,
        ErmRelevant: false,
        ErmType: '',
        punchListId: ''
    },
    ProjectPunchLists: [],
    punchListDetails: {},
};

const PunchListSlice = createSlice({
    name: 'punchList',
    initialState,
    reducers: {
        addDetails: (state, action: PayloadAction<PunchListDetailsType>) => {
            state.PunchListObject = { ...state.PunchListObject, ...action.payload };
        },
        resetDetails: (state) => {
            for (const key of zodKeys(detailsSchema)) {
                state.PunchListObject[key] = '';
            }
        },
        addDefectPhoto: (state, action: PayloadAction<{ photoNumber: number; value: string, url: string, notSync?: boolean, creation?: boolean }>) => {
            state.PunchListObject[`DefectPic${action.payload.photoNumber}`] = action.payload.url;
            state.PunchListObject[`storedDefectPic${action.payload.photoNumber}`] = {
                notSync: action.payload.notSync,
                creation: action.payload.creation,
                value: action.payload.value
            };
        },
        addCorrectedPhoto: (state, action: PayloadAction<{ photoNumber: number; value: string, url: string, notSync?: boolean, creation?: boolean }>) => {
            state.PunchListObject[`CorrectedPic${action.payload.photoNumber}`] = action.payload.url;
            state.PunchListObject[`storedCorrectedPic${action.payload.photoNumber}`] = {
                notSync: action.payload.notSync,
                creation: action.payload.creation,
                value: action.payload.value
            };
        },
        addSiteMap: (state, action: PayloadAction<PunchListSiteMapType>) => {
            state.PunchListObject = { ...state.PunchListObject, ...action.payload };
        },
        addSiteMapInput: (state, action: PayloadAction<{ name: string; value: any }>) => {
            state.PunchListObject[action.payload.name] = action.payload.value;
        },
        resetSiteMap: (state) => {
            for (const key of zodKeys(siteMapSchema)) {
                state.PunchListObject[key] = '';
            }
        },
        resetPuncList: (state) => {
            state.PunchListObject = {};
        },
        addProjectPunchLists: (state, action: PayloadAction<PunchlistApiType[]>) => {
            const toSync = state.ProjectPunchLists.filter(item => item.notSync && item.creation);
            const toSyncEdit = state.ProjectPunchLists.filter(item => item.notSync && !item.creation);
            const plsMap = new Map<string, PunchlistApiType>(action.payload.map(item => [item.punchListId, item]));
            toSyncEdit.forEach(plToedit => {
                if (plsMap.has(plToedit.punchListId)) {
                    const currentPl = plsMap.get(plToedit.punchListId);
                    plsMap.set(plToedit.punchListId, { ...currentPl, ...plToedit });
                }
            });
            state.ProjectPunchLists = Array.from(plsMap.values()).concat(toSync);
        },
        updateDisciplinePunchlistsWithPhotos: (state, action: PayloadAction<{ disciplineId: number, punchlistsWithPhotos: PunchlistApiType[] }>) => {
            for (let i = 0; i < action.payload.punchlistsWithPhotos.length; i++) {
                const newPL = action.payload.punchlistsWithPhotos[i];
                const plIndex = state.ProjectPunchLists.findIndex(p => p.punchListId == newPL.punchListId);
                if (plIndex) {
                    state.ProjectPunchLists[plIndex] = newPL;
                }
            }
        },
        addProjectPunchList: (state, action: PayloadAction<PunchlistApiType>) => {
            state.ProjectPunchLists.push(action.payload);
        },
        addPunchListDetails: (state, action: PayloadAction<{ punchListId: string; details: PunchListDetailsType }>) => {
            const { punchListId, details } = action.payload;
            state.punchListDetails[punchListId] = details;
        },
        updatePunchlistItem: (state, action: PayloadAction<PunchlistApiType>) => {
            const selectedIndex = state.ProjectPunchLists.findIndex(p => p.punchListId === action.payload.punchListId);
            if (selectedIndex >= 0) {
                state.ProjectPunchLists[selectedIndex] = action.payload;
            }
        }
    },
});

export const {
    addDetails,
    addSiteMap,
    resetDetails,
    addDefectPhoto,
    addCorrectedPhoto,
    // addStringfiedDefectPhoto,
    // addStringfiedCorrectedPhoto,
    updateDisciplinePunchlistsWithPhotos,
    addSiteMapInput,
    resetSiteMap,
    addProjectPunchLists,
    addProjectPunchList,
    resetPuncList,
    addPunchListDetails,
    updatePunchlistItem
} = PunchListSlice.actions;

export default PunchListSlice.reducer;