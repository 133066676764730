import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

const useIssuesOptions = (AssignedDiscipline: number) => {
    const { LinkDisciplineIssue } = useSelector((state: RootState) => state.LinkDisciplineIssue);
    const { Issues } = useSelector((state: RootState) => state.Issues);

    return useMemo(() => {

        const relevantLinks = LinkDisciplineIssue.filter(link => link.disciplineId === AssignedDiscipline);

        const issueOrderMap = new Map<number, number>();
        
        relevantLinks.forEach(link => {
            if (!issueOrderMap.has(link.issueId)) issueOrderMap.set(link.issueId, link.listOrder);
        });

        const filteredIssues = Issues.filter(issue => issueOrderMap.has(issue.issueId));

        const sortedIssues = filteredIssues.sort((a, b) => {
            const orderA = issueOrderMap.get(a.issueId) || 0;
            const orderB = issueOrderMap.get(b.issueId) || 0;
            return orderA - orderB;
        });

        const options = sortedIssues.map(issue => ({
            text: issue.issueDescription,
            value: issue.issueId,
        }));

        return options;
    }, [AssignedDiscipline, LinkDisciplineIssue, Issues]);
};

export default useIssuesOptions;