import { Outlet } from "react-router-dom";
import Navbar from "../components/CoreComponents/NavBar/NavBar";

export default function RouterLayout() {

  return (
    <div className="p-6">
      <div>
        <Navbar />
      </div>
      <Outlet />
    </div>
  );
}