import Navbar from "../components/CoreComponents/NavBar/NavBar";

export default function Layout({ children }) {

  return (
    <div className="max-w-4xl p-6">
      <div className="max-w-4xl">
        <Navbar />
      </div>
      {children}
    </div>
  );
}