import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { URLs } from "../../Constants/Consts";
import { fetchApi } from "../../Helpers/Functions";
import { addRelatedChecklistDetails } from "../../store/RelatedChecklistDetails";
import { updateDownloadTriggered, updateHasDownloadedValue } from "../../store/Utilities";
import { RootState } from "../../store/store";

export default function storeRelatedChecklistDetails() {
    const dispatch = useDispatch();
    const [isloading, setIsloading] = useState(false)
    const [storedRelatedChecklistDetails, setRelatedChecklistDetails] = useState();
    const SelectedUser = useSelector((state: RootState) => state.SelectedUser.SelectedUser);
    const { downloadTriggered: { readonlyelatedChecklistDetails: triggered } } = useSelector(
        (state: RootState) => state.Utilities
    );
    const calledOnceRef = useRef(false)
    useEffect(() => {
        (async () => {
            if (navigator.onLine && SelectedUser.jwt && SelectedUser.projectNumber > 0 && (triggered)) {
                calledOnceRef.current = true
                setIsloading(true);
                const url = URLs.getRelatedChecklistDetailsUrl(SelectedUser.projectNumber);
                const relatedChecklistDetails = await fetchApi(url, {
                    headers: { "Authorization": `Bearer ${SelectedUser.jwt}` },
                }).then(res => res.json());
                setRelatedChecklistDetails(relatedChecklistDetails)
                if (!relatedChecklistDetails || relatedChecklistDetails.status === 400) {
                    setIsloading(false);
                    return;
                }
                dispatch(addRelatedChecklistDetails(relatedChecklistDetails));
                dispatch(updateHasDownloadedValue({ key: "readonlyelatedChecklistDetails", value: true }))
                dispatch(updateDownloadTriggered({ key: "readonlyelatedChecklistDetails", value: false }))
                setIsloading(false);

                // chain punchlist download
                dispatch(updateDownloadTriggered({ key: "punchList", value: true }))
            }
        })();
    }, [SelectedUser.jwt, SelectedUser.projectNumber, dispatch, triggered]);

    return { isloading, storedRelatedChecklistDetails };
}