import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ChangeLogType } from '../types/types';

const initialState:
    {
        ChangeLogsTypes: ChangeLogType[]

    } = {
    ChangeLogsTypes: []
}

const Units = createSlice({
    name: 'changelogsTypes',
    initialState,
    reducers: {
        addChangeLogsTypes: (state, action: PayloadAction<ChangeLogType[]>) => {
            state.ChangeLogsTypes = action.payload
        },
    },
});

export const { addChangeLogsTypes } = Units.actions;

export default Units.reducer;
