import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RelatedChecklistDetails } from '../types/types';

const initialState:
    {
        relatedChecklistDetails: RelatedChecklistDetails[],

    } = {
        relatedChecklistDetails: [],
}

const relatedChecklistDetailsSlice = createSlice({
    name: 'relatedChecklistDetails',
    initialState,
    reducers: {
        addRelatedChecklistDetails: (state, action: PayloadAction<RelatedChecklistDetails[]>) => {
            state.relatedChecklistDetails = action.payload;
        },
    },
});

export const { addRelatedChecklistDetails } = relatedChecklistDetailsSlice.actions;

export default relatedChecklistDetailsSlice.reducer;