import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CookiesKeys, URLs } from '../../Constants/Consts'
import { fetchApi } from '../../Helpers/Functions'
import { RootState } from '../../store/store'
import Cookies from "js-cookie";
import { addLinkDisciplineTecnoCategory } from '../../store/LinkDisciplineTecnCategory'

export default function storeLinkDisciplineTecnCategory() {

    const [isloading, setIsloading] = React.useState(false)
    const dispatch = useDispatch()
    const SelectedUser = useSelector(
        (state: RootState) => state.SelectedUser.SelectedUser
    );
    const { downloadTriggered: { rootCauses: triggered } } = useSelector(
        (state: RootState) => state.Utilities
    );
    const calledOnceRef = useRef(false)
    const currentToken = Cookies.get(CookiesKeys.jwt)
    React.useEffect(() => {
        (async () => {
            try {
                if (navigator.onLine && SelectedUser.jwt && currentToken && (!calledOnceRef.current || triggered)) {
                    calledOnceRef.current = true
                    setIsloading(true)
                    const rootCauses = await fetchApi(
                        URLs.getLinkDisciplineTecnCategory())
                        .then(res => res.json())
                    // update store
                    dispatch(addLinkDisciplineTecnoCategory(rootCauses))
                    setIsloading(false)
                }
            } catch (error) {
                console.error("error from download root causes", error);
            }
        })()
    }, [SelectedUser.jwt, triggered])
    return { isloading }
}
