import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Comment } from '../types/types';

const initialState:
    {
        Comments: Comment[]

    } = {
    Comments: []
}

const Units = createSlice({
    name: 'comments',
    initialState,
    reducers: {
        addComments: (state, action: PayloadAction<Comment[]>) => {
            // todo: check notSync items
            const toSync = state.Comments.filter(item => item.notSync && item.creation)
            const toSyncEdit = state.Comments.filter(item => item.notSync && !item.creation)
            // the edit should be blended in 
            const commentsMap = new Map<string, Comment>(action.payload.map(item => [item.commentUuid, item]));
            toSyncEdit.forEach(commentToedit => {
                if (commentsMap.has(commentToedit.commentUuid)) {
                    const currentComment = commentsMap.get(commentToedit.commentUuid);
                    commentsMap.set(commentToedit.commentUuid, { ...currentComment, ...commentToedit });
                }
            })
            // add the newly not sync comments
            state.Comments = Array.from(commentsMap.values()).concat(toSync)
        },
        addComment: (state, action: PayloadAction<Comment>) => {
            state.Comments.push(action.payload)
        },
        editComment: (state, action: PayloadAction<Comment>) => {
            const commentIndex = state.Comments.findIndex(c => c.commentUuid === action.payload.commentUuid)
            if (commentIndex >= 0) {
                const newComment = { ...action.payload }
                const currentComment = state.Comments[commentIndex]
                if (currentComment.notSync && currentComment.creation) {
                    newComment.creation = true
                }
                state.Comments.splice(commentIndex, 1, newComment)
            }
        },
    },
});

export const { addComments, addComment, editComment } = Units.actions;

export default Units.reducer;
