import { TbCloudDownload } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { DownloadedModules, updateDownloadTriggered } from "../../store/Utilities";
import { RootState, persistor } from "../../store/store";
import storeAdHocUnits from "./storeAdHocUnits";
import storeAllUsers from "./storeAllUsers";
import storeCategories from "./storeCategories";
import { storeChangeLogs } from "./storeChangeLogs";
import { storeComments } from "./storeComments";
import storeDisciplinePunchList from "./storeDisciplinePunchList2";
import storeDisciplines from "./storeDisciplines";
import storeDrawings from "./storeDrawings";
import storeIssues from "./storeIssues";
import storeModels from "./storeModels";
import storeProjects from "./storeProjects";
import storeRelatedChecklistDetails from "./storeRelatedChecklistDetails";
import storeStatus from "./storeStatus";
import storeTasks from "./storeTasks";
import { storeChangeLogsTypes } from "./storechangelogsTypes";

export function DownloadUserRelatedData() {

    // downloading order matters !!!

    /**
     * once optione
     * retrigger
     * keep notSync data  
    */

    const { isloading: isIssuesLoading } = storeIssues();
    const { isloading: isStatusLoading } = storeStatus();
    const { isloading: isCategoriesLoading } = storeCategories();
    const { isloading: isLogsTypesLoading } = storeChangeLogsTypes();

    // chained

    const { isloading: isProjectsLoading } = storeProjects();
    const { isloading: isDisciplineLoading } = storeDisciplines();
    const { isloading: isFilesLoading } = storeDrawings();
    const { isLoading: isUsersLoading } = storeAllUsers();
    const { isloading: isCommentsLoading } = storeComments();
    // const { isLoading: isSequencesLoading } = storeSequences();
    const { isLoading: isTasksLoading } = storeTasks()
    const { isloading: isLogsLoading } = storeChangeLogs();
    const { isLoading: isAdHocUnitsLoading } = storeAdHocUnits();
    const { isLoading: isModelsLoading } = storeModels();
    const { isloading: isRelatedChecklistDetailsLoading } = storeRelatedChecklistDetails();
    const { isloading: isPunchlistLoading, isPhotosLoading: isPunchlistImagesLoading } = storeDisciplinePunchList();

    // Add any new stored files to src\components\CoreComponents\Loader.tsx in order to give it a nickname / lodading display name
    return {
        isIssuesLoading,
        isProjectsLoading,
        isFilesLoading,
        isLogsTypesLoading,
        isDisciplineLoading,
        isUsersLoading,
        isCommentsLoading,
        isStatusLoading,
        isCategoriesLoading,
        isLogsLoading,
        isAdHocUnitsLoading,
        isModelsLoading,
        isRelatedChecklistDetailsLoading,
        // isSequencesLoading,
        isPunchlistLoading,
        isPunchlistImagesLoading,
        isTasksLoading
    }
}

export default function Downloaded({ modules }: { modules?: DownloadedModules }) {
    const dispatch = useDispatch()
    const { unsyncItemsCount } = useSelector(
        (state: RootState) => state.Utilities
    );
    const selectedDownloadTriggered = {
        issues: true, categories: true,
        changeLogsTypes: true, status: true,
        projects: true
    }
    return (
        <div
            className="scale-125 cursor-pointer"
            onClick={() => {
                if (unsyncItemsCount !== 0) {
                    toast("Cannot download without sync with backend first!", { type: "error" })
                    return
                }
                persistor.purge().then(() => {
                    Object.keys(modules ?? selectedDownloadTriggered).forEach((key: keyof DownloadedModules) => {
                        dispatch(updateDownloadTriggered({ key: key, value: true }))
                    })
                })
            }}
        >
            <TbCloudDownload />
        </div>
    )
}

// mpc statue ?
// update checklist item !!
// create checklist item !!
// model full ?