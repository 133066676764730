import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModelItem } from "../types/Model/ModelSchema.ts";

interface ModelState {
    ModelItem: ModelItem;
}

const initialState: ModelState = {
    ModelItem: {}
};


const ModelSlice = createSlice({
    name: 'model',
    initialState,
    reducers: {
        setModels: (state, action: PayloadAction<ModelItem>) => {
            state.ModelItem = action.payload;
        },
    },
});
export const {
    setModels
} = ModelSlice.actions;
export default ModelSlice.reducer;
