import { useDispatch, useSelector } from "react-redux";
import { setModalOpen } from "../../store/Utilities";
import { RootState } from "../../store/store";

export default function Modal() {
    const { open, onConfirm, onReject, onClose, Chilren, width } = useSelector((state: RootState) => state.Utilities.modal);
    const dispatch = useDispatch()
    const closeModal = () => {
        onClose?.();
        dispatch(setModalOpen(false));
    }
    const onConfirmModal = () => {
        onConfirm();
        closeModal();
    }

    const onRejectModal = () => {
        onReject?.();
        closeModal();
    }
    return (
        <div
            onClick={closeModal}
            className={`fixed inset-0 flex justify-center items-center transition-colors z-10
            ${open ? "visible bg-black/20" : "invisible"}`}
        >
            <div
                onClick={(e) => e.stopPropagation()}
                className={`rounded-xl shadow p-4 transition-all bg-white text-black dark:bg-black dark:text-white flex gap-2
                ${open ? "scale-100 opacity-100" : "scale-125 opacity-0"} ${width ?? ''}`}
            >

                <div className="mt-4 p-2">
                    <span>{Chilren && <Chilren />}</span>
                    <div className="flex mt-6 justify-between">
                        <button className="bg-orange-1000 text-white" onClick={onConfirmModal}>YES</button>
                        <button className="bg-gray-200 text-black" onClick={onRejectModal}>CANCEL</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
