import { zodResolver } from '@hookform/resolvers/zod';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { URLs, imageType } from '../../../../Constants/Consts';
import useCheckList from "../../../../Helpers/CustomHooks/useStoredCheckListObject";
import { blobToBase64, fetchApi, getRandomUuid } from '../../../../Helpers/Functions';
import { mapPunchListToAPI } from '../../../../Helpers/Mappers';
import SectionHeader from "../../../../components/CoreComponents/Details/SectionHeader.tsx";
import ImageSelector from "../../../../components/CoreComponents/ImageSelector.tsx";
import Loader from '../../../../components/CoreComponents/Loader';
import { addDefectPhoto, addProjectPunchList } from '../../../../store/PunchList';
import { RootState } from '../../../../store/store';
import { PunchList, PunchlistApiType, siteMapSchema } from '../../../../types/PunchList/PunchListSchema';
import './Photos.css';

export default function Photos({ type, id, previous }) {
    const { selectedPath } = useSelector((state: RootState) => state.SequenceObject);
    const { checkListObject, markFailed } = useCheckList({ path: selectedPath });

    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const { handleSubmit } = useForm({
        resolver: zodResolver(siteMapSchema),
    });

    const PunchList = useSelector((state: RootState) => state.PunchList)
    const { SelectedUser } = useSelector((state: RootState) => state.SelectedUser)
    const dispatch = useDispatch()
    const { DrawingId } = useSelector((state: RootState) => state.PunchList.PunchListObject)
    const { storedDefectPic1, storedDefectPic2 } = useSelector((state: RootState) => state.PunchList.PunchListObject)
    const uploadDefectPhoto = async (image: Blob, punchListId: string, photoNumber: number): Promise<{ punchListDefectPhoto?: string, data: string }> => {
        if (!image) return new Promise((resolve) => { resolve({ punchListDefectPhoto: '', data: '' }) })
        const photoName = `${punchListId}.defect_pic_${photoNumber}.${imageType}`;
        const formData = new FormData();
        formData.append("files", image, photoName);
        const imageData = await blobToBase64(image)
        return new Promise((resolve) => {
            fetchApi(URLs.postFilePunchListImage(SelectedUser.projectNumber, punchListId, "Defect"), {
                method: 'POST',
                body: formData,
                redirect: 'follow'
            }).then(response => {
                if (response.ok) {
                    const punchListDefectPhoto = `punch_list_images/${SelectedUser.projectNumber}/${punchListId}/Defect/${photoName}`;
                    // store the stringfied image
                    dispatch(addDefectPhoto({ photoNumber: photoNumber, value: imageData, url: punchListDefectPhoto }))
                    resolve({ punchListDefectPhoto, data: imageData });
                }
            }).catch(_ => {
                if (!navigator.onLine) {
                    // store the stringfied image with notSync label
                    dispatch(addDefectPhoto({ photoNumber: photoNumber, value: imageData, notSync: true, creation: true, url: '' }))
                    resolve({ data: imageData, punchListDefectPhoto: '' });
                }
            })
        });
    }

    const onSubmit = async () => {
        setLoading(true);
        const randomPunchListId = getRandomUuid();
        const defectPicturesResult = await Promise.all<{
            punchListDefectPhoto?: string;
            data: string
        }>([
            imageSelectorRefDefectPhoto1.current?.getImage().then(blob => {
                return uploadDefectPhoto(blob, randomPunchListId, 1);
            }),
            imageSelectorRefDefectPhoto2.current?.getImage().then(blob => {
                return uploadDefectPhoto(blob, randomPunchListId, 2);
            })
        ]);
        const data = {
            ...PunchList.PunchListObject,
            projectNumber: String(SelectedUser.projectNumber),
            identifiedBy: SelectedUser.kionEmail,
            dateIdentified: (new Date()).toISOString(),
            status: 6,
            drawingId: Number(DrawingId),
            punchListId: randomPunchListId,
            defectPic1: defectPicturesResult[0].punchListDefectPhoto,
            defectPic2: defectPicturesResult[1].punchListDefectPhoto
        } as PunchList
        const apiData = mapPunchListToAPI(data)
        try {
            await fetchApi(URLs.postPunchListsUrl(), {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(apiData)
            }).then(res => res.json()).then((list) => {
                const dataWithStoredPhotos: PunchlistApiType = {
                    ...apiData,
                    storedDefectPic1: defectPicturesResult[0].data ? { value: defectPicturesResult[0].data } : null,
                    storedDefectPic2: defectPicturesResult[1].data ? { value: defectPicturesResult[1].data } : null
                }
                dispatch(addProjectPunchList({ ...dataWithStoredPhotos, punchListId: list.itemId }))
            })
        } catch (error) {
            if (!navigator.onLine) {
                const dataWithStoredPhotos: PunchlistApiType = {
                    ...apiData,
                    storedDefectPic1: defectPicturesResult[0].data ? { value: defectPicturesResult[0].data, notSync: true, creation: true } : null,
                    storedDefectPic2: defectPicturesResult[1].data ? { value: defectPicturesResult[1].data, notSync: true, creation: true } : null
                }
                dispatch(addProjectPunchList({ ...dataWithStoredPhotos, notSync: true, creation: true, }))
            }
        } finally {
            if (type === "CheckList") {
                const selectedItem = checkListObject[id];
                markFailed(selectedItem).then(res => res.json()).then(data => {
                    if (data?.success)
                        navigate(`/PunchList/DetailsPage/${randomPunchListId}`, { replace: true });
                }).catch((_) => navigate(`/PunchList/DetailsPage/${randomPunchListId}`, { replace: true }))
            } else {
                navigate(`/PunchList/DetailsPage/${randomPunchListId}`, { replace: true })
            }
            setLoading(false);
        }
    };

    const goToDetails = async () => {
        const f1 = await imageSelectorRefDefectPhoto1.current?.getImage()
        if (f1) {
            const imageData1 = await blobToBase64(f1)
            dispatch(addDefectPhoto({ photoNumber: 1, value: imageData1, url: '' }))
        }
        const f2 = await imageSelectorRefDefectPhoto2.current?.getImage()
        if (f2) {
            const imageData2 = await blobToBase64(f2)
            dispatch(addDefectPhoto({ photoNumber: 2, value: imageData2, url: '' }))
        }
        previous()
    };

    const imageSelectorRefDefectPhoto1 = useRef(null);
    const imageSelectorRefDefectPhoto2 = useRef(null);

    return (
        <>
            {loading ? (
                <Loader loaders={{ isCreatingPunchListLoading: loading }} />
            ) : (
                <>
                    <div className='mt-8 mb-8'>
                        <SectionHeader title='Select A Photo' />
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="upload-box flex flex-wrap">
                            <label htmlFor="upload1">Defect Photo 1</label>
                            <div className='w-full' style={{ minHeight: '200px' }}>
                                <ImageSelector originalImage={storedDefectPic1?.value ?? null} ref={imageSelectorRefDefectPhoto1} imageType={imageType}></ImageSelector>
                            </div>
                        </div>
                        <div className="upload-box flex flex-wrap">
                            <label htmlFor="upload2">Defect Photo 2</label>
                            <div className='w-full' style={{ minHeight: '200px' }}>
                                <ImageSelector originalImage={storedDefectPic2?.value ?? null} ref={imageSelectorRefDefectPhoto2} imageType={imageType}></ImageSelector>
                            </div>
                        </div>
                        <button type="button" onClick={goToDetails} className='save-btn absolute left-0 ml-6'>Previous</button>
                        <button type="submit" className='save-btn absolute right-0 mr-6'>Create Punch List</button>
                    </form>
                </>
            )}
        </>
    );
}