import { Control, Controller, FieldValues, UseFormRegister } from "react-hook-form";

export default function FormYesNo({
    errors, inputKey, label, control, name, required = false, defaultValue, showLabel = true, onChangeValue
}: {
    defaultValue: boolean,
    showLabel?: boolean
    errors: {},
    control: Control<FieldValues>,
    inputKey: string,
    required?: boolean,
    label?: string,
    name: string,
    register: UseFormRegister<FieldValues>,
    onChangeValue?: (event) => void;
}) {

    return (
        <div className='flex flex-col md:flex-row mb-4 items-center justify-between'>
            <div hidden={!showLabel} className='text-base text-grey-darkest md:w-1/3'>
                <label htmlFor={inputKey} className='text-base'>
                    {label}{required && <span className='text-red-700 px-2 text-base'>*</span>}
                </label>
            </div>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className='flex gap-2 justify-end items-center md:w-2/3'>
                        <label className='rounded py-2 px-3 text-white text-sm uppercase bg-gray-400 cursor-pointer'>
                            <input
                                type="radio"
                                ref={ref}
                                onBlur={onBlur}
                                onChange={() => {
                                    onChange(true);
                                    if (onChangeValue) {
                                        onChangeValue(true);
                                    }
                                }}
                                checked={value === true}
                                className="mr-2 dark:text-gray-200"
                            />
                            Yes
                        </label>
                        <label className='rounded py-2 px-3 text-white text-sm uppercase bg-gray-400 cursor-pointer'>
                            <input
                                type="radio"
                                ref={ref}
                                onBlur={onBlur}
                                onChange={() => {
                                    onChange(false);
                                    if (onChangeValue) {
                                        onChangeValue(false);
                                    }
                                }}
                                checked={value === false}
                                className="mr-2"
                            />
                            No
                        </label>
                    </div>
                )}
            />
            {errors[inputKey] && <p className="text-red-500 text-xs">{errors[inputKey]?.message}</p>}
        </div>
    );
}