import { Feature } from 'ol';
import { boundingExtent } from 'ol/extent';
import { Point } from 'ol/geom';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NumberToStatus, StatusToColor } from '../../Constants/Consts';
import { layerContext } from '../../components/Providers/LayersProvider';
import { RootState } from '../../store/store';
import "./punchlistViewer.css";
import mapPin from '/map-pin.svg';

export default function PunchlistViewer({ layoutId, punchlistId }) {
    const { mapObject, pointersLayer, overlayObject } = useContext(layerContext)
    const { ProjectPunchLists } = useSelector((state: RootState) => state.PunchList)
    const navigate = useNavigate()
    const filteredPunchlists = ProjectPunchLists.filter(p => {
        if (punchlistId)
            return p.locationPin && p.locationPin !== ","
                && p.drawingId === Number(layoutId) && p.punchListUuid === punchlistId
        else return p.locationPin && p.locationPin !== ","
            && p.drawingId === Number(layoutId)
    })
    const closerRef = useRef<HTMLDivElement>()
    const contentRef = useRef<HTMLDivElement>()
    const overlayRef = useRef<HTMLDivElement>()

    const mapClickHandler = (e) => {
        pointersLayer.getFeatures(e.pixel).then((clickedFeatures) => {
            if (clickedFeatures.length) {
                const features = clickedFeatures[0].get('features');
                if (features?.length > 1) {
                    const extent = boundingExtent(
                        features.map((r) => r.getGeometry().getCoordinates())
                    );
                    mapObject.getView().fit(extent, { duration: 1000, padding: [50, 50, 50, 50] });
                    const coordinate = e.coordinate;
                    features.forEach(feature => {
                        if (contentRef.current) {
                            const lin = document.createElement('p')
                            lin.textContent = `- Go to punchlist details: ${NumberToStatus[feature.get("status")]}`
                            lin.onclick = () => navigate("/PunchList/DetailsPage/" + feature.get("pId"))
                            const br = document.createElement('br')
                            contentRef.current.appendChild(lin).appendChild(br)
                        }
                    });
                    overlayObject.setPosition(coordinate);
                } else if (features?.length === 1) {
                    const feature = features[0]
                    navigate("/PunchList/DetailsPage/" + feature.get("pId"))
                }
            }
        });
    }
    const pointerStyleFun = clusteredfeature => {
        const feats = clusteredfeature.get('features');
        if (feats.length === 1) {
            return new Style({
                image: new Icon({
                    src: mapPin,
                    scale: 0.03,
                    color: StatusToColor[feats[0].get("status") ?? 0],
                    crossOrigin: 'anonymous',
                    // anchor:[0.2,0.2],
                })
            })
        }
        return new Style({
            image: new Icon({
                src: mapPin,
                scale: 0.03,
                color: StatusToColor[0],
                crossOrigin: 'anonymous',
                // anchor:[0.2,0.2],
            })
        })
    }
    useEffect(() => {
        if (pointersLayer) {

            filteredPunchlists.forEach(p => {
                const lonlatStr = p.locationPin.split(',')
                const lonlat = [parseFloat(lonlatStr[0]), parseFloat(lonlatStr[1])]
                const markerFeature = new Feature({
                    geometry: new Point(lonlat),
                    title: "marker",
                    pId: p.punchListUuid,
                    status: p.status
                });
                pointersLayer.getSource().getSource().addFeature(markerFeature)
            })
            pointersLayer.setStyle(pointerStyleFun)
        }

        if (overlayObject && overlayRef.current) {
            overlayObject.setElement(overlayRef.current)
        }

        if (closerRef.current && contentRef.current) {
            closerRef.current.style.cursor = "pointer"
            closerRef.current.onclick = function () {
                contentRef.current.innerHTML = ""
                overlayObject.setPosition(undefined);
                closerRef.current.blur();
                return false;
            };
        }
        
        if (mapObject && pointersLayer) {
            mapObject.on('click', mapClickHandler);
        }
        return () => {
            if (mapObject) mapObject.un("click", mapClickHandler)
            if (pointersLayer) {
                const addedFeats = pointersLayer.getSource().getSource().getFeatures()
                for (let i = 0; i < addedFeats.length; i++) {
                    const f = addedFeats[i];
                    pointersLayer.getSource().getSource().removeFeature(f)
                }
            }
            if (overlayObject) overlayObject.setPosition(undefined);
        }
    }, [mapObject, pointersLayer, filteredPunchlists])

    return (
        <div>
            <div ref={overlayRef} className="popup">
                <div ref={contentRef} className="popup-content"></div>
                <div ref={closerRef} className="popup-closer"></div>
            </div>
        </div>
    )
}
