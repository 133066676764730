import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAllUsers from '../../Helpers/CustomHooks/useAllUsers';
import Loader from '../CoreComponents/Loader';
import { DownloadUserRelatedData } from "../DownloadManager/DownloadManager";

import './MyAssignment.css';
import { addSelectedUser } from '../../store/SelectedUser';

function MyAssignment() {
  const { data: usersData, isLoading: isAllUsersLoading } = useAllUsers();
  const [selectedUser, setSelectedUser] = useState(null);
  const dispatch = useDispatch()
  const sortedUsers = [...usersData].sort((a, b) => a.fullName.localeCompare(b.fullName));
  const storedSelectedUser = useSelector((state) => state.SelectedUser.SelectedUser)
  
  useEffect(() => {
    if (storedSelectedUser) {
      setSelectedUser(storedSelectedUser)
    }
  }, [storedSelectedUser]);

  const handleUserSelect = (event) => {
    const selectedUserId = parseInt(event.target.value, 10);
    const selectedUser = sortedUsers.find((user) => user.id === selectedUserId);
    setSelectedUser(selectedUser);
  };

  const handleSave = () => {
    dispatch(addSelectedUser({
      ...selectedUser,
      projectNumber: selectedUser?.projectNumber,
      projectName: selectedUser?.project,
      discipline: selectedUser?.discipline,
      disciplineId: selectedUser?.disciplineId,
      sequence: selectedUser?.sequence
    }))
  }

  const {
    isProjectsLoading, isPunchlistLoading, isSequencesLoading, isDisciplineLoading
    , isIssuesLoading, isStatusLoading, isCategoriesLoading, isLogsLoading
    , isLogsTypesLoading, isCommentsLoading, isUsersLoading, isFilesLoading
    , isAdHocUnitsLoading, isModelsLoading
  } = DownloadUserRelatedData();
  
  const isLoading =
    isProjectsLoading || isPunchlistLoading || isSequencesLoading || isDisciplineLoading
    || isIssuesLoading || isStatusLoading || isCategoriesLoading || isLogsLoading
    || isLogsTypesLoading || isCommentsLoading || isUsersLoading || isFilesLoading
    || isAdHocUnitsLoading || isModelsLoading

  return (
    <>
      {isLoading ? <Loader /> :
        <>
          <div className="p-5 bg-gray-100 text-left max-w-screen-md mt-8">
            <div className="mb-4">
              <label htmlFor="userDropdown" className="block text-gray-700 font-bold mb-2">Assign Yourself A User:</label>
              <select
                id="userDropdown"
                onChange={handleUserSelect}
                defaultValue={storedSelectedUser?.id}
                className="p-2 w-full border rounded"
              >
                <option value="" disabled>Select A User</option>
                {isAllUsersLoading ? (
                  <option value="" disabled>Loading...</option>
                ) : (
                  sortedUsers.map((user) => (
                    <option key={user.id} value={user.id}>{user.fullName} - {user.role}</option>
                  ))
                )}
              </select>
            </div>
            {selectedUser && (
              <div className="bg-white p-6 rounded shadow text-left">
                <h2 className="text-xl font-bold mb-4 border-b pb-2 uppercase">Project Details:</h2>
                <p className="mt-2">
                  <span className="font-bold">Project:</span> {selectedUser.project} - {selectedUser.projectLocation}</p> {/* Need to import the project location (db column name is project_location) */}
                <p><span className="font-bold">Discipline:</span> {selectedUser.discipline}</p>
                <p><span className="font-bold">Sequence:</span> {selectedUser.sequence}</p>

                <h2 className="text-xl font-bold mt-10 mb-4 border-b pb-2 uppercase">User Details:</h2>
                <p className="mt-2"><span className="font-bold">Name:</span> {selectedUser.fullName}</p>
                <p><span className="font-bold">Login ID (Email):</span> {selectedUser.kionEmail}</p>
                {/* <p><span className="font-bold">Role:</span> {selectedUser.role}</p> */}
              </div>
            )}
            <div style={{ textAlign: "right" }}><button onClick={handleSave} className='save-btn mt-3'>SAVE</button></div>
          </div>
        </>
      }
    </>
  );
}

export default MyAssignment;
