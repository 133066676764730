import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { URLs } from "../../Constants/Consts"
import { fetchApi } from "../../Helpers/Functions"
import { addProjectAdHocUnitItem } from "../../store/AdHocUnit.ts"
import { updateDownloadTriggered, updateHasDownloadedValue } from "../../store/Utilities.ts"
import { RootState } from "../../store/store"


export default function storeAdHocUnits() {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)

    const projects = useSelector((state: RootState) => state.Project.projects)
    const SelectedUser = useSelector((state: RootState) => state.SelectedUser.SelectedUser);
    const { downloadTriggered: { adHocUnits: triggered } } = useSelector(
        (state: RootState) => state.Utilities
    );
    const calledOnceRef = useRef(false)
    useEffect(() => {
        (async () => {
            if (navigator.onLine && SelectedUser.jwt && (triggered)) {
                calledOnceRef.current = true
                setIsLoading(true)
                const fetchPromises = projects.map(project =>
                    fetchApi(URLs.getAdHocUnitsUrl(project.projectNumber))
                        .then(res => res.json())
                        .then(adHocUnits =>
                            dispatch(addProjectAdHocUnitItem({
                                projectNumber: project.projectNumber,
                                adHocUnitItems: adHocUnits
                            }))
                        ));
                await Promise.all(fetchPromises);

                dispatch(updateHasDownloadedValue({ key: "adHocUnits", value: true }))
                dispatch(updateDownloadTriggered({ key: "adHocUnits", value: false }))
                setIsLoading(false)
                // chain models download
                dispatch(updateDownloadTriggered({ key: "models", value: true }))
            }
        })()
    }, [SelectedUser.jwt, triggered])
    return { isLoading }
}
