import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { RootState } from '../../../../store/store';
import { fetchApi } from '../../../../Helpers/Functions';
import { URLs } from '../../../../Constants/Consts';
import { addComment } from '../../../../store/Comments';
import Loader from '../../Loader';
import { Comment } from '../../../../types/types';

export default function AddComment({ unitChecklistUuid, punchlistUuid }) {
    const { SelectedUser } = useSelector((state: RootState) => state.SelectedUser);
    const dispatch = useDispatch();
    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(false);

    const addCommentHandler = async (e) => {
        e.preventDefault();

        if (!comment) return;

        const commentBody: Comment
        // {
        //     commentType: number;
        //     projectNumber: string;
        //     disciplineId: string;
        //     commentText: string;
        //     addedBy: string;
        //     addedEmail: string;
        //     addedOn: string;
        //     commentUuid: string;
        //     ctUuid: string | null;
        //     punchlistUuid: string | null;
        // }
         = {
            commentType: 2,
            projectNumber: String(SelectedUser.projectNumber),
            disciplineId: String(SelectedUser.disciplineId),
            commentText: comment,
            addedBy: SelectedUser.fullName,
            addedEmail: SelectedUser.kionEmail,
            addedOn: new Date().toISOString(),
            commentUuid: crypto.randomUUID().toUpperCase(),
            ctUuid: unitChecklistUuid ?? null,
            punchlistUuid: punchlistUuid ?? null,
        };

        try {
            setLoading(true);
            await fetchApi(URLs.postCommentsUrl(), {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(commentBody)
            }).then(res => res.json()).then(() => {
                dispatch(addComment(commentBody));
            });
        } catch (error) {
            if (!navigator.onLine) {
                dispatch(addComment({ ...commentBody, notSync: true, creation: true }));
            }
        } finally {
            setComment("");
            setLoading(false);
        }
    };

    return (
        loading ? <Loader /> :
            <form className='flex gap-2 items-center'>
                <label htmlFor='addedComment'>Comment:</label>
                <input
                    id='addedComment'
                    value={comment}
                    type="text"
                    onChange={e => setComment(e.target.value)}
                    className='border h-10 w-full p-2'
                />
                <button
                    className='p-1 px-4 bg-[#fdb419] text-white hover:bg-[#aaa6a5]'
                    onClick={addCommentHandler}
                >
                    Add
                </button>
            </form>
    );
}
