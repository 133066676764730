import React, { useEffect, useRef, useState } from 'react';
// openlayers
import Feature from 'ol/Feature';
import olMap from 'ol/Map';
import View from 'ol/View';
import { doubleClick, primaryAction, singleClick } from 'ol/events/condition';
import { getCenter } from 'ol/extent.js';
import { Point } from 'ol/geom';
import Select from 'ol/interaction/Select.js';
import ImageLayer from 'ol/layer/Image';
import VectorLayer from 'ol/layer/Vector';
import { transform } from 'ol/proj';
import Projection from 'ol/proj/Projection.js';
import Static from 'ol/source/ImageStatic.js';
import VectorSource from 'ol/source/Vector';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import Modify from 'ol/interaction/Modify.js';
import { Collection } from 'ol';
import { containsCoordinate } from 'ol/extent';
import pinIcon from '/R.png';
import { useSelector } from 'react-redux';

export default function Map({
    ImageLayerUrl, setLocation, errors, location
}) {
    // set intial state
    const [map, setMap] = useState()
    const [selectedCoord, setSelectedCoord] = useState()

    // pull refs
    const mapElement = useRef()
    // create state ref that can be accessed in OpenLayers onclick callback function
    //  https://stackoverflow.com/a/60643670
    const mapRef = useRef()
    mapRef.current = map

    const extent = [0, 0, 1024, 968];
    const proj = new Projection({
        code: 'xkcd-image',
        units: 'pixels',
        extent: extent,
    })
    const xkcdImage = new ImageLayer({
        source: new Static({
            // url: 'https://imgs.xkcd.com/comics/online_communities.png',
            url: '/drawings/991646/P151945-C010_AF.png',
            projection: proj,
            imageExtent: extent,
        }),
    })
    const lowerLeft = [31.245760348745584, 30.04123277962705]
    const upperRight = [31.249244715229402, 30.04410585509858]
    
    const fextent = [...lowerLeft, ...upperRight]
    const centerGeometery = new Point([(fextent[0] + fextent[2]) / 2, (fextent[1] + fextent[3]) / 2])
    const centerCoordinates = [(fextent[0] + fextent[2]) / 2, (fextent[1] + fextent[3]) / 2]
    const markerFeature = new Feature({
        geometry: location?.length === 2 ? new Point(location) : centerGeometery,
        title: "marker"
    });
    const pointerStyle = new Style({
        image: new Icon({
            src: pinIcon,
            scale: 0.012
        })
    })
    const pointLayer = new VectorLayer({
        source: new VectorSource({
            projection: "EPSG:4326",
            features: [markerFeature]
        }),
        style: pointerStyle,
        title: 'pointLayer',
        extent: fextent
    });

    const layoutLayer = new ImageLayer({
        source: new Static({
            url: '',
            // url: 'https://imgs.xkcd.com/comics/online_communities.png',
            projection: "EPSG:4326",
            imageExtent: fextent,
        }),
        extent: fextent,
        title: "layout",
        zIndex: 0
    })
    const modifyInteraction = new Modify({
        // features: new Collection([markerFeature]),
        source: pointLayer.getSource(),
        style: pointerStyle,
    })
    // initialize map on first render - logic formerly put into componentDidMount
    useEffect(() => {
        // create map
        // const initialMap;
        if (mapRef.current) return
        mapRef.current = new olMap({
            target: mapElement.current,
            layers: [layoutLayer, pointLayer],
            view: new View({
                projection: "EPSG:4326",// 'EPSG:3857',
                center: getCenter(fextent),
                zoom: 17.5,
                maxZoom: 20,
                minZoom: 17
                // projection:proj
            }),
        })
        // set map onclick handler
        let cursorState = ''
        mapRef.current.on("pointerdown", (e) => {
            mapRef.current.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
                if (feature && layer?.get('title') === "pointLayer") {
                    cursorState = '-webkit-grabbing';
                    mapRef.current.getTargetElement().style.cursor = cursorState;
                }
            });
        });
        mapRef.current.on("pointerup", (e) => {
            mapRef.current.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
                if (layer?.get('title') === "pointLayer") {
                    cursorState = '-webkit-grab'
                    mapRef.current.getTargetElement().style.cursor = cursorState
                }
            });
        });
        mapRef.current.on("pointermove", (e) => {
            mapRef.current.getTargetElement().style.cursor = 'auto';
            mapRef.current.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
                if (feature && cursorState === '-webkit-grabbing') {
                    mapRef.current.getTargetElement().style.cursor = cursorState;
                    cursorState = '-webkit-grabbing'
                } else if (feature) {
                    mapRef.current.getTargetElement().style.cursor = '-webkit-grab';
                    cursorState = '-webkit-grab'
                }
            });
        });
        // save map and vector layer references to state
        setMap(mapRef.current);
        mapRef.current.addInteraction(modifyInteraction)
        modifyInteraction.on("modifyend", e => {
            let coord = e.features.getArray()[0].getGeometry().getCoordinates();
            if (!containsCoordinate(layoutLayer.getExtent(), coord)) {
                const feats = e.features.getArray()
                feats[0].setGeometry(centerGeometery)
            } else {
                setLocation(coord)
            }
        })
        return () => {
            // mapElement.current = null
        }
    }, [])

    useEffect(() => {
        if (mapRef.current) {
            const oldLayoutLayer = mapRef.current.getLayers().getArray().find(l => l.get("title") === "layout")
            mapRef.current.removeLayer(oldLayoutLayer)
            const newLayoutLayer = new ImageLayer({
                source: new Static({
                    url: ImageLayerUrl,
                    projection: "EPSG:4326",
                    imageExtent: fextent,
                }),
                extent: fextent,
                title: "layout"
            })
            mapRef.current.addLayer(newLayoutLayer)
            newLayoutLayer.setZIndex(1)
            pointLayer.setZIndex(2)
            const addedPointerLayer = mapRef.current.getLayers().getArray().find(l => l.get("title") === "pointLayer")
            if (ImageLayerUrl) addedPointerLayer.setVisible(true)
            else addedPointerLayer.setVisible(false)
        }
    }, [ImageLayerUrl])
    useEffect(() => {
        const resize = () => mapRef.current.updateSize()
        if (mapElement.current) {
            window.addEventListener("resize", resize)
        }
        return () => {
            window.removeEventListener("resize", resize)
        }
    }, [])

    return (
        <>
            <div ref={mapElement} className={`border-2 border-orange-1000 w-full`} id="map" style={{ height: "500px" }}></div>
            {errors['Location'] && <p style={{ color: "red" }}>{errors['Location'].message}</p>}
        </>
    )
}
