import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DrilldownMappingAPIResponse } from "../../../../../src/types/DataDD/DataDD"
import { URLs } from "../../../../Constants/Consts"
import { fetchApi, getByPath, splitOnCapitals } from "../../../../Helpers/Functions"
import { updateCheckListItem } from "../../../../store/SequenceObject"
import { RootState } from "../../../../store/store"
import { UnitChecklistItem } from "../../../../types/types"

export default function ({ path, CheckListItemUuid }) {
    const [isLoading, setIsLoading] = useState(false)
    const { ChecklistsObject } = useSelector((state: RootState) => state.SequenceObject)
    // const checkListItem = SequencesObject[SequenceId][UnitId][CheckListItemUuid];
    const checkListItem = getByPath(path, ChecklistsObject)

    const dispatch = useDispatch()
    useEffect(() => {
        (async () => {
            try {
                if (navigator.onLine) {
                    setIsLoading(true)
                    const checkListItem = await fetchApi(URLs.getChecListItemUrl(CheckListItemUuid)).then(res => res.json()) as UnitChecklistItem
                    // update redux store
                    dispatch(updateCheckListItem({ completePath: path, checklistItem: checkListItem }))
                }
            } finally {
                setIsLoading(false)
            }
        })()
    }, [])
    return { item: checkListItem, isLoading }

}

export const getDetilsFieldsArray = (item: any /* UnitChecklistItem */, detailsEnum: DrilldownMappingAPIResponse /* ChecklistTaskSelectedProps */) => {
    return Object.keys(item ?? {}).filter(itemKey => {
        return detailsEnum[itemKey] || itemKey === "statusId"
    }).map(k => {
        // add Status to the list of showed keys
        if ( k === "statusId") {
            return { name: "Status", value: item[k] }
        }
        if (!detailsEnum[k] || typeof detailsEnum[k] !== "string" ) return
        return { name: splitOnCapitals(detailsEnum[k]), value: item[k] }
    }).filter(Boolean)
}