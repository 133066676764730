import { RxDoubleArrowRight } from 'react-icons/rx';

type SectionHeaderProps = {
  title: string;
  size?: number;
};

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, size = 16 }) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <div style={{ display: 'flex', gap: '3px', alignItems: 'center', fontWeight: 'bold', fontSize: `${size}px`, textTransform: 'uppercase' }}>
      <div><RxDoubleArrowRight style={{ color: "#fdb419" }} /></div> {title}
    </div>
  </div>
);

export default SectionHeader;