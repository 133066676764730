import { Control, Controller, FieldValues } from "react-hook-form";
import Dropdown from "../Select.tsx";

type Option = {
    text: string;
    value: string | number | { value: string; id: string | number; };
};

type FormDDLProps = {
    defaultValue?: any;
    errors: Record<string, any>;
    required?: boolean;
    requiredText?: string;
    disabled?: boolean;
    isMultiSelect?: boolean
    control: Control<FieldValues>;
    inputKey: string;
    label?: string;
    name: string;
    options: Option[];
    showErrorIfOptionsEmpty?: boolean;
    errorTextIfOptionsIsEmpty?: string;
    onChangeEvent?: (event) => void;
};

export default function FormDDL({
    defaultValue,
    errors,
    inputKey,
    label,
    control,
    name,
    options,
    showErrorIfOptionsEmpty = false,
    errorTextIfOptionsIsEmpty = '',
    required = false,
    requiredText,
    disabled = false,
    onChangeEvent,
    isMultiSelect = false
}: FormDDLProps) {

    return (
        <div className='mb-2 md:mb-8'>
            <div className='flex flex-col md:flex-row items-start md:items-center'>
                {label && <label className='text-grey-darkest block text-left mb-1 md:mb-0 md:w-1/3' htmlFor={inputKey}>
                    {label}
                    {required && (
                        <span className='text-red-500 px-2 text-base' aria-hidden="true">*
                            {requiredText && <span className='pl-1'>{requiredText}</span>}
                        </span>
                    )}
                </label>}

                <div className="w-full ml-auto md:w-[400px]">

                    {!showErrorIfOptionsEmpty || options?.length !== 0 ? (
                        <Controller
                            name={name}
                            control={control}
                            defaultValue={defaultValue}
                            render={({ field }) => {
                                if (!field.value) {
                                    field.value = defaultValue;
                                }
                                return (
                                    <Dropdown
                                        disabled={disabled}
                                        options={options}
                                        selected={field.value}
                                        isMultiSelect={isMultiSelect}
                                        onChangeEvent={(selectedOptions: Option | Option[]) => {
                                            let selectValue;
                                            if (isMultiSelect) {
                                                selectValue = (selectedOptions as Option[]).map((option) => option.value);
                                            } else {
                                                selectValue = selectedOptions ? (selectedOptions as Option).value : null;
                                            }
                                            field.onChange(selectValue);
                                            onChangeEvent?.(selectValue);
                                        }}
                                    />
                                );
                            }}
                        />
                    ) : (
                        <span className='text-base py-1 px-1.5 text-orange-500 mt-4 md:mt-0'>
                            {errorTextIfOptionsIsEmpty}
                        </span>
                    )}
                </div>
            </div>
            {errors[inputKey] && <p className="text-red-500 text-xs text-right">{errors[inputKey]?.message}</p>}
        </div>
    );
}