import { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { MdOutlineClear } from "react-icons/md";

export default function SearchComponent({ onSearch, placeHolder = 'Search any field below' }) {
  const [searchTerm, setSearchTerm] = useState('');

  function handleSearchChange(e) {
    setSearchTerm(e.target.value);
    onSearch(e.target.value);
  }

  function resetSearch() {
    setSearchTerm("");
    onSearch("");
  }

  return (
    <div className="flex border rounded-lg my-2">
      <div className="border-r rounded-r-lg dark:border-gray-700">
        <span
          className="flex px-5 text-sm font-medium items-center bg-gray-50 py-3 dark:bg-gray-900 dark:text-gray-200">
          <FaSearch />
          <span className="ml-2">Search</span>
        </span>
      </div>

      <div className="flex justify-end relative w-full">
        <input
          value={searchTerm}
          className='border px-2 h-full w-full'
          id="search"
          onChange={handleSearchChange}
          type="text"
          placeholder={placeHolder}
        />
        <div className="border-l bg-gray-50">
          <button type="button" className="py-3" title="Clear" onClick={resetSearch}
            hidden={searchTerm === ""}>
            <MdOutlineClear />
          </button>
        </div>
      </div>
    </div>
  );
}
