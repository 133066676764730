import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { URLs } from "../../Constants/Consts"
import { fetchApi, getDrilldownStepsNumber } from "../../Helpers/Functions"
import { addChecklists, addMPCStatus, taskNodeDefaults } from "../../store/SequenceObject"
import { RootState } from "../../store/store"
import { updateDownloadTriggered, updateHasDownloadedValue } from "../../store/Utilities"
import { ChecklistTaskApiResponse, TaskNode } from "../../types/DataDD/DataDD"
import { MPCStatus, UserAssignment } from "../../types/types"

export default function storeTasks() {
    const dispatch = useDispatch()
    const { SelectedUser } = useSelector((state: RootState) => state.SelectedUser)
    const { downloadTriggered: { sequences: triggered } } = useSelector(
        (state: RootState) => state.Utilities
    );
    const [isLoading, setisLoading] = useState(false)
    const controllerRef = useRef<AbortController>()
    const calledOnceRef = useRef(false)
    useEffect(() => {
        (async () => {
            if (navigator.onLine && !!SelectedUser.jwt && triggered) {
                dispatch(updateDownloadTriggered({ key: "sequences", value: false }))
                calledOnceRef.current = true
                if (controllerRef.current) {
                    controllerRef.current?.abort?.()
                }
                controllerRef.current = new AbortController()
                setisLoading(true)

                const { UserTasks, mpcStatus } = await fetchUserTasks(SelectedUser, controllerRef.current.signal)
                dispatch(addMPCStatus(mpcStatus))
                dispatch(addChecklists(UserTasks ?? taskNodeDefaults))
                dispatch(updateHasDownloadedValue({ key: "sequences", value: true }))
                setisLoading(false)

                // chain changelogs download
                // dispatch(updateDownloadTriggered({ key: "changeLogs", value: true }))

                // chain adHocUnits download
                dispatch(updateDownloadTriggered({ key: "adHocUnits", value: true }))
            }
        })()
    }, [SelectedUser.jwt, SelectedUser.sequence, triggered])
    const isCheckListsLoading = isLoading
    return { isLoading: isCheckListsLoading }
}

async function storeMPCStatus(projectNumber: number, sequences: string): Promise<MPCStatus[]> {
    const sequencesArray = sequences.split(',')
    return await fetchApi(URLs.getMPCStatusUrl(projectNumber, sequencesArray)).then(res => res.json()).then(data => {
        return data
    })
}

export const fetchUserTasks = async (SelectedUser: UserAssignment, signal?: AbortSignal) => {
    // check if discipline id = 1, 7 or 6 -> return CACC objects instead
    // now all assignments are under sequnces key
    const userAllowedSequences = SelectedUser?.sequence.split(',')

    // get all getChecklistsUrl
    // aggregate them to construct the drop down object
    const userTasksPromises = await Promise.allSettled(
        userAllowedSequences.map(c =>
            fetchApi(URLs.getDD(SelectedUser.projectNumber, SelectedUser.disciplineId, c), { signal })
                .then(res => res.json())))
    const userTasks = []
    for (const element of userTasksPromises) {
        if (element.status === 'fulfilled') {
            const f = await element.value
            userTasks.push(f)
        }
    }
    const UserTasks3 = aggregateTasksData(userTasks.flat())
    console.log("UserTasks3: ", UserTasks3);
    // MECHANICAL PRE-COMMISSIONING
    let mpcStatus = []
    if (SelectedUser.disciplineId == 5) {
        mpcStatus = await storeMPCStatus(SelectedUser.projectNumber, SelectedUser.sequence)
    }
    return { UserTasks: UserTasks3, mpcStatus }
}

const aggregateTasksData = (userData: ChecklistTaskApiResponse[] = [], obj: TaskNode = structuredClone(taskNodeDefaults)) => {
    for (const element of userData) {
        // get number of valid dd__
        const steps = getDrilldownStepsNumber(element)
        // loop over the dd__ to add steps to the root element
        let dd = obj
        for (let i = 1; i <= steps; i++) {
            const atLastStep = i === steps
            const step = i < 10 ? `0${i}` : `${i}`
            const currentDD = element[`dd${step}`] // actual naming like CC20 -> CZA_540 -> ___
            const tc = dd.taskChilds ?? {}
            const taskChildsPreValue = atLastStep ? tc : null
            // console.log(i, steps, currentDD);
            if (!dd.childs[currentDD]) {
                dd.childs[currentDD] = {
                    name: `dd${step}`,
                    value: currentDD,
                    type: "node",
                    index: i - 1,
                    length: steps,
                    childs: {},
                    taskChilds: taskChildsPreValue
                }
            }
            // move the pointer
            dd = dd.childs[currentDD]
            // at the last step add the taskChilds object 
            if (atLastStep) {
                if (dd.taskChilds === null) dd.taskChilds = taskChildsPreValue
                dd.taskChilds[element.ctUuid] = element
            }
        }
    }
    return obj
}