import { useParams } from 'react-router-dom';
import Changelogs from '../../../../components/CoreComponents/Changelog/Changelogs.tsx';
import DetailsHeader from "../../../../components/CoreComponents/Details/SectionHeader.tsx";
import CheckListButtons from './CheckListButtons';
import TaskDetails from './ChecklistDetails';
import PunchLists from "./PunchLists.tsx";
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store.ts';
import Comments from '../../../../components/CoreComponents/Comments/Comments.tsx';
import { getByPath } from '../../../../Helpers/Functions.ts';

export default function DetailsDD() {
    const { path } = useParams()
    const { ChecklistsObject } = useSelector((state: RootState) => state.SequenceObject)
    const item = getByPath(path, ChecklistsObject)

    return (
        <div className='max-w-4xl'>
            <div className='mt-2'>
                <CheckListButtons {...{ path, item }} />
            </div>
            <DetailsHeader title="Checklist Details" />
            <div>
                <TaskDetails item={item} />
            </div>
            <hr className="border-t mt-8 mb-8" />
            <div className='mt-16'>
                <Comments item={item} />
            </div>
            <div className='mt-16 mb-8'>
                <PunchLists item={item} />
            </div>
            <div className='mt-16 mb-8'>
                <Changelogs item={item} />
            </div>
        </div>
    )
}
