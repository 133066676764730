import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CookiesKeys, URLs } from "../../Constants/Consts";
import { fetchApi } from "../../Helpers/Functions";
import { updateDownloadTriggered, updateHasDownloadedValue } from "../../store/Utilities";
import { addChangeLogsTypes } from "../../store/changelogsTypes";
import { RootState } from "../../store/store";
import Cookies from "js-cookie";

export function storeChangeLogsTypes() {
    const dispatch = useDispatch()
    const [isloading, setIsloading] = useState(false)
    const SelectedUser = useSelector(
        (state: RootState) => state.SelectedUser.SelectedUser
    );
    const { downloadTriggered: { changeLogsTypes: triggered } } = useSelector(
        (state: RootState) => state.Utilities
    );
    const calledOnceRef = useRef(false)
    const currentToken = Cookies.get(CookiesKeys.jwt)
    useEffect(() => {
        (async () => {
            if (navigator.onLine && SelectedUser.jwt && currentToken && (!calledOnceRef.current || triggered)) {
                calledOnceRef.current = true
                setIsloading(true)
                const logstypes = await fetchApi(URLs.getChangelogsTypesUrl())
                    .then(res => res.json())
                dispatch(addChangeLogsTypes(logstypes))
                dispatch(updateHasDownloadedValue({ key: "changeLogsTypes", value: true }))
                dispatch(updateDownloadTriggered({ key: "changeLogsTypes", value: false }))
                setIsloading(false)
            }
        })()
    }, [SelectedUser.jwt, triggered])
    return { isloading }
}