import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { URLs } from "../../Constants/Consts"
import { fetchApi } from "../../Helpers/Functions"
import { setModels } from "../../store/Model.ts"
import { updateDownloadTriggered, updateHasDownloadedValue } from "../../store/Utilities.ts"
import { RootState } from "../../store/store"
import { ModelApiItem } from "../../types/Model/ModelSchema.ts"


export default function storeModels() {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const SelectedUser = useSelector((state: RootState) => state.SelectedUser.SelectedUser);
    const { downloadTriggered: { models: triggered } } = useSelector(
        (state: RootState) => state.Utilities
    );
    const calledOnceRef = useRef(false)
    useEffect(() => {
        (async () => {
            if (navigator.onLine && SelectedUser.jwt && (triggered)) {
                calledOnceRef.current = true
                setIsLoading(true)
                const models: ModelApiItem[] = await fetchApi(URLs.getModels()).then(res => res.json())
                const groupedByModelNumber = models.reduce((accumulator, item) => {
                    if (!accumulator[item.modelNumber]) {
                        accumulator[item.modelNumber] = [];
                    }
                    if (!accumulator[item.modelNumber].some(e => e.modelDescription === item.modelDescription)) {
                        accumulator[item.modelNumber].push(item.modelDescription);
                    }
                    return accumulator;
                }, {});
                dispatch(setModels(groupedByModelNumber))
                dispatch(updateHasDownloadedValue({ key: "models", value: true }))
                dispatch(updateDownloadTriggered({ key: "models", value: false }))
                setIsLoading(false)
                // chain readonlyelatedChecklistDetails download
                dispatch(updateDownloadTriggered({ key: "readonlyelatedChecklistDetails", value: true }))
            }
        })()
    }, [SelectedUser.jwt, triggered])
    return { isLoading }
}
