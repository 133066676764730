import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store/store';
import { FaRegCircleUser } from 'react-icons/fa6';
import { MdEmail, MdSecurity } from 'react-icons/md';
import { ROLES } from '../utils/roles';
import { updateSelectedUserRole } from '../store/SelectedUser';

const ChangeUserRole = () => {
    const SelectedUser = useSelector((state: RootState) => state.SelectedUser.SelectedUser);
    const dispatch = useDispatch();
    const [selectedRole, setSelectedRole] = useState(SelectedUser.role);
    const handleRoleChange = (e) => {
        setSelectedRole(e.target.value);
    };

    const handleSave = () => {
        dispatch(updateSelectedUserRole(selectedRole));
    };

    return (
        <div>
            <div className="absolute p-2">
                <span className="p-2 font-bold">User Details:</span>
                <hr className="border-t p-2" />
                <div className="flex mx-auto p-2 items-center">
                    <FaRegCircleUser size={18} className="mr-4" />
                    <p className='text-base font-bold'>Full Name:</p>
                    <p className='text-base ml-2'>{SelectedUser.fullName}</p>
                </div>
                <div className="flex mx-auto p-2 items-center">
                    <MdEmail size={18} className="mr-4" />
                    <p className='text-base font-bold'>Email:</p>
                    <p className='text-base ml-2'>{SelectedUser.kionEmail}</p>
                </div>
                <div className="flex mx-auto p-2 items-center">
                    <MdSecurity size={18} className="mr-4" />
                    <p className='text-base font-bold'>Access Level:</p>
                    <select
                        className="p-2 border rounded ml-4"
                        value={selectedRole}
                        onChange={handleRoleChange}
                    >
                        {Object.values(ROLES).map((role, index) => (
                            <option key={index} value={role}>{role}</option>
                        ))}
                    </select>
                </div>
                <div style={{ textAlign: "left" }}>
                    <button onClick={handleSave} className='save-btn mt-3'>SAVE</button>
                </div>
            </div>
        </div>
    );
}

export default ChangeUserRole;