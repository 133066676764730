import { UUID } from "crypto";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { CookiesKeys, URLs } from "../../Constants/Consts";
import { updateCheckListItem } from "../../store/SequenceObject";
import { RootState } from "../../store/store";
import { DDCheckList } from "../../types/DataDD/DataDD";
import { ObjectToSync } from "../../types/types";
import { SyncItemsTypes } from "./SyncManager";
import { getCompletePath } from "../../Helpers/Functions";

export default function (): { getChecklistItemsToSync: () => ObjectToSync[], updateSyncItem: (itemId: UUID) => void } {
    const { ChecklistsObject } = useSelector((state: RootState) => state.SequenceObject)
    const { account } = useSelector((state: RootState) => state.SelectedUser)
    const dispatch = useDispatch()
    // traverse into the object to get all the notSync items 
    const getChecklistItemsToSync = () => {
        const checkList = findSyncSequences(ChecklistsObject)
        const jwt = Cookies.get(CookiesKeys.jwt)
        return checkList.map(d => {
            const method = d.creation ? "POST" : "PATCH"
            const url = d.creation ? URLs.postDD() : URLs.updateDD(d.ctUuid)
            const payload = d.creation ? d : { statusId: d.statusId }
            return {
                url,
                payload,
                method,
                type: SyncItemsTypes.CheckListItem,
                jwt,
                account
            }
        })
    }
    const updateSyncItem = (itemId) => {
        const checkList = findSyncSequences(ChecklistsObject)       
        const checklistItem = checkList.find(c => c.ctUuid.toLocaleLowerCase() === itemId.toLocaleLowerCase())
        if (checklistItem) {
            console.log("complete", getCompletePath(checklistItem));            
            dispatch(updateCheckListItem({
                completePath: getCompletePath(checklistItem),
                checklistItem: { ...checklistItem, notSync: false, creation: false }
            }))
        }
    }
    return { getChecklistItemsToSync, updateSyncItem }
}

export function findSyncSequences(obj) {
    let result = new Array<DDCheckList>;
    for (let prop in obj) {
        if (typeof obj[prop] === 'object' && obj[prop] !== null) {
            result = result.concat(findSyncSequences(obj[prop]));
        } else if (prop === 'notSync' && (obj[prop] === true)) {
            result.push(obj);
        }
    }
    return result;
}