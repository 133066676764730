import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState:
    {
        dd1Options: string[]

    } = {
        dd1Options: []
}

const Units = createSlice({
    name: 'dd1options',
    initialState,
    reducers: {
        addDD1Options: (state, action: PayloadAction<string[]>) => {
            state.dd1Options = action.payload
        },
    },
});

export const { addDD1Options } = Units.actions;

export default Units.reducer;
