import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import List from '../../components/CoreComponents/List';
import { DrilldownMappingMeaning, getDrilldownType } from '../../Constants/Consts';
import { getByPath } from '../../Helpers/Functions';
import { RootState } from '../../store/store';
import { setBackClickHandler, setTaskDisplayedPath } from '../../store/Utilities';
import Breadcrumb from '../../components/CoreComponents/Breadcrumb';
import { RiArrowRightSLine } from 'react-icons/ri';

export default function TasksDD() {
    const { ChecklistsObject } = useSelector((state: RootState) => state.SequenceObject);
    const { SelectedUser } = useSelector((state: RootState) => state.SelectedUser);
    const { taskDisplayedPath } = useSelector((state: RootState) => state.Utilities);
    const [displayedObject, setDisplayedObject] = useState({})
    const drilldownType = getDrilldownType(SelectedUser.disciplineId)
    const DrilldownMappingObject = DrilldownMappingMeaning[drilldownType]
    console.log("displayedPath", taskDisplayedPath, DrilldownMappingObject);

    const isPathToShowTasks = (path) => path.split('.').length === DrilldownMappingObject.pathStepsToShowTasks
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        if (isPathToShowTasks(taskDisplayedPath)) {
            const f = encodeURIComponent(taskDisplayedPath)
            navigate(`/CheckListDD/${f}`)
        }
        const obj = getByPath(taskDisplayedPath, ChecklistsObject)
        setDisplayedObject(obj)
    }, [taskDisplayedPath])
    useEffect(() => {
        dispatch(setBackClickHandler(() => {
            const pathArray = taskDisplayedPath.split('.')
            if (!pathArray.length || !pathArray.some(s => !!s))
                navigate(-1)
            pathArray.pop()
            const previousPath = pathArray.join('.')
            dispatch(setTaskDisplayedPath(previousPath))
        }))
        return () => {
            dispatch(setBackClickHandler(null))
        }
    }, [taskDisplayedPath])

    const breadcrumbTrail = [
        { label: SelectedUser.projectNumber },
        { label: ' - ' },
        { label: SelectedUser.project },
        { label: <RiArrowRightSLine />, icon: true },
        { label: SelectedUser.discipline },
        { label: <RiArrowRightSLine />, icon: true },
        { label: DrilldownMappingObject.dd01 },
    ];

    return (
        <div className="ml-2">
            <Breadcrumb trail={breadcrumbTrail} showIndices={[2, 3, 4, 5, 6]} />
            <List<string>
                data={Object.keys(displayedObject)}
                renderChildItem={(item) => (props: {}) =>
                    <div {...props}>{item}</div>}
                onClick={(selectedKey) => {
                    const nextPath = taskDisplayedPath ? taskDisplayedPath + '.' + selectedKey : selectedKey
                    // if we reached the units checklist onjects 
                    if (isPathToShowTasks(nextPath)) {
                        const f = encodeURIComponent(nextPath)
                        navigate(`/CheckListDD/${f}`)
                        return
                    }
                    console.log("MMMM");
                    dispatch(setTaskDisplayedPath(nextPath))
                    const obj = getByPath(nextPath, ChecklistsObject)
                    if (obj) setDisplayedObject(obj)
                }}
                isItemDisabled={false}
            />
        </div>
    )
}
