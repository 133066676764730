import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useBlocker, useParams } from 'react-router-dom'
import { setModal } from "../../../store/Utilities.ts"
import Details from './Details/Details'
import Photos from "./Photos/Photos.tsx"
import SiteMap from './SiteMap/SiteMap'
import { resetPuncList } from '../../../store/PunchList.ts'

const Components = {
    1: Details,
    2: SiteMap,
    3: Photos
}

export default function Form() {
    const { type, id } = useParams()
    const [step, setstep] = useState(1)
    const stepsCount = Object.keys(Components).length;
    const nextStep = () => setstep(step => {
        if (step < stepsCount) {
            return step + 1
        }
        return step
    })
    const previousStep = () => setstep(step => {
        if (step > 1) {
            return step - 1
        }
        return step
    })
    const StepComponent = Components[step]
    const dispatch = useDispatch()
    const nextLocationRef = useRef("")
    const blocker = useBlocker(({ nextLocation }) => {
        nextLocationRef.current = nextLocation.pathname
        if (type === "CheckList" && !nextLocation.pathname.includes('/PunchList/DetailsPage/')) {
            return true
        }
        return false
    })

    useEffect(() => {
        if (blocker.state === "blocked" && type === "CheckList" && !nextLocationRef.current.includes('/PunchList/DetailsPage/')) {
            dispatch(setModal({
                Chilren: () =>
                    <div>
                        Are you sure you want to discard this punch list?<br />
                    </div>
                ,
                onConfirm: () => {
                    blocker.proceed?.()
                    nextLocationRef.current = ""
                },
                onReject() {
                    blocker.reset?.()
                },
                open: true
            }))
        }
    }, [blocker])

    useEffect(() => {
        return () => {
            dispatch(resetPuncList())
        }
    }, [])


    return (
        <div>
            <StepComponent type={type} id={id} next={nextStep} previous={previousStep} />
        </div>
    )
}
