import Select, { components } from "react-select";
import { Controller } from "react-hook-form";

type Option = {
    label: string;
    value: string | number;
};

type FormMultiSelectProps = {
    defaultValue: any;
    errors: Record<string, any>;
    inputKey: string;
    label: string;
    control: any;
    name: string;
    options: Option[];
    required?: boolean;
    onChangeEvent?: (selectedOptions: any) => void;
};

const CheckboxOption = (props) => {
    return (
        <components.Option {...props}>
            <input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
            <label>{props.label}</label>
        </components.Option>
    );
};


export default function FormMultiSelect({
    defaultValue, 
    errors, 
    inputKey, 
    label, 
    control, 
    name, 
    options, 
    required = false,
    onChangeEvent,
}: FormMultiSelectProps) {
    return (
        <div className='mb-2 md:mb-8'>
            <div className='flex flex-col md:flex-row items-start md:items-center'>
                <label className='mb-1 text-base text-grey-darkest flex md:w-1/3' htmlFor={inputKey}>
                    {label}
                    {required && <span className='text-red-500 px-2 text-base' aria-hidden="true">*</span>}
                </label>
                <div className="w-full ml-auto md:w-[400px]">
                    <Controller
                        name={name}
                        control={control}
                        defaultValue={defaultValue}
                        render={({ field }) => {
                            const { onChange, value, ...rest } = field;
                            return (
                                <Select
                                    {...rest}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    components={{ Option: CheckboxOption }}
                                    className="w-full"
                                    classNamePrefix="react-select"
                                    value={value}
                                    onChange={(selectedOptions) => {
                                        if (onChangeEvent) {
                                            onChangeEvent(selectedOptions);
                                        }
                                        onChange(selectedOptions);
                                    }}
                                    options={options}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#fdb419', // Inside select color
                                            primary: '#fdb419', // Inside select color
                                            neutral0: 'var(--select-background)', // Background color
                                            neutral80: 'black', // Text color
                                            neutral50: 'gray', // Placeholder text color
                                            neutral20: 'gray', // Border color
                                        },
                                    })}
                                />
                            );
                        }}
                    />
                </div>
            </div>
            {errors[inputKey] && <p className="text-red-500 text-xs text-right">{errors[inputKey]?.message}</p>}
        </div>
    );
}