import { useMsal } from "@azure/msal-react";
import Cookies from "js-cookie";
import { CookiesKeys } from "../../Constants/Consts";
import { persistor } from "../../store/store";

const LogoutButton = () => {
    const { instance } = useMsal();

    const handleLogout = () => {
        persistor.purge().then(() => {
            instance.logoutRedirect().catch(e => {
                console.error(e);
            });
        })
        Cookies.set(CookiesKeys.loginPlatform, null);
    };

    return (
        <button onClick={handleLogout}>Logout</button>
    );
};

export default LogoutButton;

