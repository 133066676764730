import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UserAssignment } from '../types/types';

const initialState:
    {
        Users: UserAssignment[],
    } = {
    Users: [],
}

const Units = createSlice({
    name: 'user',
    initialState,
    reducers: {
        addUsers: (state, action: PayloadAction<UserAssignment[]>) => {
            // todo: check notSync items
            state.Users = action.payload
        }
    }
});

export const { addUsers } = Units.actions;

export default Units.reducer;
