import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    AdHocItem,
    AdHocUnitItem,
    ProjectAdHocItem,
    ProjectAdHocUnitItem,
} from '../types/Unit/AdHocUnitSchema.ts';

interface AdHocUnitState {
    ProjectAdHocUnitItem: ProjectAdHocUnitItem;
    ProjectAdHocItem: ProjectAdHocItem;
}

const initialState: AdHocUnitState = {
    ProjectAdHocUnitItem: {},
    ProjectAdHocItem: {},
};


const AdHocUnitSlice = createSlice({
    name: 'adHocUnit',
    initialState,
    reducers: {
        addProjectAdHocUnitItem: (state, action: PayloadAction<{
            projectNumber: number,
            adHocUnitItems: AdHocUnitItem[]
        }>) => {
            // todo: check notSync items 
            state.ProjectAdHocUnitItem[action.payload.projectNumber] = action.payload.adHocUnitItems
        },
        addProjectAdHocItem: (state, action: PayloadAction<{
            projectNumber: number,
            adHocItems: AdHocItem[]
        }>) => {
            // todo: check notSync items 
            state.ProjectAdHocItem[action.payload.projectNumber] = action.payload.adHocItems
        },
    },
});
export const {
    addProjectAdHocUnitItem,
    addProjectAdHocItem
} = AdHocUnitSlice.actions;
export default AdHocUnitSlice.reducer;
