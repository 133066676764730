import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import useOnline from '../../../Helpers/CustomHooks/userOnlineCheck';
import { RootState } from '../../../store/store';

import { MdOutlinePostAdd } from "react-icons/md";
import { GoChecklist } from "react-icons/go";
import { GrDownload } from "react-icons/gr";
import { twMerge } from 'tailwind-merge';
import Loader from '../../../components/CoreComponents/Loader';
import useDownloadPunchListDisciplineImages from '../../../components/DownloadManager/useDownloadPunchListDisciplineImages2';
import { updateDisplinesImagesTriggered } from '../../../store/Utilities';

export default function Options() {
    const { state: { disciplinId } } = useLocation();
    const { allDisciplines: disciplines } = useSelector((state: RootState) => state.Discipline);
    const selectedDiscipline = disciplines.find(d => String(d.id) == disciplinId);
    const navigate = useNavigate();
    const isUserOnline = useOnline();
    const dispatch = useDispatch()
    const { disciplinePunchlistsImageDownloaded } = useSelector((state: RootState) => state.Utilities)
    const isImagesDownloaded = disciplinePunchlistsImageDownloaded[selectedDiscipline?.disciplineCode]
    const { isPhotosLoading } = useDownloadPunchListDisciplineImages(selectedDiscipline?.id)
    return (
        <>
            {isPhotosLoading ? <Loader loaders={{ isPunchlistImagesLoading: isPhotosLoading }} />
                : <div className="mt-4 max-w-4xl">
                    <hr className="border-t mt-2 mb-2" />
                    <div className='flex items-center hover:bg-gray-100 hover:cursor-pointer'
                        onClick={() => { navigate(`/PunchList/Form/Discipline/${selectedDiscipline.id}`) }}>
                        <MdOutlinePostAdd className='w-10 h-10 text-orange-1000' />
                        <div className="ml-5 cursor-pointer text-sm" >
                            Add New Punch List Issue
                        </div>
                    </div>
                    <hr className="border-t mt-2 mb-2" />
                    <div className='flex items-center hover:bg-gray-100 hover:cursor-pointer' onClick={() => { navigate(`/PunchListDiscipline/${disciplinId}/view`) }}>
                        <GoChecklist className='w-10 h-10 text-orange-1000' />
                        <div className="ml-5 cursor-pointer text-sm" >
                            View All {selectedDiscipline.disciplineName} Punch Lists
                        </div>
                    </div>
                    <hr className="border-t mt-2 mb-2" />

                    <div className='flex items-center hover:bg-gray-100 hover:cursor-pointer'
                        onClick={() => {
                            if (!isImagesDownloaded && isUserOnline)
                                dispatch(updateDisplinesImagesTriggered({ disciplineCode: selectedDiscipline.disciplineCode, value: true }))
                        }}>
                        <GrDownload className={twMerge(`w-10 h-10 ${!isImagesDownloaded && isUserOnline ? "text-orange-1000" : " text-gray-100 dark:text-gray-800"} `)} />
                        <div className={twMerge(`ml-5 cursor-pointer text-sm ${!isImagesDownloaded && isUserOnline ? "" : "text-gray-300 dark:text-gray-800 line-through"}`)} >Download All {selectedDiscipline.disciplineName} Images</div>
                    </div>
                </div>}
        </>
    )
}
