import { useMemo, useState } from "react";
import { useSortBy, useTable } from 'react-table';
import { Priorities } from "../../../../../Constants/Consts";
import { PunchlistApiType } from "../../../../../types/PunchList/PunchListSchema";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";

export const useTableData = (punchlists: PunchlistApiType[]) => {
    const { Categories } = useSelector((state: RootState) => state.Categories)
    const { Issues } = useSelector((state: RootState) => state.Issues)

    const data = punchlists.map(p => {
        const dueDateParts = p.dueDate ? p.dueDate?.split('-').map(part => parseInt(part, 10)) : []
        if (dueDateParts.length === 3) {
            const [year, month, day] = dueDateParts;
            const dueDate = new Date(year, month - 1, day);

            return {
                ...p,
                DueDate: dueDate.toLocaleDateString(),
                DateIdentified: (new Date(p.dateIdentified)).toLocaleDateString(),
                mpctag: p.punchListUuid?.slice(0, 8),
                priority: Object.entries(Priorities).find(([_, v]) => v == p.priority)?.[0],
                category: Categories.find(c => c.categoryId == p.categoryV1Id)?.categoryDescription,
                issue: Issues.find(issue => issue.issueId == p.issueId)?.issueDescription
            };
        }
        return p;
    });

    const [search, setSearch] = useState('')
    const filtered = data.filter(d => JSON.stringify(d).toLocaleLowerCase().match(search.toLocaleLowerCase()))
    const columns = useMemo(() => [
        {
            Header: "Sequence / CA",
            accessor: 'adhocSequenceCa',
        },
        {
            Header: "Unit / Device",
            accessor: 'adhocUnitDevice',
        },
        {
            Header: "Category",
            accessor: 'category',
        },
        {
            Header: "Issue",
            accessor: 'issue',
        },
        {
            Header: "Drawing #",
            accessor: 'drawingNumber',
            sortable: true
        },
        {
            Header: "Identified By",
            accessor: 'identifiedBy',
        },
        {
            Header: "Priority",
            accessor: 'priority',
        },
        {
            Header: "Due Date",
            accessor: 'DueDate',
        },
        {
            Header: "Date Identified",
            accessor: 'DateIdentified',
        },
        {
            Header: "Punch List Tag",
            accessor: 'mpctag',
        },
        // {
        //     Header: "Sequence #",
        //     accessor: '',
        // },
        // {
        //     Header: "Unit #",
        //     accessor: '',
        // },
    ], []);

    return {
        ...useTable({
            columns, data: filtered,
            autoResetFilters: false,
            autoResetSortBy: false,
        }, useSortBy), search, setSearch
    }
}
