import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootCause } from '../types/types';

const initialState:
    {
        RootCauses: RootCause[]

    } = {
    RootCauses: []
}

const RootCauses = createSlice({
    name: 'rootCauses',
    initialState,
    reducers: {
        addRootCauses: (state, action: PayloadAction<RootCause[]>) => {
            state.RootCauses = action.payload
        },
    },
});

export const { addRootCauses } = RootCauses.actions;

export default RootCauses.reducer;
