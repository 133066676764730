import * as z from 'zod';

export const detailsSchema = z.object({
    OriginalDiscipline: z.number(),
    AssignedDiscipline: z.number(),
    ProjectNumber: z.number(),
    Category: z.number(),
    Issue: z.number(),
    DrawingHash: z.string().nullable().refine((value) => value !== null && value.trim().length > 0, {
        message: "Required",
    }),
    SequenceControlArea: z.string().optional(),
    UnitDevice: z.string().optional(),
    IssueDescription: z.string().nullable().refine((value) => value !== null && value.trim().length > 0, {
        message: "Required",
    }),
    DefectPic1: z.string().optional(),
    DefectPic2: z.string().optional(),
    DueDate: z.string().nonempty({ message: "Date Is Required" }),
    Priority: z.number(),
    DoesPunchListRequireReinspection: z.boolean(),
    ErmRelevant: z.boolean(),
    ErmType: z.string().optional(),
    punchListId: z.string().optional(),
    ChecklistUuid: z.string().optional(),
    InspectionTask: z.string().optional(),
}).refine(data => {
    if (data.ErmRelevant) {
        return data.ErmType !== '';
    }
    return true;
}, {
    message: "ERM type must be selected",
    path: ["ErmType"]
});

export type PunchListDetailsType = z.infer<typeof detailsSchema>;

export const siteMapSchema = z.object({
    DrawingId: z.string().optional(),
    Location: z.array(z.number()).optional(),
}).refine(data => {
    if (data.DrawingId) {
        return data.Location && data.Location.length === 2;
    }
    return true;
}, {
    message: "Location is required when a drawing is selected",
    path: ["Location"]
});

export type PunchListSiteMapType = z.infer<typeof siteMapSchema>;

export type PunchList = PunchListDetailsType & PunchListSiteMapType & {
    defectPic1?: string,
    storedDefectPic1?: {
        value: string,
        notSync?: boolean,
        creation?: boolean,
    },
    defectPic2?: string,
    storedDefectPic2?: {
        value: string,
        notSync?: boolean,
        creation?: boolean,
    },
    correctedPic1?: string,
    storedCorrectedPic1?: {
        value: string,
        notSync?: boolean,
        creation?: boolean,
    },
    correctedPic2?: string,
    storedCorrectedPic2?: {
        value: string,
        notSync?: boolean,
        creation?: boolean,
    },
    SequenceControlArea?: string,
    UnitDevice?: string,
    ChecklistUuid?: string,
};

export type PunchlistApiType = {
    "punchListId": string,
    "projectNumber"?: string,
    "disciplineId"?: string,
    "assignedDisciplineId"?: number,
    "unitChecklistId": null,
    "checklistUuid": string | null,
    "status": number,
    "materialId": null,
    "issueId": null,
    "issueDesc": null,
    "priority": number,
    "dueDate": string,
    "rfrRequired": number,
    "drawingNumber": string | null,
    "isSiteProjectPunchList": string,
    "drawingId": number | null,
    "locationPin": string | null,
    defectPic1: string,
    "storedDefectPic1"?: {
        value: string,
        notSync?: boolean,
        creation?: boolean,
    },
    defectPic2: string,
    "storedDefectPic2"?: {
        value: string,
        notSync?: boolean,
        creation?: boolean,
    },
    correctedPic1: string,
    "storedCorrectedPic1"?: {
        value: string,
        notSync?: boolean,
        creation?: boolean,
    },
    correctedPic2: string,
    "storedCorrectedPic2"?: {
        value: string,
        notSync?: boolean,
        creation?: boolean,
    },
    "isDuplicate": string,
    "adhocSequenceCa": null,
    "adhocUnitDevice": null,
    "identifiedBy": string,
    "dateIdentified": string,
    "correctedBy": string,
    "dateCompleted": string,
    "isArchived": number,
    "ermRelevant": number | null,
    "ermType": string | null,
    notSync?: boolean,
    creation?: boolean
}

export const punchlistDefault: PunchlistApiType = {
    "punchListId": "",
    "projectNumber": "",
    "disciplineId": "",
    "assignedDisciplineId": null,
    "unitChecklistId": null,
    "checklistUuid": null,
    "status": 0,
    "materialId": null,
    "issueId": null,
    "issueDesc": null,
    "priority": 0,
    "dueDate": "",
    "rfrRequired": 0,
    "drawingNumber": null,
    "isSiteProjectPunchList": null,
    "drawingId": 0,
    "locationPin": "",
    "defectPic1": null,
    "defectPic2": null,
    "correctedPic1": null,
    "correctedPic2": null,
    "storedDefectPic1": null,
    "storedDefectPic2": null,
    "storedCorrectedPic1": null,
    "storedCorrectedPic2": null,
    "isDuplicate": null,
    "adhocSequenceCa": null,
    "adhocUnitDevice": null,
    "ermRelevant": null,
    "ermType": null,
    "identifiedBy": "",
    "dateIdentified": "",
    "correctedBy": null,
    "dateCompleted": null,
    "isArchived": 0
}
