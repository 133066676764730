import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../../store/store';
import DetailsHeader from "../../Details/SectionHeader";
import { useState } from 'react';
import EditComment from './EditComment';

const viewKeys = {
    comment1: "Comment",
    addedBy: "Added By",
    addedOn: "Added On",
    addedEmail: "Email"
}

export default function ListComment() {
    const { commentUuid } = useParams()
    const { Comments } = useSelector((state: RootState) => state.Comments)
    const filteredComment = Comments.find(c => c.commentUuid === commentUuid)
    const [showEditComment, setshowEditComment] = useState(false)
    const { SelectedUser } = useSelector((state: RootState) => state.SelectedUser);
    const isCreator = SelectedUser.kionEmail === filteredComment?.addedEmail;
    const cancelEdit = () => {
        setshowEditComment(false);
    }

    return (
        <div className="mt-8">

            <div className="mt-4 mb-8 flex gap-2">
                <DetailsHeader title="comment details" />
            </div>

            {Object.keys(filteredComment ?? {}).map(k => {
                if (!Object.keys(viewKeys).includes(k)) return
                if (k === "addedOn") {
                    return (
                        <div key={k}>
                            <span className='text-lg font-bold'>
                                {viewKeys[k]}</span>
                            {" : "}
                            {new Date(String(filteredComment[k])).toLocaleString()}
                        </div>
                    )
                }
                return (<div key={k}>
                    <span className='text-lg font-bold'>
                        {viewKeys[k]}</span>
                    {" : "}
                    {filteredComment[k]}
                </div>)
            })}

            <div className="mt-4 mb-8 flex gap-2">
                <div className="">
                    {isCreator && (
                        showEditComment ? (
                            <EditComment 
                                commentToEdit={filteredComment} 
                                cancelEdit={cancelEdit} 
                            />
                        ) : (
                            <button 
                                className='p-1 px-4 bg-[#fdb419] text-white hover:bg-[#aaa6a5]' 
                                onClick={() => setshowEditComment(true)} 
                                title="Edit"
                            >
                                Edit
                            </button>
                        )
                    )}
                </div>
            </div>

        </div>
    )
}
