import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActiveStatusNumber, photosKeys, Status, URLs as URLs } from "../../Constants/Consts";
import { buildChunks, fetchApi } from "../../Helpers/Functions";
import { addProjectPunchLists } from "../../store/PunchList";
import { updateDownloadTriggered, updateHasDownloadedValue, updatePendingPunchListCount } from "../../store/Utilities";
import { RootState } from "../../store/store";
import { PunchlistApiType } from "../../types/PunchList/PunchListSchema";

export default function storeDisciplinePunchList() {
  // load all punchlists
  const [isloading, setIsloading] = useState(false)
  const [isPLsDownloaded, setIsPLsDownloaded] = useState(false)
  const [isPhotosLoading, setIsPhotosloading] = useState(false)
  const dispatch = useDispatch()
  const { SelectedUser } = useSelector((state: RootState) => state.SelectedUser)
  const arrKeys = Object.keys(photosKeys)
  const { downloadTriggered: { punchList: triggered, punchListImages: imagesTriggered } } = useSelector(
    (state: RootState) => state.Utilities
  );


  const filesWorker = useMemo(() => new Worker(new URL('../SyncManager/webWorker.ts', import.meta.url)), [])
  useEffect(() => {
    if (window.Worker) {
      // Listen for messages from the web worker
      filesWorker.onmessage = (_) => {
        console.log('Received message from worker:', _.data);
      };
    }
  }, [])

  const calledOnceRef = useRef(false)
  const controllerRef = useRef<AbortController>()
  useEffect(() => {
    (async () => {
      if (navigator.onLine && SelectedUser.projectNumber && SelectedUser.jwt && (triggered)) {
        calledOnceRef.current = true
        if (controllerRef.current) {
          controllerRef.current?.abort?.()
        }
        controllerRef.current = new AbortController()
        setIsloading(true)
        const pData = await fetchApi(
          URLs.getPunchListsPerProjectUrl(SelectedUser.projectNumber), { signal: controllerRef.current.signal })
          .then(res => res.json()) as PunchlistApiType[]
        setIsloading(false)
        if (Worker) filesWorker.postMessage({ action: 'removeimagesFolder' });
        dispatch(addProjectPunchLists(pData))
        const pendingPunchListCount = pData.filter(punchList => Status.Pending == punchList.status || Status.ReadyForReInspection == punchList.status)?.length
        const formatPendingPunchlistCount = pendingPunchListCount >= 100 ? '99+' : pendingPunchListCount;
        dispatch(updatePendingPunchListCount(formatPendingPunchlistCount))
        setIsPLsDownloaded(true)
        dispatch(updateHasDownloadedValue({ key: "punchList", value: true }))
        dispatch(updateDownloadTriggered({ key: "punchList", value: false }))
        // await yieldToMain();
        // chain photos
        dispatch(updateDownloadTriggered({ key: "punchListImages", value: true }))
      }
    })()
  }, [SelectedUser.jwt, SelectedUser.projectNumber, triggered])


  const imagesCalledOnceRef = useRef(false)
  const imagesControllerRef = useRef<AbortController>()
  const { ProjectPunchLists } = useSelector((state: RootState) => state.PunchList)
  useEffect(() => {
    (async () => {
      if (navigator.onLine && SelectedUser.jwt && imagesTriggered) {
        dispatch(updateDownloadTriggered({ key: "punchListImages", value: false }))
        imagesCalledOnceRef.current = true
        if (imagesControllerRef.current) {
          imagesControllerRef.current?.abort?.()
        }
        imagesControllerRef.current = new AbortController()
        // filter by assigned discipline and active statuses
        const selectedDisciplinepunchlists = ProjectPunchLists.filter(pl => Number(pl.assignedDisciplineId) == SelectedUser.disciplineId
          && !!ActiveStatusNumber[pl.status])
        if (selectedDisciplinepunchlists.length === 0) return
        setIsPhotosloading(_ => true)
        // these photos are for assigned discipline only
        const photosUrls = selectedDisciplinepunchlists
          .reduce<{ grouped: Array<string>, couter: string[] }[]>((acc, pl) => {
            let couter = []
            let grouped = []
            if (pl[arrKeys[0]]) {
              couter.push(photosKeys[arrKeys[0]])
              grouped.push(pl[arrKeys[0]])
            }
            if (pl[arrKeys[1]]) {
              couter.push(photosKeys[arrKeys[1]])
              grouped.push(pl[arrKeys[1]])
            }
            if (pl[arrKeys[2]]) {
              couter.push(photosKeys[arrKeys[2]])
              grouped.push(pl[arrKeys[2]])
            }
            if (pl[arrKeys[3]]) {
              couter.push(photosKeys[arrKeys[3]])
              grouped.push(pl[arrKeys[3]])
            }
            acc.push({ grouped, couter })
            return acc
          }, [])

        // fetch all photos 
        const photosToFetch = photosUrls.reduce((acc, f) => {
          if (f.couter.length) acc.push(f.grouped)
          return acc.flat()
        }, [])

        if (photosToFetch.length === 0) {
          setIsPhotosloading(_ => false)
          return
        }
        const urlChunks = buildChunks(photosToFetch, 20)
        let counter = 0
        for (let k = 0; k < urlChunks.length; k++) {
          const urlChunk = urlChunks[k];
          await Promise.allSettled(urlChunk.map(p => {
            const pUrl = p.replace('punch_list_images/', '');
            return fetchApi(URLs.getFilePunchListImage(encodeURIComponent(pUrl)), { signal: controllerRef.current.signal })
              .then(async response => {
                if (Worker) filesWorker.postMessage({ action: 'storeFile', fileName: `${pUrl.split('/')?.[3]}`, file: await response.arrayBuffer() });
                counter++
              })
              .catch(reason => {
                counter++
                return {
                  status: 'rejected',
                  reason
                }
              }).finally(() => {
                if (counter === photosToFetch.length) { setIsPhotosloading(_ => false) }
              })
          }))
        }

        dispatch(updateHasDownloadedValue({ key: "punchListImages", value: true }))
      }
    })()
  }, [SelectedUser.jwt, triggered, isPLsDownloaded])

  return { isloading, isPhotosLoading }
}

