import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DetailGrid from "../../components/CoreComponents/Details/DetailsGrid.tsx";
import SectionHeader from "../../components/CoreComponents/Details/SectionHeader.tsx";
import { getDrilldownType } from "../../Constants/Consts.ts";
import { RootState } from "../../store/store.ts";
import { AdHocItem } from "../../types/Unit/AdHocUnitSchema.ts";


export default function AdHocUnitDetails() {
    const { ProjectNumber, AdhocUuid } = useParams();
    const navigate = useNavigate();
    const { DrilldownMappingsAPIResponse } = useSelector((state: RootState) => state.SequenceObject);
    const { SelectedUser } = useSelector((state: RootState) => state.SelectedUser)
    const drilldownType = getDrilldownType(SelectedUser.disciplineId)
    const DrilldownMappingObject = DrilldownMappingsAPIResponse.find(d => d.drilldownTypeId === drilldownType)

    const filteredAdHocUnit: AdHocItem = useSelector((state: RootState) => {
        return state.AdHocUnit.ProjectAdHocItem[ProjectNumber].find((item) => item.adhocUuid === AdhocUuid);
    });

    // const discipline = useSelector((state: RootState) => {
    //     return state.Discipline.allDisciplines.find(d => d.id === filteredAdHocUnit.disciplineId)
    // })

    const generalDetails = useMemo(() => {
        const details = [
            { label: "Project Number", value: filteredAdHocUnit.projectNumber.toString() },
            // { label: "Discipline", value: discipline?.disciplineName },
            { label: DrilldownMappingObject.dd01, value: filteredAdHocUnit.dd01?.toString() },
            { label: DrilldownMappingObject.dd02, value: filteredAdHocUnit.dd02?.toString() },
            { label: DrilldownMappingObject.ap01, value: filteredAdHocUnit.ap01?.toString() },
            { label: "Model Full", value: filteredAdHocUnit.bp01 },
            {
                label: "Requested On",
                value: filteredAdHocUnit.datetimeCreated ? new Date(filteredAdHocUnit.datetimeCreated).toISOString() : ''
            },
            { label: "Requested By", value: filteredAdHocUnit.requestedBy },
            { label: "Requestor Email", value: filteredAdHocUnit.requestedEmail },
            {
                label: "Completed On",
                value: filteredAdHocUnit.datetimeCompleted ? new Date(filteredAdHocUnit.datetimeCompleted).toISOString() : ''
            },
            { label: "Completed Message", value: filteredAdHocUnit.completedMessage },
        ];
        return details.filter(detail => detail.value != null);
    }, [filteredAdHocUnit, DrilldownMappingObject]);

    return (
        <div className={'max-w-screen-md'}>
            <div className="felx items-center mt-8 mb-8">
                <SectionHeader title="Adhoc Details Page" />
            </div>

            <DetailGrid details={generalDetails} />

            <button className='relative bg-gray-300 mt-3 left-0' onClick={() => navigate(-1)}>Back</button>
        </div>
    );
}
