import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { findSyncSequences } from '../components/SyncManager/CheckListSync';
import { pathDelmiter } from '../Constants/Consts';
import { getByPath, getDrilldownStepsNumber, getTaskNodeAtSpecificLevelFromElement } from '../Helpers/Functions';
import { DDCheckList, DrilldownMappingAPIResponse, TaskNode } from '../types/DataDD/DataDD';
import { MPCStatus } from '../types/types';

export const taskNodeDefaults: TaskNode = { type: "root", childs: {}, index: 0, length: 0, name: "", taskChilds: null, value: "" }

const initialState:
  {
    ChecklistsObject: TaskNode,
    selectedDD02: string,
    selectedDD01: string,
    selectedPath: string,
    selectedInspectionTask: string | null,
    MPCStatus?: Array<MPCStatus>,
    DrilldownMappingsAPIResponse: Array<DrilldownMappingAPIResponse>
  } = {
  DrilldownMappingsAPIResponse: [],
  ChecklistsObject: taskNodeDefaults,
  selectedDD01: '',
  selectedDD02: '',
  selectedPath: '',
  selectedInspectionTask: null,
  MPCStatus: []
}

function overridePayloadStatus(object, items: Array<DDCheckList>) {
  if (items.length === 0) return
  for (let prop in object) {
    if (typeof object[prop] === 'object' && object[prop] !== null) {
      overridePayloadStatus(object[prop], items)
    } else if (prop === 'ctUuid' && object[prop] !== null) {
      const toSyncItem = items.find(c => c.ctUuid === object[prop])
      if (toSyncItem) {
        object.statusId = toSyncItem.statusId
        object["notSync"] = toSyncItem.notSync
      }
    }
  }
}

const SequencesSlice = createSlice({
  name: 'sequences',
  initialState,
  reducers: {
    addChecklists: (state, action: PayloadAction<TaskNode>) => {
      const notSyncChecklists = findSyncSequences(state.ChecklistsObject)
      // get the new items out to be added later
      const updated = notSyncChecklists.filter(f => !f.creation)
      // override the notSync items with current ones 
      overridePayloadStatus(action.payload, updated)
      // add the new notSync items to the current ones
      // TODO: generate test case for this one
      notSyncChecklists.forEach(notSyncElement => {
        if (notSyncElement.creation) {
          // if (notSyncElement.drilldownTypeId === 1) {
          // const taskAt2rdLevel = getTaskNodeAtSpecificLevelFromElement(notSyncElement, action.payload, 2)
          // taskAt2rdLevel[notSyncElement.ctUuid] = notSyncElement
          // action.payload.childs[notSyncElement.dd01].childs[notSyncElement.dd02]
          //   .childs[notSyncElement.ctUuid] = notSyncElement;
          // }
          // if (notSyncElement.drilldownTypeId === 4) {
          // const taskAt3rdLevel = getTaskNodeAtSpecificLevelFromElement(notSyncElement, action.payload, 3)
          // taskAt3rdLevel[notSyncElement.ctUuid] = notSyncElement
          // action.payload.childs[notSyncElement.dd01].childs[notSyncElement.dd02]
          //   .childs[notSyncElement.dd03].childs[notSyncElement.ctUuid] = notSyncElement;
          // }
          const levels = getDrilldownStepsNumber(notSyncElement)
          const taskAt2rdLevel = getTaskNodeAtSpecificLevelFromElement(notSyncElement, action.payload, levels)
          taskAt2rdLevel[notSyncElement.ctUuid] = notSyncElement
        }
      });
      // add the new payload
      state.ChecklistsObject = action.payload
    },
    updateCheckListItem: (state, action: PayloadAction<{ completePath: string, checklistItem: any }>) => {
      const pathArray = action.payload.completePath.split(pathDelmiter);
      let current = state.ChecklistsObject;
      for (let i = 0; i < pathArray.length - 1; i++) {
        const key = pathArray[i];
        if (current[key] === undefined) {
          return;
        }
        current[key] = { ...current[key] };
        current = current[key];
      }
      const lastKey = pathArray[pathArray.length - 1];
      current[lastKey] = { ...action.payload.checklistItem };
    },
    setSelectedDD01: (state, action: PayloadAction<string>) => {
      state.selectedDD01 = action.payload ?? ""
    },
    setSelectedDD02: (state, action: PayloadAction<string>) => {
      state.selectedDD02 = action.payload ?? ""
    },
    setSelectedPath: (state, action: PayloadAction<string>) => {
      state.selectedPath = action.payload ?? ""
    },
    setSelectedInspectionTask: (state, action: PayloadAction<string>) => {
      state.selectedInspectionTask = action.payload;
    },
    addMPCStatus: (state, action: PayloadAction<Array<MPCStatus>>) => {
      state.MPCStatus = action.payload
    },
    updateMPCStatus: (state, action: PayloadAction<{ SequenceId: string | number, UnitId: string | number, MPCStatus: Array<MPCStatus> }>) => {
      for (const mpcStatusItem of action.payload.MPCStatus) {
        state.ChecklistsObject[action.payload.SequenceId][action.payload.UnitId][mpcStatusItem.meChecklistUuid].mpcStatus = mpcStatusItem.mpcStatus;
      }
    },
    setDrilldownMappingsAPIResponse: (state, action: PayloadAction<Array<DrilldownMappingAPIResponse>>) => {
      state.DrilldownMappingsAPIResponse = action.payload
    },
    addNewTask: (
      state,
      action: PayloadAction<{ path: string, newTask: DDCheckList }>
    ) => {
      const pathArray = action.payload.path.split(pathDelmiter);
      let current = state.ChecklistsObject;
      for (let i = 0; i < pathArray.length - 1; i++) {
        const key = pathArray[i];
        if (!current[key]) {
          current[key] = {};
        }
        current[key] = { ...current[key] };
        current = current[key];
      }
      const lastKey = pathArray[pathArray.length - 1];
      current[lastKey] = { ...action.payload.newTask };
    },
    updateTaskSyncStatus: (
      state,
      action: PayloadAction<{
        path: string,
        notSync: boolean;
      }>
    ) => {
      const checklistItem = getByPath(action.payload.path, state.ChecklistsObject)
      if (checklistItem) {
        checklistItem.notSync = action.payload.notSync;
      } else {
        console.error('Checklist item not found for sync update.');
      }
    }
  },
});

export const {
  addChecklists,
  updateCheckListItem,
  setSelectedDD01,
  setSelectedDD02,
  setSelectedInspectionTask,
  addMPCStatus,
  updateMPCStatus,
  addNewTask,
  setSelectedPath,
  setDrilldownMappingsAPIResponse
} = SequencesSlice.actions;
export default SequencesSlice.reducer;