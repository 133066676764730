import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

const useCategoryOptions = (AssignedDiscipline: number, Technology: number) => {
    const { LinkDisciplineTecnoCategory } = useSelector((state: RootState) => state.LinkDisciplineTecnCategory);
    const { Categories } = useSelector((state: RootState) => state.Categories);

    return useMemo(() => {
        const relevantLinks = LinkDisciplineTecnoCategory.filter(
            link => link.disciplineId === AssignedDiscipline && link.technologyId === Technology
        );

        const categoryOrderMap = new Map<number, number>();
        
        relevantLinks.forEach(link => categoryOrderMap.set(link.categoryId, link.listOrder));

        const filteredCategories = Categories.filter(category => categoryOrderMap.has(category.categoryId));

        const sortedCategories = filteredCategories.sort((a, b) => {
            const orderA = categoryOrderMap.get(a.categoryId) || 0;
            const orderB = categoryOrderMap.get(b.categoryId) || 0;
            return orderA - orderB;
        });

        const options = sortedCategories.map(category => ({
            text: category.categoryDescription,
            value: category.categoryId,
        }));

        return options;
    }, [AssignedDiscipline, Technology, LinkDisciplineTecnoCategory, Categories]);
};

export default useCategoryOptions;