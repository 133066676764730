import * as z from 'zod';

export const detailsSchema = z.object({
    ProjectNumber: z.number().min(0, "Project must be selected"),
    Discipline: z.number().min(0, "Discipline must be selected"),
    SequenceNumber: z.string().min(1, "Sequence must be provided"),
    UnitNumber: z.string().min(1, "Sequence must be provided"),
    ModelNumber: z.string().min(0, "Model Number must be selected"),
    ModelFull: z.string().nullable().refine((value) => value !== null && value.trim().length > 0, {
        message: "Model Full must be selected",
    }),
});

export type AdHocUnitDetailsType = z.infer<typeof detailsSchema>

export type CreateAdHocUnitItem = {
    projectNumber: number,
    disciplineId: number,
    sequenceNumber: string,
    unitNumber: string,
    modelNumber: string,
    modelFull: string,
    notSync?: boolean
}

export const createAdHocUnitDefault: CreateAdHocUnitItem = {
    disciplineId: 0,
    modelFull: "",
    modelNumber: "",
    projectNumber: 0,
    sequenceNumber: "",
    unitNumber: ""
}

export type ProjectAdHocUnitItem = {
    [projectNumber: number]: AdHocUnitItem[]
}

export type AdHocUnitItem = {
    "adhocId": number,
    "adhocUuid": string
    "completedOn": Date,
    "completedMessage": string,
    "completedStatus": string,
    "disciplineId": number,
    "extraColumn03": number,
    "extraColumn04": number,
    "extraColumn05": number,
    "modelFull": string,
    "modelNumber": string,
    "projectNumber": number,
    "requestedBy": string,
    "requestedEmail": string,
    "requestedOn": Date,
    "sequenceNumber": string,
    "unitNumber": string
}