import useCheckItemDetails from "./useCheckItemDetails";

export default function TaskDetails({ item }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useCheckItemDetails({ item });
  console.log("deatails rows: ", rows);
  return (
    <div className="flex flex-col">
      <table {...getTableProps()} className="text-sm">
        <thead>
          {headerGroups.map(headerGroup => {
            const { key, ...restProps } = headerGroup.getHeaderGroupProps();
            return (
              <tr key={key} {...restProps}>
                {headerGroup.headers.map(column => {
                  const { key, ...restProps } = column.getHeaderProps();
                  return (
                    <th className="px-6" key={key} {...restProps}>
                      {column.render("Header")}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            const { key, ...restProps } = row.getRowProps();
            return (
              <tr key={key} {...restProps}>
                {row.cells.map((cell, index) => {
                  const { key, ...restProps } = cell.getCellProps();
                  const cellClass = index === 0 ? "font-bold" : "px-4 py-4";
                  return (
                    <td className={cellClass} key={key} {...restProps}>
                      <div className="flex capitalize ">{cell.render("Cell")}</div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}