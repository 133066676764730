import App from "./App";
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider, useIsAuthenticated } from "@azure/msal-react";
import { msalConfig, msalConfigCIAM } from "./config/MsalConfig";
import { useEffect, useState } from "react";
import { CookiesKeys, LoginPlatforms } from "./Constants/Consts";
import Cookies from "js-cookie";
import { deleteAllCookies } from "./Helpers/Functions";
import uniteLogo from "/images/unite_logo_no_bg.png";
import uniteDarkLogo from "/images/unite_logo_no_bg_dark.png";

function Security() {
    const [willAuth, setWillAuth] = useState("");
    const [publicClientApplication, setPublicClientApplication] = useState(null);
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        async function fillMSALConfig() {
            const loginPlatform = Cookies.get(CookiesKeys.loginPlatform);
            if (loginPlatform === LoginPlatforms.azure) {
                setPublicClientApplication(new PublicClientApplication(msalConfig));
            } else if (loginPlatform === LoginPlatforms.ciam) {
                setPublicClientApplication(new PublicClientApplication(msalConfigCIAM));
            } else {
                setPublicClientApplication(null);
            }
        }
        fillMSALConfig();
    }, []);

    const login = async (platform) => {
        // before starting a new login i need to remove old msal keys in cookies / sessions, it left some after logining out 
        deleteAllCookies()
        sessionStorage.clear()
        if (platform === LoginPlatforms.azure) {
            setPublicClientApplication(new PublicClientApplication(msalConfig));
        } else if (platform === LoginPlatforms.ciam) {
            setPublicClientApplication(new PublicClientApplication(msalConfigCIAM));
        } else {
            setPublicClientApplication(null);
        }
        setWillAuth(platform);
        Cookies.set(CookiesKeys.loginPlatform, platform);
    };

    return (
        <>
            {(isAuthenticated || willAuth === LoginPlatforms.azure || willAuth === LoginPlatforms.ciam || Cookies.get(CookiesKeys.loginPlatform)) && publicClientApplication != null ? (
                <MsalProvider instance={publicClientApplication}>
                    <App />
                </MsalProvider>
            ) : (
                <div>
                    <div>
                        <div className="flex flex-col items-center justify-center min-h-screen mt-[-100px]">
                            <img src={uniteDarkLogo} alt="UNITE Logo" className="w-64 mb-4 hidden dark:block" />
                            <img src={uniteLogo} alt="UNITE Logo" className="w-64 mb-4 dark:hidden" />
                            <h2 className="text-lg font-semibold mb-4 text-black dark:text-white">SELECT LOGIN:</h2>
                            <div className="flex space-x-4 mb-4">
                                <button onClick={() => login(LoginPlatforms.azure)} className="bg-orange-1000 text-white py-2 px-4 rounded">
                                KION Group
                                </button>
                                <button onClick={() => login(LoginPlatforms.ciam)} className="bg-black text-white py-2 px-4 rounded">
                                Sub-Contractor
                                </button>
                            </div>
                            <p className="text-gray-500 dark:text-gray-300">*Users must be added to UNITE before trying to login*</p>
                        </div>
                    </div>
                    <div className="flex items-center justify-center mt-4">
                        <div className="text-gray-500 Dark:text-gray-400 text-center">
                        UNITE is a Dematic / KION Group Project.
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Security;