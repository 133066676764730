import { UUID } from "crypto";
import { useDispatch, useSelector } from 'react-redux';
import { editComment } from '../../store/Comments';
import { RootState } from '../../store/store';
import { ObjectToSync } from '../../types/types';
import { CookiesKeys, URLs } from "../../Constants/Consts";
import { SyncItemsTypes } from "./SyncManager";
import Cookies from "js-cookie";

export default function CommentsSync(): { getCommentsToSync: () => ObjectToSync[], updateSyncItem: (itemId: UUID) => void } {
    const { Comments } = useSelector((state: RootState) => state.Comments)
    const dispatch = useDispatch()
    const getCommentsToSync = () => {
        const checklistItems = Comments.filter(c => c.notSync)
        const jwt = Cookies.get(CookiesKeys.jwt)
        return checklistItems.map(comment => {
            const url = !comment.creation ? URLs.putCommentsUrl(comment.commentUuid) : URLs.postCommentsUrl()
            const method = !comment.creation ? "PUT" : "POST"
            return {
                url,
                method,
                payload: comment,
                type: SyncItemsTypes.Comment,
                jwt
            }
        })
    }
    const updateSyncItem = (itemId) => {
        const checklistItem = Comments.find(c => c.commentUuid === itemId)
        dispatch(editComment({ ...checklistItem, notSync: false, creation: false }))
    }
    return {
        getCommentsToSync, updateSyncItem
    }
}
