import { useState, useMemo } from 'react';
import useAllUsers from "../../Helpers/CustomHooks/useAllUsers";
import Loader from '../../components/CoreComponents/Loader';
import { twMerge } from "tailwind-merge";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import SearchComponent from '../../components/CoreComponents/Search';
import roleCheck from "../../utils/roleCheck.ts";
import { ROLES } from "../../utils/roles.ts";
import { FaUserPlus } from "react-icons/fa";
import { RootState } from "../../store/store.ts";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function UserManagementPage() {
    const { role: userRole, id: userId } = useSelector((state: RootState) => state.SelectedUser.SelectedUser);
    const isAdminUser = roleCheck(userRole, [ROLES.UNITE_AdminUser, ROLES.UNITE_SuperAdmin, ROLES.UNITE_IT]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 25;
    const navigate = useNavigate();
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        isLoading
    } = useAllUsers();

    const filteredRows = useMemo(() => {
        return rows.filter(row => {
            if (!row.original) return false;
            const rowData = row.original;
            if (!isAdminUser && rowData.id !== userId) {
                return false;
            }
            const searchTermLower = searchTerm.toLowerCase();
            return (
                (rowData.fullName && rowData.fullName.toLowerCase().includes(searchTermLower)) ||
                (rowData.projectNumber && String(rowData.projectNumber).includes(searchTermLower)) ||
                (rowData.disciplineId && String(rowData.disciplineId).includes(searchTermLower)) ||
                (rowData.sequence && rowData.sequence.toLowerCase().includes(searchTermLower)) ||
                (rowData.kionEmail && rowData.kionEmail.toLowerCase().includes(searchTermLower)) ||
                (rowData.loginId && rowData.loginId.toLowerCase().includes(searchTermLower)) ||
                (rowData.project && rowData.project.toLowerCase().includes(searchTermLower)) ||
                (rowData.discipline && rowData.discipline.toLowerCase().includes(searchTermLower))
            );
        });
    }, [rows, searchTerm, isAdminUser, userId]);

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredRows.slice(indexOfFirstRow, indexOfLastRow);
    const totalPages = Math.ceil(filteredRows.length / rowsPerPage);

    const handleSearch = (term) => {
        setSearchTerm(term);
        setCurrentPage(1);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <>
            {isLoading ? <Loader loaders={{ isUsersLoading: true }} /> :
                <div className="overflow-x-auto">
                    <div className="flex justify-between items-center mb-4">
                        {/* Search Component */}
                        <div className="flex-grow mr-4">
                            <SearchComponent onSearch={handleSearch} />
                        </div>
                        {isAdminUser &&
                            <button
                                type="button"
                                className="bg-[#fdb419] float-right"
                                title="Add New User"
                                onClick={() => navigate('/AllUsers/Create')}>
                                <span className="flex items-center text-sm font-medium text-white">
                                    <FaUserPlus size={18} />
                                    <span className="ml-2">Add User</span>
                                </span>
                            </button>
                        }
                    </div>
                    <table {...getTableProps()} className="min-w-full text-left text-sm">
                        <thead className="border-b font-medium dark:border-neutral-500 uppercase">
                            {headerGroups.map(headerGroup => {
                                const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
                                return (
                                    <tr key={key} {...restHeaderGroupProps}>
                                        {headerGroup.headers.map(column => {
                                            const { key, ...restColumnProps } = column.getHeaderProps();
                                            return (
                                                <th className="px-3 py-2 text-left" key={key} {...restColumnProps}>
                                                    {column.render("Header")}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {currentRows.map((row, i) => {
                                prepareRow(row);
                                const { key, ...restRowProps } = row.getRowProps();
                                return (
                                    <tr
                                        key={key}
                                        onClick={() => navigate(`/AllUsers/Edit/${row.original.uuid}`)}
                                        className={twMerge("cursor-pointer hover:bg-slate-300", i % 2 == 0 ? "border-b bg-white dark:border-neutral-500 dark:bg-neutral-600"
                                            : "border-b bg-neutral-100 dark:border-neutral-500 dark:bg-neutral-700")}
                                        {...restRowProps}
                                    >
                                        {row.cells.map(cell => {
                                            const { key, ...restCellProps } = cell.getCellProps();
                                            return (
                                                <td className={"px-3 py-2 text-left"} key={key} {...restCellProps}>
                                                    <div className="flex items-center">
                                                        {key.includes("project") && !!row.values.project && (
                                                            <HiOutlineOfficeBuilding className="mr-2" style={{ color: "#fdb419" }} />
                                                        )}
                                                        {cell.render("Cell")}
                                                    </div>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    {/* Pagination Controls */}
                    <div className="flex justify-between items-center mt-4">
                        <button
                            className={twMerge(
                                "px-4 py-2 rounded",
                                currentPage === 1 ? "bg-gray-300 cursor-not-allowed" : "bg-orange-1000"
                            )}
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button
                            className={twMerge(
                                "px-4 py-2 rounded",
                                currentPage === totalPages ? "bg-gray-300 cursor-not-allowed" : "bg-orange-1000"
                            )}
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                </div>
            }
        </>
    );
}