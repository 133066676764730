import { useState } from "react";
import { FaArrowCircleRight } from "react-icons/fa";
import { ObjectToSync } from '../../types/types';
import JsonViewer from './JsonViewer';
import { FaArrowCircleDown } from "react-icons/fa";

export default function PendingItem({ objectData }: { objectData: ObjectToSync }) {
    const [collapsed, setCollapsed] = useState(false)
    return (
        <div className='flex gap-2 items-start border-b-2'>
            <div className="flex items-center">
                <button onClick={() => setCollapsed(!collapsed)}>{!collapsed ? <FaArrowCircleRight className="scale-125" />
                    : <FaArrowCircleDown className="scale-125" />}</button>
                {objectData.type}
            </div>
            {collapsed && <JsonViewer data={objectData.payload} />}
        </div>
    )
}
