import { AuthenticationResult, InteractionRequiredAuthError, InteractionStatus, InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import Cookies from "js-cookie";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CookiesKeys, LoginPlatforms, URLs, ssoProperties, ssoPropertiesCIAM } from '../../Constants/Consts';
import { fetchApi } from '../../Helpers/Functions';
import { addSelectedAccount, addSelectedUser } from "../../store/SelectedUser";
import { RootState } from "../../store/store";
import { UserAssignment } from '../../types/types';
import { ROLES, RoleMapping } from '../../utils/roles';

export default function MsalLayerProvider({ children }: { children: React.ReactNode }) {
    const dispatch = useDispatch()
    const [signedInUser, setSignedInUser] = useState<AuthenticationResult>(null)
    const [userAssignmentData, setUserAssignmentData] = useState<UserAssignment>(null)
    const { instance, inProgress, accounts } = useMsal();
    const SelectedUser = useSelector(
        (state: RootState) => state.SelectedUser.SelectedUser
    );
    function acquireToken(): Promise<void | AuthenticationResult> {
        if (inProgress === InteractionStatus.None) {
            const accessTokenRequest = {
                scopes: ssoProperties.scopes,
                account: accounts[0],
            };
            return instance
                .acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse: AuthenticationResult) => {
                    // Acquire token silent success
                    const accessToken = accessTokenResponse.accessToken;
                    Cookies.set(CookiesKeys.jwt, accessToken);
                    Cookies.set(CookiesKeys.roles, accessTokenResponse.idTokenClaims?.["roles"]);
                    Cookies.set(CookiesKeys.username, accessTokenResponse.account.username);
                    Cookies.set(CookiesKeys.name, accessTokenResponse.account.name);
                    if (accounts.length) dispatch(addSelectedAccount(accounts[0]))
                    setSignedInUser(accessTokenResponse)
                    return accessTokenResponse
                })
                .catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        return instance
                            .acquireTokenPopup(accessTokenRequest)
                            .then(function (accessTokenResponse: AuthenticationResult) {
                                // Acquire token interactive successs
                                const accessToken = accessTokenResponse.accessToken;
                                Cookies.set(CookiesKeys.jwt, accessToken);
                                Cookies.set(CookiesKeys.roles, accessTokenResponse.idTokenClaims?.["roles"]);
                                Cookies.set(CookiesKeys.username, accessTokenResponse.account.username);
                                Cookies.set(CookiesKeys.name, accessTokenResponse.account.name);
                                if (accounts.length) dispatch(addSelectedAccount(accounts[0]))
                                setSignedInUser(accessTokenResponse)
                                return accessTokenResponse
                            })
                            .catch(function (error) {
                                console.error(error)
                            });
                    }
                    console.error(error);
                });
        }
    }
    async function acquireTokenForCIAM(): Promise<void | AuthenticationResult> {
        if (inProgress === InteractionStatus.None) {
            const accessTokenRequest = {
                scopes: ssoPropertiesCIAM.scopes,
                account: accounts[0],
            };
            try {
                const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest)
                const accessToken = accessTokenResponse.idToken;
                Cookies.set(CookiesKeys.jwt, accessToken);
                Cookies.set(CookiesKeys.roles, accessTokenResponse.idTokenClaims?.["roles"]);
                Cookies.set(CookiesKeys.username, accessTokenResponse.idTokenClaims?.["email"]);
                Cookies.set(CookiesKeys.name, accessTokenResponse.account.name);
                setSignedInUser(accessTokenResponse)
            } catch (er) {
                if (er instanceof InteractionRequiredAuthError) {
                    const accessTokenResponse = await instance.acquireTokenPopup(accessTokenRequest)
                    const accessToken = accessTokenResponse.idToken;
                    Cookies.set(CookiesKeys.jwt, accessToken);
                    Cookies.set(CookiesKeys.roles, accessTokenResponse.idTokenClaims?.["roles"]);
                    Cookies.set(CookiesKeys.username, accessTokenResponse.idTokenClaims?.["email"]);
                    Cookies.set(CookiesKeys.name, accessTokenResponse.account.name);
                    setSignedInUser(accessTokenResponse)
                }
                console.log("error from ciam option:", er);
                throw er
            }
        }
    }

    useEffect(() => {
        (async () => {
            try {
                if (instance && Cookies.get(CookiesKeys.loginPlatform) === LoginPlatforms.azure) {
                    await acquireToken();
                }
                else if (instance && Cookies.get(CookiesKeys.loginPlatform) === LoginPlatforms.ciam) {
                    await acquireTokenForCIAM()
                }
            } catch (error) {
                if (error instanceof Error && error.message.includes("User cancelled")) {
                    console.log("?");
                } else {
                    // Handle other errors
                    console.error("Failed to acquire token:", error);
                }
            }
        })()
    }, [instance, inProgress]);


    const projects = useSelector((state: RootState) => state.Project.projects)
    const disciplines = useSelector((state: RootState) => state.Discipline.allDisciplines)

    useEffect(() => {
        if (signedInUser && navigator.onLine) {
            fetchApi(URLs.getAssignmentUsingLoginIdUrl(Cookies.get(CookiesKeys.username)))
                .then(res => res.json()).then((data: UserAssignment[]) => {
                    const user = data[0];
                    setUserAssignmentData(user)
                });
        }
    }, [signedInUser]);

    useEffect(() => {
        if (userAssignmentData && disciplines && projects) {
            const rawRole = signedInUser?.account?.idTokenClaims?.roles?.[0] ?? SelectedUser.role;
            const userRole = ROLES[RoleMapping[rawRole]] || 'Unknown Role';
            const userProject = projects.find(p => p.projectNumber === userAssignmentData.projectNumber)?.projectName;
            const userDiscipline = disciplines.find(d => d.id === userAssignmentData.disciplineId)?.disciplineName;
            dispatch(addSelectedUser({
                ...userAssignmentData,
                role: userRole,
                projectNumber: userAssignmentData?.projectNumber,
                disciplineId: userAssignmentData?.disciplineId,
                sequence: userAssignmentData?.sequence,
                project: userProject,
                discipline: userDiscipline,
                jwt: signedInUser?.accessToken ? signedInUser?.accessToken : signedInUser?.idToken
            }));
        }
    }, [disciplines, projects, userAssignmentData])

    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            {children}
        </MsalAuthenticationTemplate>
    )
}
