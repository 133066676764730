import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTable } from 'react-table';
import StatusTextIcon from "../../../../components/CoreComponents/StatusTextIcons";
import { DrilldownSelectedPropsMeaning, getDrilldownType } from "../../../../Constants/Consts";
import { RootState } from "../../../../store/store";
import { getDetilsFieldsArray } from "./useChecklistItemData";

export default function useCheckItemDetails({ item }) {
    const { SelectedUser } = useSelector((state: RootState) => state.SelectedUser)
    const drilldownType = getDrilldownType(SelectedUser.disciplineId)
    const DrilldownMappingObject = DrilldownSelectedPropsMeaning[drilldownType]

    const data = useMemo(() => getDetilsFieldsArray(item, DrilldownMappingObject).map(r => {
        if (r.name === 'Status') r.value = <StatusTextIcon statusId={r.value} />;
        return r;
    }), [item]);
    const columns = useMemo(() => [
        {
            accessor: 'name',
        },
        {
            accessor: 'value',
            Cell: ({ value }) => {
                return typeof value === 'object' ? value : <div>{value}</div>;
            }
        },
    ], []);

    return { ...useTable({ columns, data }) };
}
