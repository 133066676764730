import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModelApiItem, ModelItem } from "../types/Model/ModelSchema.ts";

interface ModelState {
    ModelItem: ModelItem;
    Models: Array<ModelApiItem>
}

const initialState: ModelState = {
    ModelItem: {},
    Models: []
};


const ModelSlice = createSlice({
    name: 'model',
    initialState,
    reducers: {
        setModels: (state, action: PayloadAction<ModelItem>) => {
            state.ModelItem = action.payload;
        },
        setModelsArray: (state, action: PayloadAction<Array<ModelApiItem>>) => {
            state.Models = action.payload;
        },
    },
});
export const {
    setModels, setModelsArray
} = ModelSlice.actions;
export default ModelSlice.reducer;
