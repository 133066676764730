import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { FaCamera, FaEraser, FaLock, FaPaintBrush, FaTrashAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setModal } from "../../store/Utilities.ts";
import CircleColorPicker from "./CircleColorPicker .tsx";
import './ImageSelector.css';

type AnnotationData = { x: number, y: number, color: string, lineWidth: number, newStroke: boolean }

function useNonPassiveEventListeners(ref, events, disabled) {
    useEffect(() => {
        const element = ref.current;
        if (element) {
            const wrappedEvents = events.map(({ type, listener }) => ({
                type,
                listener: (event) => {
                    if (!disabled) {
                        listener(event);
                    }
                },
            }));

            wrappedEvents.forEach(({ type, listener }) => {
                element.addEventListener(type, listener, { passive: false });
            });

            return () => {
                wrappedEvents.forEach(({ type, listener }) => {
                    element.removeEventListener(type, listener);
                });
            };
        }
    }, [ref, events, disabled]); // Add disabled to the dependency array
}

interface ImageSelectorProps {
    originalImage?: Blob | string | null;
    imageType: string;
    disabled?: boolean;
    showChangedWarning?: boolean;
}

interface ImageSelectorRef {
    getImage: () => Promise<Blob>;
    getDidChange: () => boolean;
}

const ImageSelector = forwardRef<ImageSelectorRef, ImageSelectorProps>(({
    originalImage,
    imageType,
    disabled = false,
    showChangedWarning = false
}, ref) => {
    const dispatch = useDispatch();
    const [image, setImage] = useState(originalImage);
    const colors = ['#444444', '#fa5252', '#ff922b', '#ffd43b', '#51cf66', '#329af0', '#cc5de8'];
    const [selectedColor, setSelectedColor] = useState(colors[0]);
    const [brushSize, setBrushSize] = useState(2);
    const fileInput = useRef<HTMLInputElement>(null);
    const cameraInput = useRef<HTMLInputElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [didChange, setDidChange] = useState(false);
    const annotationData = useRef<Array<AnnotationData>>([])

    useEffect(() => {
        setImage(originalImage);
        setDidChange(false)
    }, [originalImage]);

    useEffect(() => {
        const handleResize = () => {
            if (image) {
                createCanvas();
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [image]);

    useEffect(() => {
        if (canvasRef.current) {
            const context = canvasRef.current.getContext('2d');
            if (context) {
                context.strokeStyle = selectedColor;
                context.lineWidth = brushSize;
            }
        }
    }, [selectedColor, brushSize]);

    const emptyStrokeData = () => {
        annotationData.current.splice(0, annotationData.current.length - 1)
    }
    useEffect(() => {
        return () => {
            emptyStrokeData()
        }
    }, [])

    const createCanvas = () => {
        if (!image || !canvasRef.current) return;
        const img = new Image();
        img.onload = () => {
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
            if (context) {
                const aspectRatio = img.width / img.height;
                const containerWidth = canvas.parentElement.offsetWidth - 20;// Subtracting 20px for padding
                const containerHeight = containerWidth / aspectRatio;
                canvas.width = containerWidth;
                canvas.height = containerHeight;
                context.strokeStyle = selectedColor;
                context.lineWidth = brushSize;
                context.clearRect(0, 0, containerWidth, containerHeight);
                context.drawImage(img, 0, 0, containerWidth, containerHeight);
                if (annotationData.current.length) {
                    annotationData.current.forEach(iad => {
                        if (iad.newStroke) {
                            context.beginPath();
                            context.moveTo(iad.x, iad.y)
                            context.strokeStyle = iad.color;
                            context.lineWidth = iad.lineWidth;
                        }
                        context.lineTo(iad.x, iad.y);
                        context.stroke();
                    })
                }
            }
        };
        img.src = image instanceof Blob ? URL.createObjectURL(image) : image;
    }

    const getCoordinates = (event) => {
        if (event.touches && event.touches.length > 0) {
            const { clientX, clientY } = event.touches[0];
            const { left, top } = canvasRef.current.getBoundingClientRect();
            return { offsetX: clientX - left, offsetY: clientY - top };
        } else {
            return { offsetX: event.nativeEvent.offsetX, offsetY: event.nativeEvent.offsetY };
        }
    };

    const startDrawing = useCallback((event) => {
        const { offsetX, offsetY } = getCoordinates(event);
        const context = canvasRef.current.getContext('2d');
        if (context) {
            context.beginPath();
            context.moveTo(offsetX, offsetY);
            setIsDrawing(true);
            setDidChange(true);
        }
    }, []);

    const draw = useCallback((event) => {
        if (!isDrawing) return;
        const { offsetX, offsetY } = getCoordinates(event);
        const context = canvasRef.current.getContext('2d');
        if (context) {
            context.lineTo(offsetX, offsetY);
            context.stroke();
            const drawData = { color: selectedColor, x: offsetX, y: offsetY, lineWidth: brushSize, newStroke: false }
            annotationData.current.push(drawData)
        }
    }, [isDrawing]);

    const finishDrawing = useCallback(() => {
        setIsDrawing(false);
    }, []);

    const touchStartHandler = useCallback((event) => {
        if (disabled) return;
        event.preventDefault();
        startDrawing(event);
        //-----//
        const { offsetX, offsetY } = getCoordinates(event);
        const drawData = { color: selectedColor, x: offsetX, y: offsetY, lineWidth: brushSize, newStroke: true }
        annotationData.current.push(drawData)
    }, [startDrawing, disabled, selectedColor, brushSize]);

    const touchMoveHandler = useCallback((event) => {
        if (disabled) return;
        event.preventDefault();
        draw(event);
    }, [draw, disabled]);

    const touchEndHandler = useCallback((event) => {
        if (disabled) return;
        event.preventDefault();
        finishDrawing();
    }, [finishDrawing, disabled]);

    useNonPassiveEventListeners(canvasRef, [
        { type: 'touchstart', listener: touchStartHandler },
        { type: 'touchmove', listener: touchMoveHandler },
        { type: 'touchend', listener: touchEndHandler },
    ], disabled);

    const handleImageChange = (event) => {
        setDidChange(true);
        const file = event.target.files?.[0];
        if (file) {
            setImage(file);
            // notify(file)
        }
    };

    const imageEraserModal = () =>
        dispatch(setModal({
            Chilren: () => <>Do you want to erase all the mark-ups on the current photo?</>,
            onConfirm: () => {
                createCanvas();
                emptyStrokeData()
                setDidChange(true);
            },
            open: true
        }));

    const imageTrashModal = () => {
        dispatch(setModal({
            Chilren: () => <>Are you sure you want to remove the current image?</>,
            onConfirm: () => {
                fileInput.current.value = null;
                cameraInput.current.value = null;
                setDidChange(originalImage != null);
                setImage(null);
            },
            open: true
        }));
    }


    const getImage = useCallback((): Promise<Blob> => {
        return new Promise((resolve, reject) => {
            if (!canvasRef.current) {
                resolve(null);
            } else {
                canvasRef.current.toBlob(blob => {
                    if (blob) {
                        resolve(blob);
                    } else {
                        reject(new Error("Failed to convert canvas to Blob."));
                    }
                }, `image/${imageType}`, 0.8);
            }
        });
    }, [imageType]);

    const getDidChange = () => {
        return didChange;
    }
    const notify = (callback) => {
        callback(image)
    }
    // useCallback(, [image, didChange])

    useImperativeHandle(ref, () => ({
        getImage,
        getDidChange,
        notify,
        emptyStrokeData
    }));

    return (
        <div className={`w-full h-full image-upload`}>
            {image ? (
                <>
                    <canvas
                        onMouseDown={!disabled ? startDrawing : undefined}
                        onMouseUp={!disabled ? finishDrawing : undefined}
                        onMouseMove={!disabled ? draw : undefined}
                        onMouseLeave={!disabled ? finishDrawing : undefined}
                        ref={canvasRef}
                    />
                    {!disabled ?
                        <div className="mt-5 w-full flex flex-wrap" style={{ alignItems: 'center', gap: '15px' }}>
                            <CircleColorPicker colors={colors} selectedColor={selectedColor}
                                onColorSelect={setSelectedColor} />
                            <div className={'flex'}>
                                <FaEraser className='icon pointer-cursor' size={25}
                                    onClick={imageEraserModal} />
                                <div className='flex ml-10' style={{ alignItems: 'center' }}>
                                    <FaPaintBrush className='icon brush mr-2' size={20} />
                                    <input type="range" min="1" max="10" value={brushSize}
                                        onChange={(event) => setBrushSize(Number(event.target.value))}
                                        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700" />
                                    <FaPaintBrush className='icon brush ml-2' size={35} />
                                </div>
                                <FaTrashAlt className='icon ml-10 pointer-cursor' size={25} onClick={imageTrashModal} />
                            </div>
                        </div> : ''}
                </>
            ) : (
                <div className={`${showChangedWarning && didChange ? "h-5/6" : "h-full"}`}>
                    <div className={'unlock-container pointer-cursor relative'}>
                        <div onClick={() => fileInput.current.click()} className={'unlock-container relative'}>
                            <FaLock className="icon lock-icon   text-amber-500" />
                            <span className={'text-amber-500'}>Tap to unlock</span>
                        </div>
                        <FaCamera className="icon absolute bottom-0 right-0 text-amber-500 pointer-cursor"
                            onClick={() => cameraInput.current.click()} />
                    </div>
                </div>
            )}
            {showChangedWarning && didChange ? <span className={'text-uniteOrange font-bold'}>Please ensure the changes made to the image are saved</span> : ''}

            <input
                ref={fileInput}
                type="file"
                accept="image/*"
                onChange={handleImageChange}
            />
            <input
                ref={cameraInput}
                type="file"
                accept="image/*"
                capture="environment"
                onChange={handleImageChange}
            />
        </div>
    );
});

export default ImageSelector;
