import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Status } from '../types/types';

const initialState:
    {
        Status: Status[]
    } = {
    Status: []
}

const Units = createSlice({
    name: 'status',
    initialState,
    reducers: {
        addStatus: (state, action: PayloadAction<Status[]>) => {
            state.Status = action.payload
        },
    },
});

export const { addStatus } = Units.actions;

export default Units.reducer;
