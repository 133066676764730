import React, { useRef, useEffect } from 'react'

const channel = new MessageChannel();
export const ctx = React.createContext<MessagePort>(null)
export default function SWChannelProvider({ children }: { children: React.ReactNode }) {
    const port1Ref = useRef<MessagePort>()
    port1Ref.current = channel.port1;
    // listen to 1st port
    port1Ref.current.onmessage = _ => {
        console.log("message: ")
    }
    // send 2nd port
    navigator.serviceWorker.ready.then((reg) => {
        reg.active.postMessage({ TYPE: 'PORT' }, [channel.port2]);
    });
    port1Ref.current.postMessage("sss")
    useEffect(() => {
    }, [])
    return (
        <ctx.Provider value={port1Ref.current}>
            {children}
        </ctx.Provider>
    )
}
