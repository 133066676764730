
const JsonViewer = ({ data }) => {
 const renderValue = (value) => {
    if (typeof value === 'string') {
      return `"${value}"`;
    } else if (typeof value === 'number') {
      return value;
    } else if (typeof value === 'boolean') {
      return value.toString();
    } else if (value === null) {
      return 'null';
    } else if (Array.isArray(value)) {
      return (
        <div>
          [
          {value.map((item, index) => (
            <div key={index}>{renderValue(item)}</div>
          ))}
          ]
        </div>
      );
    } else if (typeof value === 'object') {
      return (
        <div>
          {Object.keys(value).map((key) => (
            <div key={key}>
              {key}: {renderValue(value[key])}
            </div>
          ))}
        </div>
      );
    }
    return 'undefined';
 };
 return <div>{renderValue(data)}</div>;
};

export default JsonViewer;
