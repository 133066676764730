import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState:
    {
        dd2Options:{[key:string]:string[]}

    } = {
        dd2Options: {}
}

const Units = createSlice({
    name: 'dd2Options',
    initialState,
    reducers: {
        addDD2Options: (state, action: PayloadAction<{}|{[key:string]:string[]}>) => {
            state.dd2Options = action.payload
        },
    },
});

export const { addDD2Options } = Units.actions;

export default Units.reducer;
