import React from "react";
import { BiReset } from "react-icons/bi";
import { FaCheck } from "react-icons/fa";
import { FiSlash } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { pathDelmiter, Status, URLs } from "../../../../Constants/Consts";
import userOnlineCheck from "../../../../Helpers/CustomHooks/userOnlineCheck";
import { fetchApi } from "../../../../Helpers/Functions";
import { updateChangeLogs } from "../../../../store/ChangeLogs";
import { setSelectedInspectionTask, setSelectedPath, setSelectedDD01, updateCheckListItem, setSelectedDD02 } from "../../../../store/SequenceObject";
import { RootState } from "../../../../store/store";
import { ChecklistTaskApiResponse } from "../../../../types/DataDD/DataDD";

export default function CheckListButtons({ item, path }: {
  item: ChecklistTaskApiResponse;
  path: string;
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const online = userOnlineCheck();
  const hasActivePunchList = (checklistUuid) => {
    const relatedPendingPLs = ProjectPunchLists.filter(pl => pl.ctUuid?.toLocaleUpperCase() === checklistUuid?.toLocaleUpperCase() && (pl.status === Status.Pending || pl.status === Status.OnHold || pl.status === Status.ReadyForReInspection || pl.status === Status.MaterialsReceived));
    return relatedPendingPLs.length > 0;
  };

  const fetchChangeLogs = async () => {
    if (online) {
      try {
        const logs = await fetchApi(URLs.getUpdatedChangeLogsUrl(item.projectNumber, item.disciplineId, item.ctUuid))
          .then(res => res.json());
        dispatch(updateChangeLogs({ uuidType: "Checklist", uuid: item.ctUuid, data: logs }));
      } catch (error) {
        console.error('Failed to fetch changelogs:', error);
      }
    }
  };

  const { ProjectPunchLists } = useSelector((state: RootState) => state.PunchList)
  const update = React.useCallback(async (e, status) => {
    e.stopPropagation();
    const Statuses = {
      1: "Open",
      2: "Pass",
      3: "Fail",
      5: "Mark as N/A"
    };

    if (hasActivePunchList(item.ctUuid) && status !== Status.Failed) {
      if (status === Status.NA) {
        toast.error(`Cannot mark this task as N/A until all associated punch list items are completed.`, { type: "error" });
        return;
      }
      toast.error(`Cannot ${Statuses[status]} this task until all associated punch list items are completed.`, { type: "error" });
      return;
    }

    const updatedItem = { ...item, statusId: status };
    try {
      await fetchApi(URLs.updateDD(item.ctUuid), {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ statusId: status }),
      })
        .then((res) => res.json())
        .then(() => {
          console.log(`${path}.${updatedItem.ctUuid}`, updatedItem);
          dispatch(
            updateCheckListItem({
              completePath: path,
              checklistItem: updatedItem,
            })
          )
        }
        );
    } catch (error) {
      if (!navigator.onLine) {
        dispatch(
          updateCheckListItem({
            completePath: path,
            checklistItem: { ...updatedItem, notSync: true },
          })
        );
      } else throw error;
    }
  }, [ProjectPunchLists, item, dispatch, path]);


  const navigateToPunchList = (item: ChecklistTaskApiResponse) => {
    const pathArray = path.split(pathDelmiter)
    // this is a details page and the selected path is the complete path of the checklist item,
    // selected path refer to the path of all the checklists items group
    const selectedPath = pathArray.slice(0, pathArray.length - 1).join('.');
    dispatch(setSelectedPath(selectedPath))
    console.log("pathArray", pathArray);

    // todo: delete these place holders
    dispatch(setSelectedDD01(pathArray[1]))
    dispatch(setSelectedDD02(pathArray[3]))
    dispatch(setSelectedInspectionTask(item.taskDescription));
    navigate(`/PunchList/Form/CheckList/${item.ctUuid}`);
  };
  return (
    <div className="flex justify-start pt-8 mb-8 gap-4">
      <div className="flex flex-col items-center">
        <div
          onClick={async (e) => {
            e.stopPropagation();
            await update(e, Status.Passed);

            try {
              await fetchChangeLogs();
            } catch (error) {
              console.log(error);
            }
          }}
          className={`rounded-full p-2 flex justify-center items-center cursor-pointer ${hasActivePunchList(item.ctUuid) ? 'bg-gray-500 opacity-50' : 'bg-green-500'}`}
        >
          <FaCheck className="text-white" />
        </div>
        <div className={`text-center text-xs font-bold mt-1 uppercase ${hasActivePunchList(item.ctUuid) ? 'opacity-50' : ''}`}>
          <p>Mark</p>
          <p>Passed</p>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <div
          onClick={async (e) => {
            e.stopPropagation();
            await update(e, Status.Failed);
            navigateToPunchList(item);

            try {
              if (navigator.onLine) {
                await fetchChangeLogs();
              }
            } catch (error) {
              console.log(error);
            }
          }}
          className="rounded-full p-2 flex justify-center items-center cursor-pointer bg-red-500">
          <IoMdClose className="text-white" />
        </div>
        <div className="text-center text-xs font-bold mt-1 uppercase">
          <p>Create</p>
          <p>Punch List</p>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <div
          onClick={async (e) => {
            e.stopPropagation();
            await update(e, Status.NA);

            try {
              if (navigator.onLine) {
                await fetchChangeLogs();
              }
            } catch (error) {
              console.log(error);
            }
          }}
          className={`rounded-full p-2 flex justify-center items-center cursor-pointer ${hasActivePunchList(item.ctUuid) ? 'bg-gray-500 opacity-50' : 'bg-gray-500'}`}
        >
          <FiSlash className="text-white" />
        </div>
        <div className={`text-center text-xs font-bold mt-1 uppercase ${hasActivePunchList(item.ctUuid) ? 'opacity-50' : ''}`}>
          <p>Not</p>
          <p>Applicable</p>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <div
          onClick={async (e) => {
            e.stopPropagation();
            await update(e, Status.Open);

            try {
              if (navigator.onLine) {
                await fetchChangeLogs();
              }
            } catch (error) {
              console.log(error);
            }
          }}
          className={`rounded-full p-2 flex justify-center items-center  ${hasActivePunchList(item.ctUuid) ? 'bg-gray-500 opacity-50' : 'bg-gray-500'}`}>
          <BiReset className="text-white" />
        </div>
        <div className={`text-center text-xs font-bold mt-1 uppercase ${hasActivePunchList(item.ctUuid) ? 'opacity-50  ' : ''}`}>
          <p>Reset</p>
          <p>Status</p>
        </div>
      </div>
    </div>
  );
}
