import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { URLs } from "../../Constants/Consts";
import { fetchApi } from "../../Helpers/Functions";
import { addDisciplines, addProjectDisciplines } from "../../store/Discipline";
import { addDisplinesImagesFlag, updateDownloadTriggered, updateHasDownloadedValue } from "../../store/Utilities";
import { RootState } from "../../store/store";
export default function storeDisciplines() {
    const dispatch = useDispatch()
    const [isloading, setIsloading] = useState(false)
    const { SelectedUser } = useSelector((state: RootState) => state.SelectedUser)
    const [storedDisciplines, setStoredDisciplines] = useState([])
    const { downloadTriggered: { disciplines: triggered } } = useSelector(
        (state: RootState) => state.Utilities
    );
    const calledOnceRef = useRef(false)
    useEffect(() => {
        (async () => {
            if (navigator.onLine && SelectedUser.projectNumber && SelectedUser.jwt && (triggered)) {
                calledOnceRef.current = true
                setIsloading(true)
                const disciplines = await fetchApi(URLs.getDisciplinesUrl()).then(res => res.json())
                const projectDisciplinesIDs = await fetchApi(URLs.getDisciplinesPerProjectUrl(SelectedUser.projectNumber)).then(res => res.json())
                dispatch(addDisciplines(disciplines))
                dispatch(addProjectDisciplines(projectDisciplinesIDs))
                setStoredDisciplines(projectDisciplinesIDs)
                disciplines?.forEach?.(discipline => {
                    dispatch(addDisplinesImagesFlag(discipline?.disciplineCode))
                })
                dispatch(updateHasDownloadedValue({ key: "disciplines", value: true }))
                dispatch(updateDownloadTriggered({ key: "disciplines", value: false }))
                setIsloading(false)

               // chain downloading drawings
               dispatch(updateDownloadTriggered({ key: "drawings", value: true }))
            }
        })()
    }, [SelectedUser.jwt, SelectedUser.projectNumber, triggered])
    return { isloading, storedDisciplines }
}
