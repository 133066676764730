import ReactLoading from "react-loading";
import { splitOnCapitals } from "../../Helpers/Functions";

export default function Loader({ loaders = {} }: { loaders?: { [key: string]: boolean } }) {
  const loadingNicknames = {
    isProjectsLoading: 'LOADING PROJECTS',
    isFilesLoading: 'LOADING MAPS / DRAWINGS',
    isSequencesLoading: 'LOADING SEQUENCES',
    isLogsTypesLoading: 'LOADING CHANGELOG TYPES',
    isDisciplineLoading: 'LOADING DISCIPLINES',
    isUsersLoading: 'LOADING ALL USERS',
    isCommentsLoading: 'LOADING COMMENTS',
    isLogsLoading: 'LOADING CHANGELOGS',
    isIssuesLoading: 'LOADING ISSUES',
    isStatusLoading: 'LOADING STATUSES',
    isCategoriesLoading: 'LOADING CATEGORIES',
    isPunchlistLoading: 'LOADING PUNCH LISTS',
    isPunchlistImagesLoading: 'LOADING PUNCH LIST IMAGES',
    isAdHocUnitsLoading: 'LOADING AD HOC UNITS',
    isModelsLoading: 'LOADING MODELS',
    isRelatedChecklistDetailsLoading: 'LOADING CHECKLIST DETAILS',
    isCreatingPunchListLoading: 'CREATING PUNCH LIST',
  };

  return (
    // <div className="fixed  z-50 flex flex-col justify-center items-center bg-white bg-opacity-50 backdrop-blur-sm dark:bg-black dark:bg-opacity-50">
    <div className="fixed inset-0 z-50 flex items-center justify-center w-screen h-screen text-black bg-white bg-opacity-90 dark:text-white dark:bg-black dark:bg-opacity-90">
      <div className="flex flex-col">
        {Object.keys(loaders).map(k => {
          const loadingName = loadingNicknames[k] || splitOnCapitals(k.replace('is', '').replace('Loading', '') ?? '')?.toUpperCase();
          return (
            <div key={k} className="flex items-center">
              {loaders[k] && (
                <>
                  <ReactLoading
                    type="spin"
                    color="#fdb419"
                    height={18}
                    width={18}
                  />
                  <div className="font-bold ml-2 text-left">{loadingName}</div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}