import { FaCheckCircle, FaPauseCircle, FaRegCircle } from 'react-icons/fa';
import { FcRedo, FcCancel } from 'react-icons/fc';
import { HiDocumentDuplicate } from 'react-icons/hi';
import { AiFillClockCircle } from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import { LuCircleSlash2 } from "react-icons/lu";
import { IoIosWarning } from "react-icons/io";

const statusDetails = {
    1: { icon: <FaRegCircle />, iconColor: '#fdb419', text: 'Open' },
    2: { icon: <FaCheckCircle />, textColor: '#000000', iconColor: '#56c240', text: 'Passed' },
    3: { icon: <MdCancel />, textColor: '#000000', iconColor: '#fdb419', text: 'Failed' },
    4: { icon: <FcRedo />, textColor: '#000000', iconColor: '#fdb419', text: 'Ready For Re-Inspection' },
    5: { icon: <LuCircleSlash2 />, textColor: '#000000', iconColor: '#8a8a8a', text: 'Not Applicable' },
    6: { icon: <FaRegCircle />, textColor: '#000000', iconColor: '#fdb419', text: 'Pending' },
    7: { icon: <HiDocumentDuplicate />, textColor: '#000000', iconColor: '#fdb419', text: 'Duplicate' },
    8: { icon: <FaCheckCircle />, textColor: '#000000', iconColor: '#56c240', text: 'Completed' },
    9: { icon: <FaPauseCircle />, textColor: '#000000', iconColor: '#8a8a8a', text: 'On-Hold' },
    10: { icon: <FcCancel />, textColor: '#000000', iconColor: '#fdb419', text: 'Cancelled' },
    11: { icon: <AiFillClockCircle />, textColor: '#000000', iconColor: '#8a8a8a', text: 'Materials Received' },
    12: { icon: <IoIosWarning />, textColor: '#000000', iconColor: 'red', text:'Completed with Root Cause Conflict' },
};

const StatusTextIcon = ({ statusId }) => {
    const status = statusDetails[statusId];

    if (!status) {
        return null;
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ color: status.iconColor, marginRight: '8px' }}>
                {status.icon}
            </span>
            <span style={{ fontWeight: '600' }}>
                {status.text}
            </span>
        </div>
    );
};

export default StatusTextIcon;
