import React, { PropsWithChildren } from "react";

export default function List<T>({
  data,
  onClick,
  isItemDisabled: isDisabled,
  renderChildItem,
  }: {
  data: Array<T> | undefined;
  onClick: (item: T) => void;
  isItemDisabled: boolean | ((item: T) => boolean);
  renderChildItem: (
    item: T
  ) => React.FunctionComponent<
    PropsWithChildren<{ onClick?: (item: T) => void, className:string }>
  >;
}) {
  
  return (
    <table className="min-w-full divide-y divide-gray-200">
      <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-1000">
        {data?.map((item, i) => {
          const disabled =
            typeof isDisabled === "function" ? isDisabled?.(item) : isDisabled;
          const ReactItem = renderChildItem(item);

          return (
            <tr
              key={i}
              onClick={() => {
                if (!disabled) onClick?.(item);
              }}
              className={`${
                disabled
                  ? "bg-zinc-50 text-opacity-50 cursor-not-allowed"
                  : "hover:bg-gray-100 hover:cursor-pointer"
              }`}
            >
              <td className="py-4 whitespace-nowrap text-left text-sm text-gray-900 dark:text-white">
                <ReactItem className={""} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}