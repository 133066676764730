import { useTable } from 'react-table';
import { twMerge } from 'tailwind-merge';

export default function ViewTable({ columns = [], data = [], onRowClick = (_) => { } }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns, data,
    autoResetFilters: false,
    autoResetSortBy: false,
  });

  return (
    <div className="overflow-x-auto">
      <table {...getTableProps()} className="min-w-full">
        <thead className="border-b dark:border-neutral-500">
          {headerGroups.map(headerGroup => {
            const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
            return (
              <tr key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map(column => {
                  const { key, ...restHeaderProps } = column.getHeaderProps();
                  return (
                    <th
                      key={key}
                      className="px-0.5 py-2 text-left text-sm sm:text-xs"
                      {...restHeaderProps}>
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            const { key, ...restRowProps } = row.getRowProps();
            return (
              <tr
                key={key}
                onClick={() => onRowClick(row.original)}
                className={twMerge("cursor-pointer hover:bg-gray-200", 
                                    i % 2 === 0 ? "border-b bg-white dark:border-neutral-500 dark:bg-neutral-600"
                                    : "border-b bg-neutral-100 dark:border-neutral-500 dark:bg-neutral-700"
                                  )
                          }
                {...restRowProps}>
                {row.cells.map(cell => {
                  const { key, ...restCellProps } = cell.getCellProps();
                  return (
                    <td
                      key={key}
                      className="px-0.5 py-3 text-left text-sm sm:text-xm"
                      {...restCellProps}>
                      <div className="flex">
                        {cell.render("Cell")}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}