import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import BasicDDL from '../../../../components/CoreComponents/ReactFormComponents/BasicDDL';
import MapComponent from "../../../../components/Map/map";
import { addSiteMapInput } from '../../../../store/PunchList';
import { RootState } from '../../../../store/store';
import { siteMapSchema } from '../../../../types/PunchList/PunchListSchema';

export default function SiteMap({ next, previous }) {
    const [url, seturl] = useState("")
    const { clearErrors, handleSubmit, formState: { errors }, control, watch, setValue } = useForm({
        resolver: zodResolver(siteMapSchema),
    });
    const { Location, DrawingId } = useSelector((state: RootState) => state.PunchList.PunchListObject)
    const { urls } = useSelector((state: RootState) => state.Drawings)
    const dispatch = useDispatch()
    const onSubmit = async () => {
        next();
    }

    watch((data, { name }) => {
        // listen to inputs from data -names are undefiend in begining- to set the map url
        if (name === "DrawingId") {
            try {
                const parsedData = JSON.parse(data?.["DrawingId"])
                seturl(parsedData?.["url"])
                dispatch(addSiteMapInput({ name, value: parsedData?.["id"] }))
            } catch (error) {
                seturl("")
                dispatch(addSiteMapInput({ name, value: '' }))
            }
        }
        if (name === "Location")
            dispatch(addSiteMapInput({ name: "Location", value: data?.["Location"] }))
    })

    const [location, setLocation] = useState<number[]>(Location ?? [])
    
    useEffect(() => {
        setValue("Location", location)
        location.length && clearErrors("Location")
    }, [location])

    const goToDetails = () => previous()

    useEffect(function resetDrawing() {
        const selectedUrl = urls.find(u => u.id == Number(DrawingId))
        seturl(selectedUrl?.url ?? '')
        const optionData = selectedUrl ? JSON.stringify({ url: selectedUrl?.data, id: selectedUrl?.id }) : ''
        setValue('DrawingId', optionData)
    }, [])

    const [options, setOptions] = useState([])
    
    useEffect(() => {
        (async () => {
            const ddlOptions = await Promise.all(urls.map(async ({ label, id, data }) => {
                const blobData = await fetch(data)
                    .then(response => response.blob())
                const blobUrl = window.URL.createObjectURL(blobData)
                return ({ text: label, value: JSON.stringify({ url: blobUrl, id }) })
            }))
            setOptions([{ text: "Select...", value: "" }, ...ddlOptions])
        })()
    }, [urls])

    return (
        <div className='mt-8'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='flex flex-col items-start ml-0'>
                    Select Level:
                    <BasicDDL
                        options={options}
                        control={control}
                        defaultValue={''}
                        errors={errors}
                        inputKey='DrawingId'
                        label=''
                        name='DrawingId'
                    />
                </div>
                <div className='mb-4'>
                    <MapComponent errors={errors} location={location} setLocation={setLocation} ImageLayerUrl={url} />
                </div>
                <button type="button" onClick={goToDetails} className='save-btn absolute left-6'>
                    Previous
                </button>
                <button className='save-btn absolute right-6'>
                    Next
                </button>
            </form>
        </div>
    );
}