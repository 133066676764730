import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState:
    {
        photos: Array<{
            photo: Blob,
            punchlistUuid: string,
            photoType: string
        }>,

    } = {
    photos: [],
}

const Units = createSlice({
    name: 'photos',
    initialState,
    reducers: {
        addPhoto: (state, action: PayloadAction<{
            photo: Blob,
            punchlistUuid: string,
            photoType: string
        }>) => {
            state.photos.push(action.payload)
        },
    },
});

export const { addPhoto } = Units.actions;

export default Units.reducer;
