import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Project } from '../types/types';

const initialState:
    {
        projects: Project[],
        SelectedProject: Project,

    } = {
    projects: [],
    SelectedProject: {
        projectNumber: 0,
        country: "",
        isArchived: false,
        projectLocation: "",
        projectName: ""
    },
}

const Units = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        addSelectedProject: (state, action: PayloadAction<Project>) => {
            state.SelectedProject = action.payload
        },
        addProjects: (state, action: PayloadAction<Project[]>) => {
            state.projects = action.payload
        },
    },
});

export const { addProjects, addSelectedProject } = Units.actions;

export default Units.reducer;
