import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { URLs } from "../../Constants/Consts";
import { AssignmentApiData, Discipline } from "../../types/types";
import { fetchApi } from "../Functions";
import { useTable } from 'react-table';
import { RootState } from "../../store/store";

export default function useAllUsers() {
    const projects = useSelector((state: RootState) => state.Project.projects);
    const { data: disciplines } = useQuery<Array<Discipline>>({
        queryKey: ["disciplines"],
        queryFn: async () => {
            const disciplines = await fetchApi(URLs.getDisciplinesUrl()).then(res => res.json()) as Array<Discipline>;
            return disciplines;
        },
        gcTime: 0,
    });

    const { data: AllUsers, isLoading } = useQuery<Array<AssignmentApiData>>({
        queryKey: ["AllUsers"],
        queryFn: async () => {
            const usersAssignments = await fetchApi(URLs.getAssignmentsUrl()).then(res => res.json()) as Array<AssignmentApiData>;

            const sortedAndFilteredUsers = usersAssignments
                .filter(ua => ua.id !== 1)
                .sort((a, b) => a.fullName.localeCompare(b.fullName))
                .map(ua => ({
                    ...ua,
                    project: projects.find(p => p.projectNumber === ua.projectNumber)?.projectName,
                    discipline: disciplines?.find(d => d.disciplineId === ua.disciplineId)?.disciplineName
                }));

            return sortedAndFilteredUsers;
        },
        enabled: !!disciplines?.length && !!projects.length,
        gcTime: 0,
    });

    const columns = useMemo(() => [
        {
            Header: 'Name',
            accessor: 'fullName',
        },
        {
            Header: 'Email',
            accessor: 'kionEmail',
        },
        {
            Header: 'Project',
            accessor: 'project',
        },
        {
            Header: 'Discipline',
            accessor: 'discipline',
        },
        {
            Header: 'Sequence',
            accessor: 'sequence',
        },
    ], []);

    const data = useMemo(() => AllUsers || [], [AllUsers]);

    return { ...useTable({ columns, data }), isLoading };
}
