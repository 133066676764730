import './CircleColorPicker.css';
import { FaCheck } from "react-icons/fa";

const CircleColorPicker = ({ colors, onColorSelect, selectedColor }) => {
    return (
        <div className="color-picker-row">
            {colors.map((color) => (
                <div
                    key={color}
                    className={`color-swatch ${selectedColor === color ? 'selected' : ''}`}
                    style={{ backgroundColor: color }}
                    onClick={() => onColorSelect(color)}
                >
                    {selectedColor === color && <FaCheck className="selected-icon" />}
                </div>
            ))}
        </div>
    );
};


export default CircleColorPicker;
