import { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { MdOutlineClear } from 'react-icons/md';
import { RiArrowRightSLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../../components/CoreComponents/Breadcrumb';
import { pathDelmiter } from '../../Constants/Consts';
import { getByPath, replaceLastOccurrence } from '../../Helpers/Functions';
import { RootState } from '../../store/store';
import { setBackClickHandler, setTaskDisplayedPath } from '../../store/Utilities';
import { DDCheckList } from '../../types/DataDD/DataDD';

export default function TasksDD() {
    const { ChecklistsObject } = useSelector((state: RootState) => state.SequenceObject);
    const { SelectedUser } = useSelector((state: RootState) => state.SelectedUser);
    const { taskDisplayedPath } = useSelector((state: RootState) => state.Utilities);
    const [displayedObject, setDisplayedObject] = useState({})
    const isPathToShowTasks = (path) => {
        const pathArrayToNode = path.split(pathDelmiter)
        const access = pathArrayToNode?.pop()
        if (access !== "childs" && access !== "taskChilds") {
            return
        }
        const pathToNode = pathArrayToNode?.join(pathDelmiter)
        if (!pathToNode) return
        const currentObject = getByPath(pathToNode, ChecklistsObject)
        return Object.keys(currentObject.childs ?? {}).length === 0
        // return currentObject.length && currentObject.length - 1 === currentObject.index
    }
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (isPathToShowTasks(taskDisplayedPath)) {
            // const vv = taskDisplayedPath.replace(/childs(?!.*childs)/, 'taskChilds')
            const vv = replaceLastOccurrence(taskDisplayedPath, pathDelmiter, "childs", "taskChilds")
            const f = encodeURIComponent(vv)
            navigate(`/CheckListDD/${f}`)
        }
        const obj = getByPath(taskDisplayedPath, ChecklistsObject)
        setDisplayedObject(obj)
    }, [taskDisplayedPath])

    useEffect(() => {
        dispatch(setBackClickHandler(() => {
            const pathArray = taskDisplayedPath.split(pathDelmiter)
            if (pathArray.length === 1 && pathArray[0] === "childs")
                navigate(-1)
            if (pathArray.length > 1) {
                pathArray.pop() // pop the childs keyword
                pathArray.pop() // pop the path step
                const previousPath = pathArray.join(pathDelmiter)
                dispatch(setTaskDisplayedPath(previousPath))
            }
        }))
        return () => {
            dispatch(setBackClickHandler(null))
        }
    }, [taskDisplayedPath])

    const handleGetCurrentDDLevels = () => {
        const pathArray = taskDisplayedPath.split(pathDelmiter).filter(p => p !== "childs");
        const totaltDDNum = pathArray[0]?.length === 0 ? 0 : pathArray.length
        let currentDDSLevels = [];
        for (let i = 0; i <= totaltDDNum; i++) {
            if (i < totaltDDNum) currentDDSLevels.push({ label: <RiArrowRightSLine />, icon: true })
            currentDDSLevels.push({ label: `${pathArray?.[i] ?? ""}` })
        }
        return currentDDSLevels
    };

    const breadcrumbTrail = [
        { label: SelectedUser.projectNumber },
        { label: ' - ' },
        { label: SelectedUser.project },
        { label: <RiArrowRightSLine />, icon: true },
        { label: SelectedUser.discipline },
        ...handleGetCurrentDDLevels()
    ];
    const [searchTerm, setSearchTerm] = useState("")
    const initialArray = Object.keys(displayedObject)
    const filteredObjectData = initialArray.filter(k => k.toLowerCase().includes(searchTerm.toLowerCase()))

    const getUnitStatus = (levelId: string): JSX.Element => {
        // const pathArrayToNode = taskDisplayedPath.split(pathDelmiter)
        // pathArrayToNode?.pop() // remove last "childs" labels
        // const pathToNode = pathArrayToNode?.join(pathDelmiter)
        // if (!pathToNode) return
        // const currentObject = getByPath(pathToNode, ChecklistsObject)
        // console.log(pathToNode, "currentObject", pathToNode, currentObject, levelId);
        // we check here if we are on the last DD level before the checklist page to show the Status Label
        // const lastLevel = currentObject.length && currentObject.length - 2 === currentObject.index

        const allTaskChecklistsObject = displayedObject?.[levelId];
        if (!allTaskChecklistsObject.taskChilds) return <></>
        const checklistStatus = Object.values(allTaskChecklistsObject.taskChilds ?? {})?.map((checklist: DDCheckList) => checklist?.statusId);
        const allNotStarted = checklistStatus?.every(status => status === 1);
        const allOpenningTotal = checklistStatus?.filter(status => status === 1).length;
        const allCompletedOrNA = checklistStatus?.every(status => status === 2 || status === 5);
        const allFailedTotal = checklistStatus?.filter(status => status === 3).length;
        const DashComponent = <span className='px-3'>-</span>
        if (allNotStarted) return <>{DashComponent} <span className="bg-gray-200 text-gray-800 py-1 px-3 rounded-full"> Not Started</span> </>;
        if (allCompletedOrNA) return <>{DashComponent} <span className="bg-green-200 text-green-800 py-1 px-3 rounded-full"> Completed</span></>;

        return (
            <div className='inline-flex items-center'>
                {DashComponent}
                <span className="bg-orange-1000 text-white py-1 px-3 rounded-full">In Progress</span>
                {!!allFailedTotal && <span className="bg-red-200 text-yellow-800 py-1 px-3 rounded-full ml-2">{allFailedTotal} Failed</span>}
                {!!allOpenningTotal && <span className="bg-gray-500 text-white py-1 px-3 rounded-full ml-2">{allOpenningTotal} Open</span>}
            </div>
        );
    }
    return (
        <div className="ml-2">
            <Breadcrumb trail={breadcrumbTrail} showIndices={[2, 3, 4, 5, 6]} />
            <div className='flex'>
                <div className="border rounded-lg">
                    <span className="flex items-center px-2 py-3 text-sm font-medium bg-gray-50 dark:bg-gray-900 dark:text-gray-200">
                        <FaSearch />
                        <span className="ml-2 hidden md:inline-block">Search</span>
                    </span>
                </div>
                <div className="flex border rounded-lg border-1 w-full">
                    <input value={searchTerm} onChange={event => setSearchTerm(event.target.value)}
                        type="text" placeholder="Search" className="px-2 w-full" />
                    <div className="border border-black rounded-lg bg-gray-50 py-1" hidden={searchTerm === ""}>
                        <button type="button" className="py-3" title="Clear" onClick={() => setSearchTerm("")}  >
                            <MdOutlineClear />
                        </button>
                    </div>
                </div>
            </div>
            <table className="min-w-full divide-y divide-gray-200">
                <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-1000">
                    {filteredObjectData?.map((item) => {
                        const hasTwoPaths = Object.keys(displayedObject[item].childs ?? {}).length
                            && Object.keys(displayedObject[item].taskChilds ?? {}).length
                        if (hasTwoPaths) {
                            return <>
                                <tr
                                    key={item}
                                    onClick={() => {
                                        const nextPath = taskDisplayedPath ? taskDisplayedPath + pathDelmiter + item : item
                                        // if we reached the tasks checklist objects 
                                        const pp = nextPath + pathDelmiter + "childs"
                                        if (isPathToShowTasks(pp)) {
                                            const vv = replaceLastOccurrence(pp, pathDelmiter, "childs", "taskChilds")
                                            const f = encodeURIComponent(vv)
                                            navigate(`/CheckListDD/${f}`)
                                            return
                                        }
                                        dispatch(setTaskDisplayedPath(pp))
                                    }}
                                    className={"hover:bg-gray-100 hover:cursor-pointer"}
                                >
                                    <td className="py-4 whitespace-nowrap text-left text-sm text-gray-900 dark:text-white">
                                        {item}
                                    </td>
                                </tr>
                                <tr
                                    key={`${item}-tasks`}
                                    onClick={() => {
                                        const nextPath = taskDisplayedPath ? taskDisplayedPath + pathDelmiter + item : item
                                        // if we reached the tasks checklist objects 
                                        const pp = nextPath + pathDelmiter + "taskChilds"
                                        const f = encodeURIComponent(pp)
                                        navigate(`/CheckListDD/${f}`)
                                    }}
                                    className={"hover:bg-gray-100 hover:cursor-pointer"}
                                >
                                    <td className="py-4 whitespace-nowrap text-left text-sm text-gray-900 dark:text-white">
                                        {item} {getUnitStatus(item)}
                                    </td>
                                </tr>
                            </>
                        } else
                            return (
                                <tr
                                    key={item}
                                    onClick={() => {
                                        const nextPath = taskDisplayedPath ? taskDisplayedPath + pathDelmiter + item : item
                                        // if we reached the tasks checklist objects 
                                        const pp = nextPath + pathDelmiter + "childs"
                                        if (isPathToShowTasks(pp)) {
                                            const vv = replaceLastOccurrence(pp, pathDelmiter, "childs", "taskChilds")
                                            const f = encodeURIComponent(vv)
                                            navigate(`/CheckListDD/${f}`)
                                            return
                                        }
                                        dispatch(setTaskDisplayedPath(pp))
                                    }}
                                    className={"hover:bg-gray-100 hover:cursor-pointer"}
                                >
                                    <td className="py-4 whitespace-nowrap text-left text-sm text-gray-900 dark:text-white">
                                        {item} {getUnitStatus(item)}
                                    </td>
                                </tr>
                            );
                    })}
                </tbody>
            </table>
            {/* <List<string>
                data={filteredObjectData}
                renderChildItem={(item) => (props: {}) => {
                    // render two items for childs + taskChilds (or modal)
                    console.log("the item", displayedObject[item]);
                    const hasTwoPaths = Object.keys(displayedObject[item].childs ?? {}).length && Object.keys(displayedObject[item].taskChilds ?? {}).length
                    if (hasTwoPaths) {
                        return <>
                            <div {...props}>{item} {getUnitStatus(item)}</div>
                            <div {...props}>{item} {getUnitStatus(item)}</div>
                        </>
                    } else return <div {...props}>{item} {getUnitStatus(item)}</div>
                }}
                onClick={(selectedKey) => {
                    const nextPath = taskDisplayedPath ? taskDisplayedPath + pathDelmiter + selectedKey : selectedKey
                    // if we reached the tasks checklist objects 
                    const pp = nextPath + ".childs"
                    if (isPathToShowTasks(pp)) {
                        const vv = replaceLastOccurrence(pp, pathDelmiter, "childs", "taskChilds")
                        const f = encodeURIComponent(vv)
                        navigate(`/CheckListDD/${f}`)
                        return
                    }
                    dispatch(setTaskDisplayedPath(pp))
                }}
                isItemDisabled={false}
            /> */}
        </div>
    )
}
