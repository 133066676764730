import { twMerge } from 'tailwind-merge';

export default function FormInput({
    registeredProps,
    type = "text",
    errors,
    inputKey,
    label,
    disabled = false,
    required = false,
    requiredText = '',
    placeholder,
    defaultValue,
    isTextArea = false
}) {
    return (
        <div className='mb-4 md:mb-8 text-base'>
            <div className='flex flex-col md:flex-row items-start mb-4'>
                <label className='block text-left md:w-1/3' htmlFor={inputKey}>
                    {label}
                    {required && (
                        <span className='text-red-500 px-2 text-base' aria-hidden="true">*
                            {requiredText && <span className='pl-1'>{requiredText}</span>}
                        </span>
                    )}
                </label>
                <div className="w-full ml-auto md:w-[400px]">
                    {isTextArea ? (
                        <textarea
                            id={inputKey}
                            disabled={disabled}
                            {...registeredProps}
                            placeholder={placeholder}
                            defaultValue={defaultValue}
                            className={twMerge(`border-2 rounded text-base py-1 px-1.5 text-grey-darkest
                            w-full
                            ${disabled ? "text-neutral-500 bg-gray-200 cursor-not-allowed dark:bg-gray-800 dark:text-gray-400" : ""}`)}
                        />
                    ) : (
                        <input
                            id={inputKey}
                            disabled={disabled}
                            {...registeredProps}
                            type={type}
                            placeholder={placeholder}
                            defaultValue={defaultValue}
                            className={twMerge(`border-2 rounded text-base py-1 px-1.5 text-grey-darkest
                            w-full
                            ${disabled ? "text-neutral-500 bg-gray-200 cursor-not-allowed dark:bg-gray-800 dark:text-gray-400" : ""}`)}
                        />
                    )}
                </div>
            </div>
            {errors[inputKey] && <p className="text-red-500 text-xs text-right">{errors[inputKey]?.message}</p>}
        </div>
    );
}