import { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { MdOutlineClear } from 'react-icons/md';
import { RiArrowRightSLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../../components/CoreComponents/Breadcrumb';
import List from '../../components/CoreComponents/List';
import { getDrilldownType } from '../../Constants/Consts';
import { getByPath, getDrilldownStepsNumber } from '../../Helpers/Functions';
import { RootState } from '../../store/store';
import { setBackClickHandler, setTaskDisplayedPath } from '../../store/Utilities';
import { DDCheckList } from '../../types/DataDD/DataDD';

export default function TasksDD() {
    const { ChecklistsObject, DrilldownMappingsAPIResponse } = useSelector((state: RootState) => state.SequenceObject);
    const { SelectedUser } = useSelector((state: RootState) => state.SelectedUser);
    const { taskDisplayedPath } = useSelector((state: RootState) => state.Utilities);
    const [displayedObject, setDisplayedObject] = useState({})
    // for now: discipline will refere to drilldownTypeId
    const drilldownType = getDrilldownType(SelectedUser.disciplineId)
    // const DrilldownMappingObject = DrilldownMappingMeaning[drilldownType]
    const DrilldownMappingObject = DrilldownMappingsAPIResponse.find(d => d.drilldownTypeId === drilldownType)
    const isPathToShowTasks = (path) => path.split('.').length === getDrilldownStepsNumber(DrilldownMappingObject)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (isPathToShowTasks(taskDisplayedPath)) {
            const f = encodeURIComponent(taskDisplayedPath)
            navigate(`/CheckListDD/${f}`)
        }
        const obj = getByPath(taskDisplayedPath, ChecklistsObject)
        setDisplayedObject(obj)
    }, [taskDisplayedPath])

    useEffect(() => {
        dispatch(setBackClickHandler(() => {
            const pathArray = taskDisplayedPath.split('.')
            if (!pathArray.length || !pathArray.some(s => !!s))
                navigate(-1)
            pathArray.pop()
            const previousPath = pathArray.join('.')
            dispatch(setTaskDisplayedPath(previousPath))
        }))
        return () => {
            dispatch(setBackClickHandler(null))
        }
    }, [taskDisplayedPath])

    const handleGetCurrentDDLevels = () => {
        const pathArray = taskDisplayedPath.split('.');
        const totaltDDNum = pathArray[0].length === 0 ? 0 : pathArray.length
        let currentDDSLevels = [];
        for (let i = 0; i <= totaltDDNum; i++) {
            if (i < totaltDDNum) currentDDSLevels.push({ label: <RiArrowRightSLine />, icon: true })
            currentDDSLevels.push({ label: `${pathArray?.[i] ?? ""}` })
        }
        return currentDDSLevels
    };
    
    const breadcrumbTrail = [
        { label: SelectedUser.projectNumber },
        { label: ' - ' },
        { label: SelectedUser.project },
        { label: <RiArrowRightSLine />, icon: true },
        { label: SelectedUser.discipline },
        ...handleGetCurrentDDLevels()
    ];
    const [searchTerm, setSearchTerm] = useState("")
    const initialArray = Object.keys(displayedObject)
    const [filteredObjectData, setFilteredObjectData] = useState(initialArray)
    useEffect(() => {
        if (searchTerm) setFilteredObjectData(initialArray.filter(k => k.toLowerCase().includes(searchTerm.toLowerCase())))
        else setFilteredObjectData(initialArray)
    }, [searchTerm, displayedObject])



    const getUnitStatus = (unitId: string): JSX.Element => {

        const pathArray = taskDisplayedPath.split('.')
        const currentDDStepNum = pathArray[0].length === 0 ? 0 : pathArray.length
        const isLastDD = currentDDStepNum === (getDrilldownStepsNumber(DrilldownMappingObject) - 1)
        // we check here if we are on the last DD level before the checklist page to show the Status Label
        if (!isLastDD) return <></>
        const allUnitChecklists = displayedObject?.[unitId];
        const checklistStatus = Object.values(allUnitChecklists ?? {})?.map((checklist: DDCheckList) => checklist?.statusId);
        const allNotStarted = checklistStatus?.every(status => status === 1);
        const allCompletedOrNA = checklistStatus?.every(status => status === 2 || status === 5);
        const allFailedTotal = checklistStatus?.filter(status => status === 3).length;
        const DashComponent = <span className='px-3'>-</span>

        if (allNotStarted) return <>{DashComponent} <span className="bg-gray-200 text-gray-800 py-1 px-3 rounded-full"> Not Started</span> </>;
        if (allCompletedOrNA) return <>{DashComponent} <span className="bg-green-200 text-green-800 py-1 px-3 rounded-full"> Completed</span></>;

        return (
            <div className='inline-flex items-center'>
                {DashComponent}
                <span className="bg-orange-1000 text-white py-1 px-3 rounded-full">In Progress</span>
                {!!allFailedTotal && <span className="bg-red-200 text-yellow-800 py-1 px-3 rounded-full ml-2">{allFailedTotal} Failed</span>}
            </div>
        );
    }
    return (
        <div className="ml-2">
            <Breadcrumb trail={breadcrumbTrail} showIndices={[2, 3, 4, 5, 6]} />
            <div className='flex'>
                <div className="border rounded-lg">
                    <span className="flex items-center px-2 py-3 text-sm font-medium bg-gray-50 dark:bg-gray-900 dark:text-gray-200">
                        <FaSearch />
                        <span className="ml-2 hidden md:inline-block">Search</span>
                    </span>
                </div>
                <div className="flex border rounded-lg border-1 w-full">
                    <input value={searchTerm} onChange={event => setSearchTerm(event.target.value)}
                        type="text" placeholder="Search inspection task" className="px-2 w-full" />
                    <div className="border border-black rounded-lg bg-gray-50 py-1" hidden={searchTerm === ""}>
                        <button type="button" className="py-3" title="Clear" onClick={() => setSearchTerm("")}  >
                            <MdOutlineClear />
                        </button>
                    </div>
                </div>
            </div>
            <List<string>
                data={filteredObjectData}
                renderChildItem={(item) => (props: {}) =>
                    <div {...props}>{item} {getUnitStatus(item)}</div>}
                onClick={(selectedKey) => {
                    const nextPath = taskDisplayedPath ? taskDisplayedPath + '.' + selectedKey : selectedKey
                    // if we reached the units checklist onjects 
                    if (isPathToShowTasks(nextPath)) {
                        const f = encodeURIComponent(nextPath)
                        navigate(`/CheckListDD/${f}`)
                        return
                    }
                    dispatch(setTaskDisplayedPath(nextPath))
                    const obj = getByPath(nextPath, ChecklistsObject)
                    if (obj) setDisplayedObject(obj)
                }}
                isItemDisabled={false}
            />
        </div>
    )
}
