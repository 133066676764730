import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { useNavigate } from "react-router"
import { useEffect, useState } from "react"

export default function DrawingLayouts() {
  const { urls } = useSelector((state: RootState) => state.Drawings)
  const navigate = useNavigate()
  const [options, setOptions] = useState([])
  
  useEffect(() => {
    (async () => {
      const ff = await Promise.all(urls.map(async ({ label, id, data }) => {
        const dd = await fetch(data)
          .then(response => response.blob())
        const blobUrl = window.URL.createObjectURL(dd)
        return ({ url: blobUrl, id, label })
      }))
      setOptions(ff)
    })()
  }, [urls])
  
  return (
    <div>
      {options.map(({ url, label, id }) => {
        return (
          <div
            key={label}
            onClick={() => navigate(`/DrawingLayouts/MapView/${id}`)}
            className="flex gap-2 p-3 cursor-pointer border-b-2">
            <img src={url} style={{ width: "50px" }} />
            <label>{label}</label>
          </div>
        )
      })}
    </div>
  )
}