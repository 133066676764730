import { FaPlusSquare } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store.ts";
import { getProjectText } from "../../types/types.ts";
import { useTable } from "react-table";
import { useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import SearchComponent from "../../components/CoreComponents/Search.tsx";
import { AdHocUnitItem } from "../../types/Unit/AdHocUnitSchema.ts";

export default function AllAdHocUnitsByProject() {
    const navigate = useNavigate();

    const { ProjectNumber } = useParams();

    const { ProjectAdHocUnitItem } = useSelector((state: RootState) => state.AdHocUnit);
    const { projects } = useSelector((state: RootState) => state.Project);
    const { allDisciplines } = useSelector((state: RootState) => state.Discipline)

    const project = projects.find(p => p.projectNumber === Number(ProjectNumber));

    const columns = useMemo(() => [
        {
            Header: 'Sequence Number',
            accessor: 'sequenceNumber',
        },
        {
            Header: 'Unit Number',
            accessor: 'unitNumber',
        },
        {
            Header: 'Model Number',
            accessor: 'modelNumber',
        },
        {
            Header: 'Discipline',
            accessor: 'disciplineId',
            Cell: ({ value }) => allDisciplines?.find(d => d.id === value)?.disciplineName
        }
    ], []);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data: ProjectAdHocUnitItem[ProjectNumber]
    });
    const [searchTerm, setSearchTerm] = useState('');

    const filteredRows = rows.filter(row => {
        if (!row.original) return false;

        const rowData: AdHocUnitItem = row.original;
        const searchTermLower = searchTerm.toLowerCase();

        return (
            (String(rowData.sequenceNumber).includes(searchTermLower)) ||
            (String(rowData.unitNumber).includes(searchTermLower)) ||
            (rowData.modelNumber.includes(searchTermLower)) ||
            (String(allDisciplines?.find(d => d.id === rowData.disciplineId)?.disciplineName.toLowerCase()).includes(searchTermLower))
        );
    });

    const handleSearch = (term) => {
        setSearchTerm(term);
    };

    return (
        <>
            <div className={'max-w-screen-lg'}>
                <div className='text-xl font-semibold uppercase text-center mt-4 mb-4'>
                    AdHoc Units ({getProjectText(project)})
                </div>
                <div className="flex justify-between items-center mb-4">
                    <div className="flex-grow mr-4">
                        <SearchComponent onSearch={handleSearch} />
                    </div>
                    <button
                        type="button"
                        className="bg-[#fdb419] float-right whitespace-nowrap"
                        title="Add Unit"
                        onClick={() => navigate(`/AdHocUnits/Create`)}>
                        <span className="flex gap-1 items-center text-sm font-medium text-white">
                            <FaPlusSquare size={18} />
                            <span className="ml-0">Add Unit Checklist Item</span>
                        </span>
                    </button>
                </div>

                <table {...getTableProps()} className="min-w-full text-left text-sm">
                    <thead className="border-b font-medium dark:border-neutral-500 uppercase">
                        {headerGroups.map(headerGroup => {
                            const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
                            return (
                                <tr key={key} {...restHeaderGroupProps}>
                                    {headerGroup.headers.map(column => {
                                        const { key, ...restColumnProps } = column.getHeaderProps();
                                        return (
                                            <th className="px-3 py-2 text-left" key={key} {...restColumnProps}>
                                                {column.render("Header")}
                                            </th>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {filteredRows.map((row, i) => {
                            prepareRow(row);
                            const { key, ...restRowProps } = row.getRowProps();
                            return (
                                <tr
                                    key={key}
                                    onClick={() => navigate(`/AdHocUnits/${ProjectNumber}/${row.original.adhocUuid}`)}
                                    className={twMerge("cursor-pointer hover:bg-slate-300", i % 2 == 0 ? "border-b bg-white dark:border-neutral-500 dark:bg-neutral-600"
                                        : "border-b bg-neutral-100 dark:border-neutral-500 dark:bg-neutral-700")}
                                    {...restRowProps}
                                >
                                    {row.cells.map(cell => {
                                        const { key, ...restCellProps } = cell.getCellProps();
                                        return (
                                            <td className={"px-3 py-2 text-left"} key={key} {...restCellProps}>
                                                <div className="flex items-center">
                                                    {key.includes("project") && !!row.values.project && (
                                                        <HiOutlineOfficeBuilding className="mr-2"
                                                            style={{ color: "#fdb419" }} />
                                                    )}
                                                    {cell.render("Cell")}
                                                </div>
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
}
